import React from 'react'
import styled from 'styled-components'
import { CompleteSvg } from '../../icons/Complete'
import Image from 'next/image'
import { CompleteStarsSvg } from '../../icons/CompleteStars'
import { InvestInCard } from '../InvestInCard'
import { Device } from '../../../styles'

const CompleteWithOthersAnimation = () => {
  return (
    <Container>
      <ContentDesktop>
        <StarsContainer>
          <CompleteStarsSvg />
        </StarsContainer>
        <GoldTrophyContainer>
          <GoldTrophy>
            <Image src="/images/landing/goldTrophy.png" layout="fill" />
          </GoldTrophy>
        </GoldTrophyContainer>
        <CompelteSvgContainer>
          <CompleteSvg />
        </CompelteSvgContainer>

        <FirstLevelLeafContainer>
          <ImageContainer>
            <Image src="/images/landing/tokens/yieldB.png" layout="fill" />
          </ImageContainer>
          <ImageSwordsContainer>
            <Image src="/images/landing/crossedSwords.png" layout="fill" />
          </ImageSwordsContainer>
        </FirstLevelLeafContainer>

        <FirstLevelSecondLeafContainer>
          <ImageSwordsContainer>
            <Image src="/images/landing/crossedSwords.png" layout="fill" />
          </ImageSwordsContainer>
        </FirstLevelSecondLeafContainer>
        <FirstCardContainer>
          <InvestInCard
            src="/images/landing/tokens/yieldB.png"
            title="Profit King"
            value="+23%"
            isOpacityNeeded={false}
            subtitle="$PRFT"
          />
        </FirstCardContainer>
        <SecondCardContainer>
          <SecondCard
            src="/images/landing/avatars/1.png"
            title="Goated"
            value="+23%"
            isOpacityNeeded={false}
            subtitle="$GOAT"
          />
          <BonesContainer>
            <BonesImageContainer>
              <Image src="/images/landing/avatars/bones.png" layout="fill" />
            </BonesImageContainer>
          </BonesContainer>
        </SecondCardContainer>
        <ThirdCardContainer>
          <ThirdCard
            src="/images/landing/tokens/yieldB.png"
            title="Profit King"
            value="+23%"
            subtitle="$PRFT"
            isOpacityNeeded={false}
          />
        </ThirdCardContainer>
      </ContentDesktop>
      <ContentMobile>
        <StarsContainer>
          <CompleteStarsSvg />
        </StarsContainer>
        <GoldTrophyContainer>
          <GoldTrophy>
            <Image src="/images/landing/goldTrophy.png" layout="fill" />
          </GoldTrophy>
        </GoldTrophyContainer>
        <FirstCardContainer>
          <InvestInCard
            src="/images/landing/tokens/yieldB.png"
            title="Profit King"
            value="+23%"
            isOpacityNeeded={false}
            subtitle="$PRFT"
          />
        </FirstCardContainer>
        <SecondCardContainer>
          <SecondCard
            src="/images/landing/avatars/1.png"
            title="Goated"
            value="+23%"
            isOpacityNeeded={false}
            subtitle="$GOAT"
          />
        </SecondCardContainer>
        <FirstLevelLeafContainer>
          <ImageSwordsContainer>
            <Image src="/images/landing/crossedSwords.png" layout="fill" />
          </ImageSwordsContainer>
        </FirstLevelLeafContainer>
      </ContentMobile>
    </Container>
  )
}

export default CompleteWithOthersAnimation

const animationDuration = '5s'
const animationIteration = 1

const BonesContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 18px;
  left: 21px;
  background: #efeff3;
  mix-blend-mode: luminosity;

  animation: animateBones ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @keyframes animateBones {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
`

const BonesImageContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 25px;
  height: 25px;
  opacity: 0.4;
`

const StarsContainer = styled.div`
  position: absolute;
  top: 160px;
  z-index: 3;
  left: 8%;

  animation: animateStars ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @media ${Device.mobile} {
    left: -120px;
    top: 40px;
    animation: none;
    z-index: 5;
  }
}

  @keyframes animateStars {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`

const FirstCardContainer = styled.div`
  position: absolute;
  left: 67%;
  top: 65px;
  width: 320px;
  z-index: 3;

  @media ${Device.mobile} {
    left: 30%;
    width: 250px;
    z-index: 0;
    top: 65px;
  }
`

const SecondCardContainer = styled.div`
  position: absolute;
  left: 67%;
  top: 180px;
  width: 320px;
  z-index: 3;

  @media ${Device.mobile} {
    left: 30%;
    width: 250px;
    z-index: 0;
    top: 155px;
  }
`

const ThirdCard = styled(InvestInCard)`
  filter: blur(2px);
  animation: animateThirdCard ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @keyframes animateThirdCard {
    0% {
      filter: blur(2px);
    }

    25% {
      filter: blur(0px);
    }

    50% {
      filter: blur(0px);
    }

    75% {
      filter: blur(2px);
    }

    100% {
      filter: blur(2px);
    }
`

const SecondCard = styled(InvestInCard)`
  filter: grayscale(1);
  mix-blend-mode: luminosity;
  opacity: 0.4;
  box-shadow: 0px 7px 14px rgba(66, 57, 104, 0.06);
  border-radius: 16px;

  animation: animateSecondCard ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @media ${Device.mobile} {
    animation: none;
    filter: none;
    mix-blend-mode: normal;
    opacity: 1;
  }

  @keyframes animateSecondCard {
    0% {
      mix-blend-mode: luminosity;
      opacity: 0.4;
      filter: grayscale(1);
    }

    25% {
      mix-blend-mode: normal;
      opacity: 1;
      filter: grayscale(0);
    }

    50% {
      mix-blend-mode: normal;
      opacity: 1;
      filter: grayscale(0);
    }

    75% {
      mix-blend-mode: luminosity;
      opacity: 0.4;
      filter: grayscale(1);
    }

    100% {
      mix-blend-mode: luminosity;
      opacity: 0.4;
      filter: grayscale(1);
    }
  }
`

const ThirdCardContainer = styled.div`
  position: absolute;
  left: 67%;
  top: 360px;
  width: 320px;
  z-index: 3;
`

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: -5px;
  z-index: 4;
  opacity: 1;
  animation: animateImageContainer ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @keyframes animateImageContainer {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
`

const ImageSwordsContainer = styled.div`
  width: 45px;
  height: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const FirstLevelLeafContainer = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 118px;
  left: 51.5%;
  background: rgba(202, 200, 224, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 50%;
  overflow: hidden;
  z-index: 3;

  @media ${Device.mobile} {
    top: 110px;
    left: 60%;
  }
`

const FirstLevelSecondLeafContainer = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 415px;
  left: 51.5%;
  background: rgba(202, 200, 224, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 50%;
  overflow: hidden;
  z-index: 3;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const ContentDesktop = styled.div`
  position: absolute;
  width: 100%;
  top: -5px;
  left: -5%;

  @media ${Device.mobile} {
    display: none;
  }
`

const ContentMobile = styled.div`
  display: none;
  width: 100%;
  max-width: 375px;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  top: -5px;
  height: 5px;

  @media ${Device.mobile} {
    display: block;
  }
`

const GoldTrophyContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 208px;
  height: 208px;
  background: rgba(250, 230, 161, 0.3);
  backdrop-filter: blur(12px);
  border-radius: 50%;
  overflow: hidden;
  left: 12%;
  top: 200px;


  animation: animateTrophy ease;
  animation-iteration-count: ${animationIteration};
  animation-duration: ${animationDuration};

  @media ${Device.mobile} {
    left: -120px;
    top: 100px;
    animation: none;
  }

  @keyframes animateTrophy {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(0.5);
    }

    50% {
      transform: scale(0.5);
    }

    75% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
`

const CompelteSvgContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  top: 100px;
`

const GoldTrophy = styled.div`
  position: absolute;
  top: -25px;
  left: 10px;
  width: 240px;
  height: 280px;
`
