import React, { useEffect, useRef } from 'react'

export const useOuterClick = (callback: any): any => {
  const callbackRef = useRef<HTMLButtonElement>(null) // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLButtonElement>(null) // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    // @ts-ignore
    callbackRef.current = callback
  })

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', handleClick)
    // @ts-ignore
    return () => document.removeEventListener('click', handleClick)

    function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        // @ts-ignore
        callbackRef.current(e)
      }
    }
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}
