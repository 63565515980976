import React, { ReactNode, useMemo } from 'react'
import { EtherPriceProvider } from './etherPrice/provider'
import { GlobalStyle } from './GlobalStyle'
import { ReactQueryClientProvider } from './reactQuery/provider'
import { GraphQLProvider } from './subgraph/provider'
import { DAppProvider } from '@usedapp/core'
import { LegacyActivator } from '../components'
import { getApplicationConfig } from '../../config/getApplicationConfig'
import { IdProvider } from '@radix-ui/react-id'
import { AppProps } from 'next/app'
import Gtm from './gtm'
import { RequestAuthProvider } from './requestAuth/provider'
import { AuthenticationProvider } from './authentication/provider'
import { EstimatorProvider } from './estimator/provider'
import { LogRocketProvider } from './logRocket/provider'
import { PosthogProvider } from './posthog/provider'
import { UserAddressGuard } from './guards/whitelist'
import { VampireProvider } from './vampire/provider'
import { ToastsProvider } from './toasts/provider'
import { VampireClaimModal } from '../components/page/VampireClaim'
import { MobileGuard } from './guards/mobile'

interface Props {
  children: ReactNode
  pageProps: AppProps['pageProps']
}

export function Providers(props: Props) {
  const appConfig = getApplicationConfig()

  const config = {
    readOnlyChainId: appConfig.network.defaultChainId,
    readOnlyUrls: appConfig.network.urls,
    notifications: appConfig.notifications,
  }
  const whitelistPages = useMemo(
    () => ['/maintenance', '/404', '/early-access', '/', '/team', '/terms', '/privacy-policy'],
    []
  )

  return (
    <IdProvider>
      <Gtm />
      <DAppProvider config={config}>
        <EtherPriceProvider>
          <GraphQLProvider pageProps={props.pageProps}>
            {/* <LogRocketProvider> */}
            <ReactQueryClientProvider pageProps={props.pageProps}>
              <LegacyActivator />
              <EstimatorProvider>
                <RequestAuthProvider>
                  <AuthenticationProvider>
                    <PosthogProvider>
                      <MobileGuard>
                        <UserAddressGuard whitelistPages={whitelistPages}>
                          <VampireProvider>
                            <GlobalStyle />
                            {props.children}
                            <ToastsProvider />
                            <VampireClaimModal />
                          </VampireProvider>
                        </UserAddressGuard>
                      </MobileGuard>
                    </PosthogProvider>
                  </AuthenticationProvider>
                </RequestAuthProvider>
              </EstimatorProvider>
            </ReactQueryClientProvider>
            {/* </LogRocketProvider> */}
          </GraphQLProvider>
        </EtherPriceProvider>
      </DAppProvider>
    </IdProvider>
  )
}
