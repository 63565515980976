import React from 'react'
import styled from 'styled-components'

interface OpenIconProps {
  className?: any
}

export function OpenIcon({ className }: OpenIconProps) {
  return (
    <OpenIconStyle viewBox="0 0 16 16" fill="none" color="currentColor" className={className}>
      <path
        d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59667 2 2 2.59667 2 3.33333V12.6667C2 13.4033 2.59667 14 3.33333 14H12.6667C13.4033 14 14 13.4033 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7233L5.17 9.88667L6.11333 10.83L12.6667 4.27667V6.66667H14V2H9.33333Z"
        fill="currentColor"
      />
    </OpenIconStyle>
  )
}

export const OpenIconStyle = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
