import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'

import { Colors, Device } from 'src/newDesign/styles'

export const SaveMoneyAnimation = () => {
  return (
    <CardContent>
      <Prices>
        <span className="price">$48</span>
        <span className="price__old">$96</span>
        <span className="face">
          <Image src="/images/landing/emojiFace.svg" width={72} height={72} />
        </span>
        <span className="money-bag">
          <Image src="/images/landing/emojiMoneyBag.svg" width={56} height={56} />
        </span>
      </Prices>
      <ImageContainer>
        <Image src="/images/landing/eth.svg" width={56} height={56} />
        <span className="swap-dai">
          <Image src="/images/landing/swapDai.svg" width={60} height={60} />
        </span>
        <span className="enso">
          <Image src="/images/landing/ensoLogo.svg" width={120} height={120} />
        </span>
        <span className="swap-compound">
          <Image src="/images/landing/swapCompound.svg" width={60} height={60} />
        </span>
        <Image src="/images/landing/dai.svg" width={56} height={56} />
        <DottedLine>
          <Image src="/images/landing/dottedLine.svg" width={600} height={3} />
        </DottedLine>
      </ImageContainer>
      <Reduction>
        <div>32%</div>
        <div>32%</div>
        <div>32%</div>
      </Reduction>
    </CardContent>
  )
}

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 124px;
  width: 365px;

  animation-name: prices;

  @keyframes prices {
    0%,
    60% {
      margin-top: 0;
    }
    85%,
    100% {
      margin-top: 40px;
    }
  }

  & .price {
    font-weight: 700;
    font-size: 40px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: ${Colors.Positive[900]};
    position: absolute;
    top: 60px;

    animation-name: price;

    @keyframes price {
      0%,
      47% {
        opacity: 0;
        top: 60px;
      }
      85%,
      100% {
        opacity: 1;
        top: 0;
      }
    }
  }

  & .price__old {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: ${Colors.Neutral[200]};

    animation-name: priceOld;

    @keyframes priceOld {
      0%,
      25% {
        opacity: 0;
      }
      50% {
        opacity: 0.8;
        text-decoration-line: none;
      }
      75%,
      100% {
        opacity: 0.3;
        text-decoration-line: line-through;
      }
    }
  }

  & .face {
    left: 65px;
    position: absolute;
    width: fit-content;

    animation-name: face;

    @keyframes face {
      0%,
      90% {
        opacity: 0;
        bottom: 0;
      }
      100% {
        opacity: 1;
        bottom: 50px;
      }
    }
  }

  & .money-bag {
    right: 65px;
    position: absolute;
    width: fit-content;

    animation-name: moneyBag;

    @keyframes moneyBag {
      0%,
      90% {
        opacity: 0;
        bottom: 25px;
      }
      100% {
        opacity: 1;
        bottom: 85px;
      }
    }
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 80px;
  width: 375px;

  & .enso {
    z-index: 1;

    animation-name: enso;

    @keyframes enso {
      0%,
      25% {
        opacity: 0;
      }
      50%,
      100% {
        opacity: 1;
      }
    }
  }

  & .swap-dai,
  & .swap-compound {
    position: absolute;
    top: 22px;
  }

  & .swap-dai {
    left: 88px;

    animation-name: swap-dai;

    @keyframes swap-dai {
      25% {
        opacity: 1;
      }
      50%,
      100% {
        opacity: 0;
        left: 165px;
      }
    }
  }

  & .swap-compound {
    right: 76px;
    z-index: 0;

    animation-name: swap-compound;

    @keyframes swap-compound {
      25% {
        opacity: 1;
      }
      50%,
      100% {
        opacity: 0;
        right: 150px;
      }
    }
  }

  @media ${Device.mobile} {
    width: 329px;
  }
`

const DottedLine = styled.div`
  width: 445px;
  position: absolute;
  right: 60px;
  bottom: 35px;

  @media ${Device.mobile} {
    width: 367px;
  }
`

const Reduction = styled.div`
  width: 244px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${Colors.Neutral[200]};
  left: 77px;
  bottom: 84px;

  animation-name: reduction;

  @keyframes reduction {
    25% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
  }
`

const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & ${Prices}, & ${Reduction}, & .price,
  & .price__old,
  & .enso,
  & .swap-dai,
  & .swap-compound {
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(1, 0.5, 0.78, 0.13);
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }

  & .face,
  & .money-bag {
    animation-duration: 3.5s;
    animation-timing-function: cubic-bezier(1, 0.5, 0.78, 0.13);
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }
`
