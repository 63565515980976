import React, { memo, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Search, SearchInput } from '../base/Search'
import { useCombobox } from 'downshift'
import { BorderRad, Colors, Fonts, Shadows, Transitions } from '../../constants'
import * as NewDesignStyles from '../../newDesign/styles'
import { SearchIcon } from '../icons/SearchIcon'
import { DeploymentSpinner } from '../icons/DeploymentSpinner'
import { DropdownCategories } from './DropdownCategories'
import { usePopper } from 'react-popper'
import * as Portal from '@radix-ui/react-portal'
import {
  GlobalSearchAllResultType,
  GlobalSearchInvestor,
  GlobalSearchManager,
  GlobalSearchStrategy,
  useGlobalSearch,
} from './useGlobalSearch'
import { itemToString, sectionOptions } from './GlobalSearchHelpers'
import { Device } from 'src/newDesign/styles'
import { InputStyles } from '../base/Input'
import { isMacOS } from 'src/misc/helpers/isMacOS'
import { LSearchIcon } from 'src/newDesign/components/icons/LSearchIcon'
import { WrappedSpinner } from '../base/WrappedSpinner'
import { useOuterClick } from 'src/newDesign/hooks/useOuterClick'
import { UseSearchKeystones } from 'src/newDesign/hooks/UseKeystones'
import { usePrevious } from 'src/misc/hooks/usePrevious'
import { usePosthog } from 'src/providers/posthog/context'

const { Colors: NewDesignColors } = NewDesignStyles
export interface OptionProps<OptionType> {
  index: number
  item: OptionType
}

export interface Category<OptionType> {
  options: OptionType[]
  title: string
}

export interface DropdownSectionsAndItemIndex<MergedItemType> {
  sections: Category<OptionProps<MergedItemType>>[]
  itemIndex: number
}

interface GlobalSearchProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

export const GlobalSearch = memo(function GlobalSearch({ isOpen, setOpen }: GlobalSearchProps) {
  const posthog = usePosthog()
  const [searchValue, setSearchValue] = useState('')
  const [ctrlSymbol, setCtrlSymbol] = useState('')

  useEffect(() => {
    setCtrlSymbol(isMacOS() ? '⌘' : 'Ctrl')
  }, [setCtrlSymbol])

  const { investors, managers, strategies, loading, hasResults, allResults } = useGlobalSearch(searchValue)

  const previousLoading = usePrevious(loading, null)
  useEffect(() => {
    if (posthog && searchValue && !loading && previousLoading)
      posthog.capture('Search', {
        type: 'General',
        search: searchValue,
        results: allResults.length,
      })
  }, [posthog, searchValue, allResults.length, loading, previousLoading])

  const { getComboboxProps, getInputProps, getMenuProps, getItemProps, highlightedIndex, selectedItem } =
    useCombobox<GlobalSearchAllResultType>({
      items: allResults,
      itemToString,
      isOpen,
      onIsOpenChange: (changes) => setOpen(!changes.isOpen),
      onInputValueChange: ({ inputValue }) => {
        setOpen(true)
        setSearchValue(inputValue ?? '')
      },
    })

  const showPopup = isOpen && !!searchValue
  const referenceElement = useRef<HTMLButtonElement>()
  const inputElement = referenceElement as React.MutableRefObject<HTMLInputElement>
  const popperElement = useRef<HTMLDivElement>(null)
  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  })

  const categories = [
    {
      title: 'Strategies',
      options: strategies,
    } as Category<GlobalSearchStrategy>,
    {
      title: 'Investors',
      options: investors,
    } as Category<GlobalSearchInvestor>,
    {
      title: 'Managers',
      options: managers,
    } as Category<GlobalSearchManager>,
  ]

  const handleClickSearchIcon = () => {
    setOpen(true)
  }

  const sections = sectionOptions(categories)

  const containerRef = useOuterClick(() => setOpen(false))
  UseSearchKeystones(inputElement)

  return (
    <SearchContainerWrapper>
      <SearchContainer isOpen={isOpen} ref={containerRef}>
        <Combobox {...getComboboxProps()}>
          <SearchIconContainer onClick={handleClickSearchIcon}>
            <WrappedSpinner loading={loading && !!searchValue}>
              <LSearchIcon color={Colors.Neutral[200]} />
            </WrappedSpinner>
          </SearchIconContainer>
          <GlobalSearchInput
            {...getInputProps({ refKey: 'none' }, { suppressRefError: true })}
            ref={referenceElement}
            icon={loading ? <DeploymentSpinner size={16} /> : <SearchIcon />}
            placeholder="Search ENS, address etc."
          />

          <SymbolsContainer>
            <PressSymbol>{ctrlSymbol}</PressSymbol>
            <PressSymbol>/</PressSymbol>
          </SymbolsContainer>
        </Combobox>
        <Portal.Root>
          <DropdownContainer
            ref={popperElement}
            searchWidth={referenceElement.current?.clientWidth}
            style={styles.popper}
            className={showPopup ? 'open' : 'closed'}
            {...attributes}
          >
            <DropdownMenu {...getMenuProps()}>
              <DropdownCategories
                hasResults={hasResults}
                sectionedOptions={sections}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
              />
            </DropdownMenu>
          </DropdownContainer>
        </Portal.Root>
      </SearchContainer>
    </SearchContainerWrapper>
  )
})

export const GlobalSearchInput = styled(InputStyles)`
  width: 100%;
  margin: 0 auto;

  box-shadow: ${Shadows.smallEnso};
`

const SearchIconContainer = styled.button`
  all: unset;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -55%);
  pointer-events: none;

  & svg {
    width: 16px;
    height: 16px;
  }

  @media ${Device.mobile} {
    pointer-events: all;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -45%);
  }
`

const SearchContainerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

const SearchContainer = styled.div<{ isOpen: boolean }>`
  align-items: center;
  max-width: 585px;
  width: 100%;
  position: relative;
  background: rgba(25, 10, 53, 0.03);
  border: 2px solid rgba(151, 158, 184, 0.05);
  color: ${NewDesignColors.Neutral[200]};
  transition: ${NewDesignStyles.Transitions.default};
  font-weight: 600;
  padding: 0;
  border-radius: 16px;
  min-width: 260px;

  & input {
    padding: 4px 80px 4px 50px;
    font-weight: 600;
    height: 48px;
    width: 100%;
    font-family: ${NewDesignStyles.Fonts.secondary};
    font-size: 16px;

    &::placeholder {
      font-weight: 600;
    }
  }

  @media ${Device.mobile} {
    min-width: 48px;

    ${({ isOpen }) =>
      !isOpen
        ? css`
            width: 48px;
            height: 48px;
            padding: 4px 4px;

            & input {
              padding: 4px 4px 4px 15px;
              display: none;
            }
          `
        : css`
            min-width: 40px;

            & input {
              padding: 4px 4px 4px 32px;
            }

            ${SearchIconContainer} {
              left: 16px;
              top: 50%;
            }
          `}
  }
`

const Combobox = styled.div`
  width: 100%;
`

const DropdownMenu = styled.div`
  padding: 16px;
  min-height: 40px;
  background: ${Colors.White};
  border-radius: ${BorderRad.m};
  box-shadow: ${Shadows.smallEnso};
  overflow-y: scroll;
  width: 100%;
`

const DropdownContainer = styled.div<{ searchWidth?: string | number }>`
  display: flex;
  width: ${({ searchWidth }) => `${searchWidth}px`};

  &.closed {
    visibility: hidden;

    > ${DropdownMenu} {
      display: none;
    }
  }
  &.open {
    visibility: visible;
  }
`

const SymbolsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  gap: 4px;
`

const PressSymbol = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 24px;
  padding: 0 6px;
  height: 24px;
  font-size: 12px;
  color: ${NewDesignColors.Neutral[100]};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Device.mobile} {
    display: none;
  }
`
