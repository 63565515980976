export const Colors = {
  White: '#FFFFFF',
  MainBG: '#F4F8FB',

  Primary: {
    600: '#5A67D9',
    500: '#6A79FF',
    400: '#8894FF',
    300: '#A6AFFF',
    200: '#C3C9FF',
    100: '#F0F4FF',
    50: '#FAFBFE',
  },
  Neutral: {
    900: '#393550',
    800: '#454D5F',
    700: '#79808E',
    600: '#929AAA',
    500: '#A6AEBF',
    400: '#C3CAD8',
    300: '#E2E9F1',
    200: '#D9E0EB',
    150: '#F3F4F7',
    100: '#FBFCFD',
    50: '#FAFAFA',
    Disabled: '#CFDCEA',
  },
  Negative: {
    900: '#F34459',
    700: '#EB5757',
    400: '#FEEAF1',
  },
  Positive: {
    900: '#24A275',
    400: '#E2FFF1',
  },
  Warning: {
    900: '#FF8D5C',
    800: '#FFBE98',
    600: '#FFEED4',
    400: '#FFF8F0',
  },
  Accent: {
    900: '#2F79E8',
    400: '#E4F2FF',
    100: '#F8FCFF',
  },
  Blue: {
    500: '#2F80ED',
  },

  Border: '#D2DAE3',
  BorderCollapsible: '#E8EDF8',
  ButtonBorder: '#EDEFF2',
  Disabled: '#EDEFF3',
  Badge: '#EAF2FD',
  BadgeHover: '#f3f8ff',

  ModalGlass: 'rgba(129, 126, 149, 0.3)',
  TwitterBlue: '#1DA1F2',
  DiscordPurple: '#6A79FF',
  checkboxDisabled: '#E1E4EC',
  CardBackground: '#FBFCFE',
  SearchBackground: '#FBFBFB',
}

export const Gradients = {
  enso: 'linear-gradient(265deg, #0ACFFE, #4365FF)',
  ensoNew: 'linear-gradient(265deg, #6A79FF, #5A67D9)',
  rainbow: 'linear-gradient(90deg, #CF1F1F, #E18E2D, #B9D31A, #42DF1B, #1BDFBC, #1B81DF, #711BDF, #DB1BDF)',
  purple: 'linear-gradient(295deg, transparent -25%, #8f57f2 50%, transparent 115%)',
}

export const Fonts = {
  Inter: '"Inter", sans-serif',
  DMSans: '"DM Sans", sans-serif',
}

export const Transitions = {
  duration: '0.25s',
  durationNumeric: 250,
  all: 'all 0.25s ease',
  durationM: '0.5s',
  durationMTime: 500,
  allM: 'all 0.5s ease',
  allS: 'all 0.1s ease',
}

export const Animations = {
  showSelectedOption: `
    showSelectedOption ${Transitions.duration} ease;

    @keyframes showSelectedOption {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
  showTag: `
    animation: 0.25s showTag ease;

    @keyframes showTag {
      from {
        height: 0;
        opacity: 0;
        transform: scale(0.5);
      }
    }
  `,
  showSidebarStuff: `
    animation: 0.25s showSidebarStuff ease;

    @keyframes showSidebarStuff {
      from {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  `,
  showModalBackground: `
    animation: showModalBackground ${Transitions.duration} ease;

    @keyframes showModalBackground {
      from {
        background-color: rgba(129, 126, 149, 0.0);
        backdrop-filter: blur(0px);
      }
      to {}
    }
  `,
  showModalBlock: `
    animation: showModalBlock 0.1s ease;

    @keyframes showModalBlock {
      from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(.96);
      }
      to {}
    }
  `,
  showResultSymbol: `
    animation: showSymbol 1s ease;

    @keyframes showSymbol {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `,
  spinTimerClock: `
    animation: spinTimerClock 1.5s ease infinite;

    @keyframes spinTimerClock {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
  showCheckboxIndicator: `
    animation: showCheckboxIndicator 0.25s ease;

    @keyframes showCheckboxIndicator {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  `,
  hideCheckboxIndicator: `
    animation: hideCheckboxIndicator 0.25s ease;

    @keyframes hideCheckboxIndicator {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(0);
      }
    }
  `,
  hideLandingPageVideosContainer: `
    animation: hideLandingPageVideosContainer 0.5s ease;

    @keyframes hideLandingPageVideosContainer {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
      }
    }
  `,
  openAccordion: `
    animation: openAccordion ${Transitions.duration} ease;

    @keyframes openAccordion {
      from {
        height: 0;
      }
      to {
        height: var(--radix-accordion-content-height);
      }
    }
  `,
  closeAccordion: `
    animation: closeAccordion ${Transitions.duration} ease;

    @keyframes closeAccordion {
      from {
        height: var(--radix-accordion-content-height);
      }
      to {
        height: 0;
      }
    }
  `,
  shakeCreateStrategyInput: `
    animation: shakeCreateStrategyInput 0.5s ease;

    @keyframes shakeCreateStrategyInput {
      0% {
        transform: translateX(0px);
        color: ${Colors.Primary[500]};
      }
      25% {
        transform: translateX(8px);
      }
      50% {
        transform: translateX(-8px);
      }
      75% {
        transform: translateX(8px);
      }
      100% {
        transform: translateX(0px);
        color: ${Colors.Primary[500]};
      }
    }
  `,
  shakeCreateStrategyButtons: `
    animation: shakeCreateStrategyButtons 0.5s ease;

    @keyframes shakeCreateStrategyButtons {
      0% {
        transform: translateX(0px);
      }
      25% {
        transform: translateX(8px);
      }
      50% {
        transform: translateX(-8px);
      }
      75% {
        transform: translateX(8px);
      }
      100% {
        transform: translateX(0px);
      }
    }
  `,
  shakeGeneric: `
  animation: shakeGenericInput 0.5s ease;

  @keyframes shakeGenericInput {
    0% {
      transform: translateX(0px);
      color: ${Colors.Primary[500]};
    }
    25% {
      transform: translateX(8px);
    }
    50% {
      transform: translateX(-8px);
    }
    75% {
      transform: translateX(8px);
    }
    100% {
      transform: translateX(0px);
      color: ${Colors.Primary[500]};
    }
  }`,
}

export const Effects = {
  ModalGlassBlur: 'blur(4px)',
}

export const Shadows = {
  main: '0px 4px 40px rgba(214, 224, 238, 0.4)',
  enso: '0px 5px 30px rgba(217, 224, 235, 0.6)',
  ensoHover: '0px 5px 24px rgba(217, 224, 235, 0.6)',
  ensoActive: '0px 5px 16px rgba(217, 224, 235, 0.6)',
  big: '0px 20px 30px rgba(217, 224, 235, 0.6)',
  small: '0px 10px 16px rgba(217, 224, 235, 0.5)',
  modal: '0px 44px 64px rgba(37, 29, 90, 0.16)',
  default: `0px 4px 4px ${Colors.Neutral[900]}1D`,
  defaultHover: `0px 0px 8px ${Colors.Primary[500]}80`,
  defaultActive: `0px 2px 2px ${Colors.Neutral[900]}1A`,
  transparent: '0px 0px 0px rgba(0, 0, 0, 0)',
  switchHandleShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.2)',
  newEnso: '0px 0px 30px rgba(207, 220, 234, 0.5)',
  smallEnso: '0px 5px 40px rgba(214, 224, 238, 0.4)',
  bigEnso: '0px 5px 30px rgba(217, 224, 235, 0.6)',
  defaultEnso: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  hoverEnso: '0px 0px 10px rgba(106, 121, 255, 0.5)',
  activeEnso: '0px 2px 2px rgba(0, 0, 0, 0.1)',
}

export const BorderRad = {
  xxs: '2px',
  xs: '4px',
  sm: '6px',
  s: '8px',
  m: '12px',
  l: '16px',
  xl: '20px',
  xxl: '24px',
  full: '1000px',
  round: '50%',
}

export const SidebarNormalWidth = '256px'
export const defaultModalHeight = 478

export const Overflow = {
  Dots: `
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  `,
  FullDots: `
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  `,
}

export const Wrappers = {
  main: '1242px',
  narrow: '682px',
  homepageHeader: '1344px',
  homepageFooter: '1064px',
}
