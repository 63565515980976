export const ERC20_ABI = [
  'function balanceOf(address) view returns(uint256)',
  'function totalSupply() view returns(uint256)',
  'function transfer(address to, uint256 value) returns(bool)',
  'function symbol() view returns(string)',
  'function name() view returns(string)',
  'function decimals() view returns(uint8)',
]

export const MULTICALL_ABI = [
  'function aggregate(tuple(address target, bytes callData)[] calls) view returns (uint256 blockNumber, bytes[] returnData)',
  'function getEthBalance(address addr) view returns (uint256 balance)',
  'function getBlockHash(uint256 blockNumber) view returns (bytes32 blockHash)',
  'function getLastBlockHash() view returns (bytes32 blockHash)',
  'function getCurrentBlockTimestamp() view returns (uint256 timestamp)',
  'function getCurrentBlockDifficulty() view returns (uint256 difficulty)',
  'function getCurrentBlockGasLimit() view returns (uint256 gaslimit)',
  'function getCurrentBlockCoinbase() view returns (address coinbase)',
]
export const UNI_PROXY_ROUTER_ABI_FRAGMENT = [
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
    ],
    name: 'getAmountsOut',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
]

export const COMP_TOKEN = '0xc00e94Cb662C3520282E6f5717214004A7f26888'
export const COMP_LENS_ADDRESS = '0xdCbDb7306c6Ff46f77B349188dC18cEd9DF30299'
export const COMP_LENS_ABI_FRAGMENT = [
  {
    constant: true,
    inputs: [
      { internalType: 'contract Comp', name: 'comp', type: 'address' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'getCompBalanceMetadata',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'uint256', name: 'votes', type: 'uint256' },
          { internalType: 'address', name: 'delegate', type: 'address' },
        ],
        internalType: 'struct CompoundLens.CompBalanceMetadata',
        name: '',
        type: 'tuple',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
]

export const CURVE_GAUGE_ABI_FRAGMENT = [
  {
    name: 'claimable_tokens',
    outputs: [{ type: 'uint256', name: '' }],
    inputs: [{ type: 'address', name: 'addr' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    name: 'claimable_reward',
    outputs: [{ type: 'uint256', name: '' }],
    inputs: [
      { type: 'address', name: '_addr' },
      { type: 'address', name: '_token' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
