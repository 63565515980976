import { useQuery } from '@apollo/client'
import { useChainId } from '../../../hooks/useChainId'
import { UserStrategiesQuery } from '../../../infrastructure/subgraph/backend/queries'
import {
  QueryUserStrategiesFilter,
  SortingDir,
  StrategyAssetExposure,
  UserStrategiesType,
} from '../../../infrastructure/subgraph/backend/types'
import { SubgraphClientName } from '../../../infrastructure/subgraph/SubgraphClientName'
import { GetUserStrategiesQuery, GetUserStrategiesQueryVariables } from '../../../infrastructure/subgraph/backend/types'
import { useRefetchOnNewBlock } from '../../../infrastructure/subgraph/useRefetchOnNewBlock'
import { useEffect, useState } from 'react'
import { mapValues } from 'lodash'
import { useAuthentication } from 'src/providers/authentication/context'

export type UserStrategy = NonNullable<
  NonNullable<NonNullable<NonNullable<GetUserStrategiesQuery['userStrategies']>['strategies']>[0]>
>
export type AssetExposureDatum = Omit<StrategyAssetExposure, '__typename'>

export function useUserStrategies(
  id: string,
  type: string,
  filter: QueryUserStrategiesFilter,
  orderBy: string,
  orderDir: 'DESC' | 'ASC' | null,
  currentPage: number,
  pageSize: number
) {
  const { chainId } = useChainId()

  const [hadFirstState, setHadFirstState] = useState(false)
  const [auth] = useAuthentication()

  const authJwt = auth?.jwt

  const { data, refetch, previousData, loading, error } = useQuery<
    GetUserStrategiesQuery,
    GetUserStrategiesQueryVariables
  >(UserStrategiesQuery, {
    variables: {
      id: id.toLowerCase(),
      chainId,
      currentPage,
      pageSize,
      filter: mapValues(filter, (v) => v?.toString()),
      sort: {
        orderBy,
        orderDir: orderDir as SortingDir,
      },
      type: type as UserStrategiesType,
    },
    context: { clientName: SubgraphClientName.Enso, auth: authJwt },
  })

  useEffect(() => {
    if (!loading && data && !hadFirstState) {
      setHadFirstState(true)
    }
  }, [loading, data, hadFirstState])

  const dataOrPrevData = hadFirstState ? data || previousData : data

  const meta =
    dataOrPrevData?.userStrategies?.meta ??
    ({} as NonNullable<NonNullable<GetUserStrategiesQuery['userStrategies']>['meta']>)

  useRefetchOnNewBlock<GetUserStrategiesQuery>(refetch)

  const strategies = dataOrPrevData?.userStrategies?.strategies ?? []

  return {
    strategies: strategies as UserStrategy[],
    meta,
    loading,
    hasData: !!dataOrPrevData,
    lastPage: dataOrPrevData?.userStrategies?.pageCount ?? 1,
    error,
  }
}
