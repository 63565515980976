// Hook
import React, { useEffect, useRef } from 'react'

export const useEventListener = (eventName: any, handler: any, element?: any) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const localElement = window
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener
      if (!isSupported) {
        // @ts-ignore
        const eventListener = (event: React.SyntheticEvent) => savedHandler.current(event)

        window.addEventListener(eventName, eventListener)

        // Remove event listener on cleanup
        return () => {
          // @ts-ignore
          window.removeEventListener(eventName, eventListener)
        }
      } else {
        // Create event listener that calls handler function stored in ref
        // @ts-ignore
        const eventListener = (event: React.SyntheticEvent) => savedHandler.current(event)

        // Add event listener
        element.addEventListener(eventName, eventListener)

        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener)
        }
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}
