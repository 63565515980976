import React, { useCallback } from 'react'
import { useEventListener } from './UseEventListener'
import { Router, useRouter } from 'next/router'

export const UseSearchKeystones = (searchRef: React.MutableRefObject<HTMLInputElement>) => {
  const handler = useCallback(
    (e) => {
      if (e.metaKey && e.key === '/') {
        if (searchRef && searchRef.current) {
          searchRef.current.focus()
        }
      }
    },
    [searchRef]
  )

  useEventListener('keydown', handler)
}

export const UseLaunchKeystones = () => {
  const router = useRouter()
  const handler = useCallback(
    (e) => {
      if (e.shiftKey && (e.key === 'A' || e.key === 'a')) {
        router.push('/explore/strategies/1', undefined, { shallow: true })
      }
    },
    [router]
  )

  useEventListener('keydown', handler)
}
