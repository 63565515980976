import React from 'react'
import styled from 'styled-components'
import { ContentContainer, StrategyCardSmall } from '../base'
import { Colors, Device } from '../../styles'
import { SLink } from '../base/StyledLink'
import { Image } from 'zrender'

const mockData = [
  {
    id: 1,
    avatar: '/images/landing/avatars/1.png',
    title: 'GOAT Yield',
    currency: '$JSD',
    investors: {
      count: 223,
      data: [
        { avatar: '/images/landing/avatars/s4.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s5.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s12.png', id: 'q13' },
      ],
    },
    tvl: '$1,611,532',
    pctChange: '+23%',
  },
  {
    id: 2,
    avatar: '/images/landing/avatars/1.png',
    title: 'GOAT Yield',
    currency: '$JSD',
    investors: {
      count: 223,
      data: [
        { avatar: '/images/landing/avatars/s7.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s8.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s2.png', id: 'q13' },
      ],
    },
    tvl: '$1,611,532',
    pctChange: '+210%',
  },
  {
    id: 3,
    avatar: '/images/landing/avatars/1.png',
    title: 'GOAT Yield',
    currency: '$JSD',
    investors: {
      count: 223,
      data: [
        { avatar: '/images/landing/avatars/s9.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s10.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s11.png', id: 'q13' },
      ],
    },
    tvl: '$1,611,532',
    pctChange: '+23%',
  },
  {
    id: 4,
    avatar: '/images/landing/avatars/1.png',
    title: 'GOAT Yield',
    currency: '$JSD',
    investors: {
      count: 223,
      data: [
        { avatar: '/images/landing/avatars/s9.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s10.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s11.png', id: 'q13' },
      ],
    },
    tvl: '$1,611,532',
    pctChange: '+23%',
  },
  {
    id: 5,
    avatar: '/images/landing/avatars/1.png',
    title: 'GOAT Yield',
    currency: '$JSD',
    investors: {
      count: 223,
      data: [
        { avatar: '/images/landing/avatars/s9.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s10.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s11.png', id: 'q13' },
      ],
    },
    tvl: '$1,611,532',
    pctChange: '+23%',
  },
]

export const CreateYourStrategySection = () => {
  return (
    <ContentContainer>
      <Content>
        <h1>Create your strategy</h1>
        <p className="paragraph-xl">
          Enso takes social trading to a whole new level! Experience less friction, get rewarded and have fun!
        </p>
        <SLink href="/create">Create a Strategy</SLink>

        <Carousel>
          <CarouselContent count={mockData.length}>
            {mockData.map((item, i) => {
              return <StrategyCardSmall key={`${i}_${item.id}`} data={item} variant={1} />
            })}
          </CarouselContent>
        </Carousel>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 88px 0;
  position: relative;
  z-index: 2;

  & > h1 {
    margin-top: 30px;
  }

  & > p {
    margin-top: 32px;
  }

  & .paragraph-xl {
    margin-top: 48px;
    color: ${Colors.Neutral[300]};

    @media ${Device.mobile} {
      margin-top: 24px;
      width: 88%;
    }
  }

  & a {
    margin: 48px 0 0 0;
  }
`

const Carousel = styled.div`
  min-height: 350px;
  margin: 120px 0 0 0;
  position: relative;
  width: 100%;
`

const CarouselContent = styled.div<{ count: number }>`
  display: flex;
  justify-content: space-between;
  gap: 46px;
  position: absolute;
  left: 0;
  transform: translate(-5%, 0);

  animation-duration: 25s;
  animation-name: slidein;
  animation-iteration-count: infinite;

  @keyframes slidein {
    from {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(0%);
    }
  }
`
