import { DialogWrapper, FormFooter, FormWrapper, Header, Modal, ModalTitle } from '../base/Modal'
import React from 'react'
import styled, { css } from 'styled-components'
import { ClaimableAmount, useVampire } from '../../providers/vampire/context'
import { Button, ButtonSize, ButtonVariant } from '../base/Button'
import { Colors } from '../../constants'
import { TokenLogoIcon } from '../dialogs/TokensDialog/TokenLogoIcon'
import { getVampireAttackIconSrc } from '../icons/VampireAttack'
import { formatNumber } from '../../misc/helpers/number'
import { formatUnits } from '@ethersproject/units'
import { TokenUsdPriceState, useTokenUsdPrice } from '../../hooks/useTokenUsdPrice'
import { useVampireClaim, useVampireWithdraw } from '../../providers/vampire/useVampireDetails'
import { ClaimButton } from '../base/TransactionButton'
import { MUST_WITHDRAW, VAMPIRE_PRICES } from '../../providers/vampire/data'
import { addressEqual } from '../../misc/helpers/address'
import { useAuthentication } from '../../providers/authentication/context'
import { DeploymentSpinner } from '../icons/DeploymentSpinner'

export const VampireClaim = () => {
  const { claims, setOpen, open } = useVampire()

  return claims.length ? (
    <StyledButton variant={ButtonVariant.primary} size={ButtonSize.m} onClick={() => setOpen(!open)}>
      Claim
    </StyledButton>
  ) : null
}

export const VampireClaimModal = () => {
  const { open, setOpen, claims } = useVampire()
  const [auth, _, status] = useAuthentication()

  return (
    <VampireDialogModal open={open} onClose={() => setOpen(false)}>
      <StyledDialogWrapper>
        <FormWrapper freeBottom>
          <Header>
            <StyledModalTitle>Claim strategy tokens</StyledModalTitle>
          </Header>
          <StyledVampireContent>
            {status === 'LOADING' ? (
              <StyledLoader>
                <LoaderText>Waiting for MetaMask...</LoaderText>
                <DeploymentSpinner />
              </StyledLoader>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow header>
                    <TableCell>Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHeader>
                <TableContent>
                  {(claims ?? []).map((claimable) => {
                    const { token, amount } = claimable
                    const amountAsNumber = +formatUnits(amount, token.decimals)
                    const mustWithdraw = MUST_WITHDRAW.includes(token.address.toLowerCase())
                    return (
                      <TableRow>
                        <TableCell flex>
                          <TokenLogoIcon
                            marginless
                            symbol={token.symbol}
                            logoURI={getVampireAttackIconSrc(token.symbol)}
                          />
                          <TokenName>{token.name}</TokenName>
                        </TableCell>
                        <TableCell>
                          <TokenName>
                            {formatNumber(amountAsNumber, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 4,
                            })}
                          </TokenName>
                        </TableCell>
                        <TokenPriceCell address={token.address} amount={amountAsNumber} />
                        {mustWithdraw ? (
                          <WithdrawStrategyCell withdrawable={claimable} />
                        ) : (
                          <ClaimStrategyCell claimable={claimable} />
                        )}
                      </TableRow>
                    )
                  })}
                </TableContent>
              </Table>
            )}
          </StyledVampireContent>
        </FormWrapper>
      </StyledDialogWrapper>
    </VampireDialogModal>
  )
}

const ClaimStrategyCell = ({ claimable }: { claimable: ClaimableAmount }) => {
  const { send: claimStrategy, state: claimStrategyState } = useVampireClaim()

  const onClaimHandler = () => {
    claimStrategy(claimable.token.address)
  }
  return (
    <TableCell>
      <ClaimButton transactionStatus={claimStrategyState} onClick={onClaimHandler} spinnerSize={16}>
        Claim
      </ClaimButton>
    </TableCell>
  )
}

const WithdrawStrategyCell = ({ withdrawable }: { withdrawable: ClaimableAmount }) => {
  const { send: withdrawStrategy, state: withdrawStrategyState } = useVampireWithdraw()

  const onWithdrawHandler = () => {
    withdrawStrategy(withdrawable.token.address)
  }
  return (
    <TableCell>
      <ClaimButton transactionStatus={withdrawStrategyState} onClick={onWithdrawHandler} spinnerSize={16}>
        Withdraw
      </ClaimButton>
    </TableCell>
  )
}

const TokenPriceCell = ({ address, amount }: { address: string; amount: number }) => {
  const { tokenUsdPrice, priceState } = useTokenUsdPrice(address)
  const backupPrice = VAMPIRE_PRICES.find((price) => addressEqual(price.address, address))

  return (
    <TableCell>
      {priceState === TokenUsdPriceState.Price || !!backupPrice?.price
        ? '$' + (Math.round(+(tokenUsdPrice || backupPrice?.price || '0')) * amount).toLocaleString()
        : 'not found'}
    </TableCell>
  )
}

const StyledDialogWrapper = styled(DialogWrapper)`
  width: 100%;
`
const Flexy = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`
const LoaderText = styled.div`
  margin-bottom: 16px;
`
const StyledLoader = styled.div`
  min-height: 444px;
  ${Flexy};
  flex-direction: column;
`

const Table = styled.div`
  width: 100%;
`
const TableHeader = styled.div`
  height: 40px;
  color: ${Colors.Neutral[700]};
  font-weight: 400;
  ${Flexy};
  > div > div:first-child {
    color: ${Colors.Neutral[900]};
  }
`
const TableContent = styled.div`
  ${Flexy};
  flex-direction: column;
  font-weight: 500;
`
const TableRow = styled.div<{ header?: boolean }>`
  ${Flexy};
  ${({ header }) =>
    header
      ? css`
          height: 40px;
        `
      : css`
          height: 50px;
          flex: auto;
        `};
`
const TableCell = styled.div<{ flex?: boolean }>`
  width: 100%;
  text-align: right;
  flex: 1;
  ${({ flex }) =>
    flex &&
    css`
      ${Flexy};
      justify-content: flex-start;
    `};

  &:first-child {
    text-align: left;
  }
`

export const StyledVampireContent = styled.div`
  width: 100%;
  display: grid;
  margin-right: auto;
  gap: 16px;
`

const VampireDialogModal = styled(Modal)`
  height: fit-content;
  min-width: 488px;
  min-height: fit-content;
  z-index: 1;
  ${FormWrapper} {
    min-width: 492px;
    padding: 16px 24px 24px;
  }
`

export const VampireModalFooter = styled(FormFooter)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 24px;
  position: relative;
  border: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% + 48px);
    height: 1px;
    background-color: ${Colors.Neutral[200]};
    transform: translateX(-50%);
  }
`

export const InfoTextWrapper = styled.div<{ warning?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 100%;
  color: ${({ warning }) => (warning ? Colors.Negative[700] : Colors.Primary[500])};
  background: ${({ warning }) => (warning ? Colors.Negative[400] : Colors.Primary[100])};
  border-radius: 16px;
`

export const InfoText = styled.div`
  margin-left: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
`
export const StyledModalTitle = styled(ModalTitle)`
  font-size: 24px;
  font-weight: 700;
`
export const StyledDialogContent = styled.div`
  display: grid;
  width: 100%;
  margin-right: auto;
  gap: 16px;
`
const StyledButton = styled(Button)`
  margin-top: 0;
  min-width: unset;
`
const TokenName = styled.span`
  margin-left: 12px;
  width: 111px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
