import { useQuery, UseQueryResult } from 'react-query'
import { useMemo } from 'react'
import { httpCoinGeckoClient } from '../../httpClients/httpCoinGeckoClient'
import { TokenSeries } from '../../components/base/Chart/LineChart/useTokenSeries'

export interface CoinGeckoCoin {
  id: string
  symbol: string
  name: string
  platforms: Record<string, any>
}

export interface CoinGeckoMarketData {
  id: string
  symbol: string
  name: string
  image: string
  current_price: number
  market_cap: number
  market_cap_rank: number
  fully_diluted_valuation?: any
  total_volume: number
  high_24h: number
  low_24h: number
  price_change_24h: number
  price_change_percentage_24h: number
  market_cap_change_24h: number
  market_cap_change_percentage_24h: number
  circulating_supply: number
  total_supply: number
  max_supply?: any
  ath: number
  ath_change_percentage: number
  ath_date: Date
  atl: number
  atl_change_percentage: number
  atl_date: Date
  roi?: any
  last_updated: Date
}

export const getCoinGeckoTokenSeries = (url: string): Promise<TokenSeries> => {
  return httpCoinGeckoClient.get(url).then(({ data }) => data)
}
export const getCoinGeckoMarketData = (url: string): Promise<CoinGeckoMarketData[]> => {
  return httpCoinGeckoClient.get(url).then(({ data }) => data)
}
export const getCoinGeckoCoins = (url: string): Promise<CoinGeckoCoin[]> => {
  return httpCoinGeckoClient.get(url).then(({ data }) => data)
}

export const useCoinGeckoMarketData = (coinGeckoIds: string[] | undefined) => {
  const call = 'coins/markets'
  const searchParams = new URLSearchParams()
  searchParams.set('vs_currency', 'usd')
  searchParams.set('ids', (coinGeckoIds ?? []).join(',') ?? '')
  const queryString = searchParams.toString()
  const queryKey = [call, queryString].join('?')

  const result: UseQueryResult<CoinGeckoMarketData[]> = useQuery({
    queryKey,
    queryFn: () => getCoinGeckoMarketData(queryKey),
    enabled: !!coinGeckoIds?.length,
  })

  return useMemo(() => {
    return result?.data ?? null
  }, [result])
}
