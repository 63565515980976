import React from 'react'
import { Colors, Device, Fonts, Shadows, Transitions } from '../../../newDesign/styles'
import styled from 'styled-components'

type onChange = (tab: string) => void
type onClick = () => void

interface Props {
  isActive: boolean
  title: string
  name: string
  onChange: onChange
}

interface Button {
  isActive: boolean
  onClick: onClick
}

export const Tab = ({ isActive, title, name, onChange }: Props) => {
  const handleClick: onClick = () => onChange(name)

  return (
    <Button isActive={isActive} onClick={handleClick}>
      {title}
    </Button>
  )
}

const Button = styled.button<Button>`
  height: 48px;
  display: flex;
  white-space: nowrap;
  padding: 16px 24px;
  border: 1px solid ${Colors.Primary[100]};
  box-shadow: ${Shadows.small};
  border-radius: 41px;
  background-color: ${(props) => (props.isActive ? '#FFFFFF' : 'none')};
  font-family: ${Fonts.secondary};
  color: ${(props) => (props.isActive ? Colors.Neutral[400] : Colors.Neutral[300])};
  font-weight: 600;
  transition: ${Transitions.default};

  @media ${Device.mobile} {
    box-shadow: none;
  }

  &:hover {
    box-shadow: ${Shadows.small};
    background-color: #ffffff;
    color: ${Colors.Neutral[400]};
  }
`
