import React from 'react'
import { LoadingBar, LoadingBarAction } from '../LoadingBar'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const PageLoadingBar = () => {
  const router = useRouter()

  const [action, setAction] = useState<LoadingBarAction>(LoadingBarAction.IDLE)

  useEffect(() => {
    const handleStart = () => setAction(LoadingBarAction.START)
    const handleStop = () => setAction(LoadingBarAction.FINISH)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  return <LoadingBar action={action} />
}
