import React, { useState } from 'react'
import styled from 'styled-components'

import { Device } from '../../../newDesign/styles'
import { TeamCardProps } from '../../../components/pages/index/TeamCard'
import { ContentContainer, Tabs, TeamMemberCard, PartnerLogo } from '../base'

const ICONS = [
  {
    alt: 'comopany1',
    src: '/images/landing/partners/company1.svg',
  },
  {
    alt: 'comopany3',
    src: '/images/landing/partners/company3.svg',
  },
  {
    alt: 'comopany4',
    src: '/images/landing/partners/company4.svg',
  },
  {
    alt: 'comopany5',
    src: '/images/landing/partners/company5.svg',
  },
  {
    alt: 'comopany6',
    src: '/images/landing/partners/company6.svg',
  },
  {
    alt: 'twitter',
    src: '/images/landing/partners/twitter.svg',
  },
  {
    alt: 'comopany7',
    src: '/images/landing/partners/company7.svg',
  },
  {
    alt: 'comopany8',
    src: '/images/landing/partners/company8.svg',
  },
  {
    alt: 'comopany9',
    src: '/images/landing/partners/company9.svg',
  },
  {
    alt: 'share',
    src: '/images/landing/partners/shareIcon.svg',
  },
]

const TABS = [
  {
    name: 'all',
    title: 'All',
  },
  {
    name: 'engineering',
    title: 'Engineering',
  },
  {
    name: 'design',
    title: 'Design',
  },
  {
    name: 'tokenEconomics',
    title: 'Token Economics',
  },
]

export const team: TeamCardProps[] = [
  {
    name: 'Connor Howe',
    title: 'Liquidity Hunter',
    image: '/images/landing/team/connorHowe.png',
    twitter: 'ConnorSB13',
    linkedin: 'connorhowe',
    position: 'Founder',
    departments: ['engineering', 'tokenEconomics'],
  },
  {
    name: 'Milos Costantini',
    title: `Michellin \n Guide`,
    image: '/images/landing/team/milosConstantini.png',
    twitter: 'MilosCostantini',
    linkedin: 'miloscostantini',
    position: 'Strategy & Subgraph Engineer ',
    departments: ['engineering'],
  },
  {
    name: 'Annette G.',
    title: 'Photographer',
    image: '/images/landing/team/annetteG.png',
    twitter: 'honestlyany1',
    linkedin: 'annette-g-b84b2597',
    position: 'Strategy & Operations',
    departments: [],
  },
  {
    name: 'Peter Phillips',
    title: 'Gardener',
    image: '/images/landing/team/peterPhilips.png',
    linkedin: 'peter-phillips-166a06168',
    position: 'Lead Solidity Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Filip Małachowicz',
    title: `Board \n Game Geek`,
    image: '/images/landing/team/filipMatachowicz.png',
    twitter: 'exef_eth',
    linkedin: 'fmalacho',
    position: 'Backend/Solidity Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Ben Wolf',
    title: 'Night Owl',
    image: '/images/landing/team/benWolf.png',
    linkedin: 'benjamin-wolf-73035b149',
    twitter: 'ImBenWolf',
    position: 'Full Stack Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Mark Shields',
    title: 'The Beekeeper',
    image: '/images/landing/team/markShields.png',
    linkedin: 'markashields',
    position: 'DevOps Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Joe Pegler',
    title: 'The JPEG',
    image: '/images/landing/team/joePegler.png',
    linkedin: 'joe-pegler',
    twitter: 'joepegler',
    position: 'Lead Frontend Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Andre Meyer',
    title: 'Crazy Dog Person',
    image: '/images/landing/team/andreMeyer.png',
    linkedin: 'andre-meyer-91942342',
    twitter: 'andremeyer93',
    position: 'Frontend Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Oskar Vu',
    title: 'Eternal Boy',
    image: '/images/landing/team/oskarVu.jpg',
    twitter: 'oskar_vu',
    position: 'Frontend Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Maks Akymenko',
    title: 'Wanderer',
    image: '/images/landing/team/maksA.jpg',
    linkedin: 'maksakymenko',
    twitter: 'maks_akymenko',
    position: 'Frontend Engineer',
    departments: ['engineering'],
  },
  {
    name: 'Ayik Four',
    title: 'The Chef',
    image: '/images/landing/team/ayikFour.png',
    linkedin: 'ayikfour4 ',
    twitter: 'paswotnya',
    position: 'Designer',
    departments: ['design'],
  },
  {
    name: 'Roger Fernandez',
    title: 'Coffee Lover',
    image: '/images/landing/team/rogerFernandez.png',
    linkedin: 'rfguri',
    twitter: 'rfguri',
    position: 'Product Management',
    departments: ['tokenEconomics'],
  },
  {
    name: 'Mazzi',
    title: 'The Godfather',
    image: '/images/landing/team/mazzi.png',
    twitter: 'mazzi_eth',
    position: 'Token Engineering',
    departments: ['tokenEconomics'],
  },
  {
    name: 'Patrick Burke',
    title: 'Trivia Master',
    image: '/images/landing/team/patrickBurke.png',
    twitter: 'pburketr',
    linkedin: 'patrick-burke-63665989',
    position: 'Recruitment',
    departments: [],
  },
]

export const Team = () => {
  const [activeTab, setActiveTab] = useState<string>(TABS[0].name)

  return (
    <ContentContainer>
      <Content>
        <PartnersContainer>
          {ICONS.map((icon, i) => (
            <PartnerLogo key={i} alt={icon.alt} source={icon.src} />
          ))}
        </PartnersContainer>
        <h1>The Enso Force</h1>
        <Tabs
          tabs={TABS}
          onChange={(tab) => {
            setActiveTab(tab)
          }}
        />
        <TeamContainer>
          {team
            .filter((item) => {
              if (activeTab === 'all') return true

              return item.departments.find((department) => department === activeTab)
            })
            .map((member, i) => (
              <TeamMemberCard key={i} {...member} />
            ))}
        </TeamContainer>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  padding-top: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${Device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
  }

  & > h1 {
    margin-top: 64px;
    margin-bottom: 72px;
    width: 390px;
    display: flex;
    text-align: center;
    align-self: center;

    @media ${Device.mobile} {
      display: flex;
      justify-content: center;
      font-weight: 800;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.04em;
      width: 100%;
    }
  }
`

const PartnersContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 935px;
  flex-wrap: wrap;

  @media ${Device.mobile} {
    width: 375px;
  }
`

const TeamContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;

  @media ${Device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`
