import { useQuery } from 'react-query'
import { getCoinGeckoCoins } from './queries/useCoinGeckoCalls'
import { useMemo } from 'react'

export const useCoingeckoCoins = (disabled?: boolean) => {
  const call = 'coins/list'
  const searchParams = new URLSearchParams()
  const queryString = searchParams.toString()
  const queryKey = [call, queryString].join('?')

  const result = useQuery({
    queryKey,
    queryFn: () => getCoinGeckoCoins(queryKey),
    enabled: !disabled,
  })

  return useMemo(() => {
    return result?.data ?? []
  }, [result.data])
}
