import { useEthers } from '@usedapp/core'
import { useMutation, useQuery } from 'react-query'
import { useCallback, useMemo, useState } from 'react'
import { guild, prepareBodyWithSign, user } from '@guildxyz/sdk'
import _get from 'lodash/get'
import { useRefetchOnNewBlock } from '../../../infrastructure/subgraph/useRefetchOnNewBlock'
import { Bytes } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import axios from 'axios'
import { useStatus } from '../../../hooks/useStatus'
import uniq from 'lodash/uniq'

import whitelistJson from './whitelist.local.json'

const GUILD_ID = 4014

interface WhitelistData {
  whitelistedAddresses: string[]
  canWhitelist: boolean
  isWhitelisted: boolean
}

export const useJoinWhitelistMutation = (address?: string | null, library?: Web3Provider) => {
  const status = useStatus()
  const callback = useCallback(async () => {
    if (library && address) {
      status.setLoading()
      const signerFunction = (signableMessage: string | Bytes) =>
        library.getSigner(address).signMessage(signableMessage)
      try {
        const bodyWithPayload = await prepareBodyWithSign(address, signerFunction, {
          guildId: GUILD_ID,
          platform: 'DISCORD',
        })
        const result = await axios.post(`https://api.guild.xyz/v1/user/join/`, bodyWithPayload, {
          headers: { 'Content-Type': 'application/json' },
        })
        status.setSuccess()
        return result?.data
      } catch (error) {
        status.setError(error)
        console.log(error)
      }
    }
  }, [address, library, status])

  return [callback, status] as const
}

export const useWhitelistData = (address: string): WhitelistData | null => {
  const [whitelistedAddresses, isLoading] = useWhitelistedAddresses()
  const canWhitelist = useCanWhitelist(address)
  const isWhitelisted = useIsMember(address)

  return useMemo(() => {
    const isValid = whitelistedAddresses.length
    return isValid
      ? {
          whitelistedAddresses,
          canWhitelist,
          isWhitelisted,
        }
      : null
  }, [canWhitelist, isWhitelisted, whitelistedAddresses])
}

export const useWhitelistedAddresses = () => {
  const { account } = useEthers()

  const { data, refetch, isLoading } = useQuery('guildData' + account, () => guild.get(GUILD_ID))
  useRefetchOnNewBlock(refetch)

  return useMemo<[string[], boolean]>(() => {
    const whitelistAddresses = _get(data, 'roles[0].requirements[0].data.addresses', []).map((address: string) =>
      address.toLowerCase()
    )

    const totalList = whitelistJson.map((addr) => addr.toLowerCase()).concat(whitelistAddresses)

    const uniqList = uniq(totalList)

    return [uniqList, isLoading]
  }, [data, isLoading])
}

export const useCanWhitelist = (address?: string): boolean => {
  const { account } = useEthers()
  const [addresses, isLoading] = useWhitelistedAddresses()
  const addressOrAccount = (address ?? account ?? '').toLowerCase()
  return useMemo(() => {
    if (!addressOrAccount) return false

    const inLocalList = account ? whitelistJson.includes(account) : false
    if (inLocalList) return true

    return !!addresses?.includes(addressOrAccount)
  }, [addressOrAccount, addresses, account])
}

export const useIsMember = (address?: string): boolean => {
  const { account } = useEthers()

  const addressOrAccount = (address ?? account ?? '').toLowerCase()
  const { data, refetch } = useQuery('memberships' + account, () => user.getMemberships(addressOrAccount), {
    enabled: !!addressOrAccount,
  })

  useRefetchOnNewBlock(refetch)

  return useMemo(() => {
    return !!data?.find((membership) => membership.guildId === GUILD_ID)
  }, [data])
}
