import React from 'react'
import styled from 'styled-components'

export function CompleteStarsSvg({ className }: { className?: string }) {
  return (
    <SvgIcon width="294" height="121" viewBox="0 0 294 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M253.302 26.8486L260.782 23.1824L257.773 13.4014L249.743 16.2993L253.302 26.8486Z" fill="#F367FF" />
      <path d="M123.691 43.3209L134.171 39.0126L134.655 31.4702L123.837 35.24L123.691 43.3209Z" fill="#F367FF" />
      <path d="M229.347 30.4202L224.067 24.1107L228.773 14.8049L234.68 20.4864L229.347 30.4202Z" fill="#F367FF" />
      <path d="M88.6387 23.2078L73.9326 22.2391L80.1841 22.6509L96.0038 23.6929L88.6387 23.2078Z" fill="#F367FF" />
      <path d="M264.036 43.7287L255.141 47.5437L256.314 55.4975L265.237 51.423L264.036 43.7287Z" fill="#FFCA63" />
      <path d="M169.968 44.5333L160.596 39.5074L162.759 36.4042L172.132 41.5559L169.968 44.5333Z" fill="#FFCA63" />
      <path d="M62.6993 33.1838L55.1911 28.5762L60.1097 23.2925L67.5261 28.0869L62.6993 33.1838Z" fill="#FFCA63" />
      <path d="M197.956 38.9041L208.866 33.876L209.724 27.826L198.713 33.0662L197.956 38.9041Z" fill="#FFCA63" />
      <path
        d="M131.93 52.3342C133.976 51.7667 136.278 54.0471 136.425 56.852C136.567 59.5536 134.731 62.3271 132.687 63.2068C130.371 64.2045 127.752 61.9666 127.659 59.1635C127.577 56.6849 129.465 53.018 131.93 52.3342Z"
        fill="#FFCA63"
      />
      <path
        d="M66.789 38.9946C68.335 39.2655 70.4352 38.8788 70.8981 38.2243C71.3437 37.5938 70.236 36.8144 68.7308 36.4698C67.0241 36.079 64.6791 36.4345 64.2596 37.0923C63.8885 37.6739 64.9267 38.6684 66.789 38.9946Z"
        fill="#FFCA63"
      />
      <path
        d="M231.302 43.819C232.94 43.2991 234.802 46.1052 234.938 49.3737C235.069 52.5218 233.613 55.6188 231.978 56.5015C230.125 57.5027 228.01 54.7245 227.917 51.4618C227.836 48.5768 229.328 44.4454 231.302 43.819Z"
        fill="#FFCA63"
      />
      <path
        d="M94.7576 11.006C94.5465 11.2331 94.415 11.5222 94.4011 11.7804L94.0378 17.9785L89.8195 15.9079C89.6309 15.8163 89.3687 15.8664 89.1206 16.0488L83.6681 19.9838L85.8917 13.3916C85.9864 13.1184 85.963 12.8649 85.8398 12.703L82.8034 8.91637L88.4182 6.94269C88.6723 6.85396 88.921 6.6331 89.0794 6.35554L92.654 0.124357L93.9071 5.45072C93.958 5.67721 94.124 5.80685 94.3551 5.8057L99.6328 5.69045L94.7576 11.006Z"
        fill="#F367FF"
      />
      <path
        d="M179.518 36.2456C179.646 36.0477 179.681 35.7572 179.616 35.4686L178.083 28.5513L182.641 32.0791C182.845 32.2357 183.074 32.2525 183.251 32.1156L187.146 29.1882L187.064 36.0301C187.058 36.313 187.157 36.6067 187.32 36.8245L191.296 41.9643L186.657 42.6249C186.446 42.6542 186.281 42.8345 186.217 43.1044L184.767 49.1587L181.989 42.7754C181.873 42.5046 181.68 42.3113 181.464 42.2478L176.578 40.8983L179.518 36.2456Z"
        fill="#F367FF"
      />
      <path
        d="M6.45796 63.7809C6.38074 63.9594 6.298 64.1849 6.23585 64.3852L4.7407 69.1912L4.74763 67.6389C4.74769 67.5703 4.70618 67.6123 4.63525 67.7566L3.09331 70.872L4.89204 65.7378C4.96689 65.525 5.02369 65.3289 5.04767 65.205L5.59127 62.3089L6.69156 60.7109C6.74121 60.6391 6.82125 60.4649 6.90434 60.248L8.77174 55.3769L7.66455 59.4877C7.6172 59.6626 7.60563 59.7609 7.63214 59.7572L8.25822 59.6031L6.45796 63.7809Z"
        fill="#F367FF"
      />
      <path
        d="M287.551 110.5C287.39 110.743 287.303 111.063 287.316 111.359L287.598 118.438L283.722 115.707C283.549 115.586 283.326 115.621 283.127 115.81L278.756 119.864L280.057 112.489C280.113 112.184 280.068 111.891 279.945 111.695L276.934 107.095L281.636 105.305C281.848 105.224 282.044 104.992 282.155 104.687L284.668 97.8404L286.274 104.056C286.34 104.32 286.497 104.483 286.699 104.501L291.286 104.814L287.551 110.5ZM284.037 114.571C284.037 114.571 283.263 114.477 284.037 114.571V114.571Z"
        fill="#F367FF"
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  position: relative;
`
