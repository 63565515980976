import { createGlobalStyle } from 'styled-components'
import { Colors, Fonts, SidebarNormalWidth } from '../constants'
import 'react-toastify/dist/ReactToastify.css'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  :root {
    --sidebar-width: ${SidebarNormalWidth};
  }

  body, html {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    background-color: ${Colors.White};
    font-family: ${Fonts.Inter};
    font-size: 16px;
    line-height: 16px;
    color: ${Colors.Neutral[900]};
  }

  body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
  }

  body > #__next {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  button {
    font-family: ${Fonts.Inter};
    font-size: 14px;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 150%;
    font-weight: 700;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 12px;
  }

  p {
    margin: 0;
  }
  input, textarea {
    font-family: ${Fonts.Inter};
    font-weight: 400;
    border: none;
    &[type='number']{
      -moz-appearance: textfield;
    }
  }
`
