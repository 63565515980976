import React from 'react'
import Img from 'next/image'
import styled from 'styled-components'

import { SLink } from '../base/StyledLink'
import { ContentContainer } from '../base'

import { Colors, Device } from '../../styles'
import { HeroSvg } from '../icons/Hero'

export const MainSection = () => {
  return (
    <ContentContainer>
      <Content>
        <ImageContainer>
          <HeroSvg />
        </ImageContainer>
        <h1>Social trading, redefined.</h1>
        <ParagraphXl>
          From easy strategy management, investing with friends, competitions and exploring top trading strategies
        </ParagraphXl>
        <SLink href="/explore/strategies/1">Launch Enso</SLink>
        <StyledPressContainer>
          Press <PressSymbol>⇧</PressSymbol> <PressSymbol>A</PressSymbol> anytime to launch the App
        </StyledPressContainer>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 88px 0;
  position: relative;
  z-index: 2;

  & > h1 {
    margin-top: 30px;
  }

  & a {
    margin: 32px 0 0 0;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 505px;
  position: absolute;
  opacity: 0.2;
  top: 60px;

  @media ${Device.mobile} {
    min-height: 200px;
    top: 70px;
  }
`

const PressSymbol = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: inline-block;
  color: ${Colors.Neutral[100]};
  font-weight: 600;
`

const Background = styled.div`
  opacity: 0.2;
  top: -300px;
  position: absolute;
  width: 100%;
  left: 50px;
  height: 1360px;
  background: radial-gradient(43.32% 43.32% at 50% 50%, rgba(217, 107, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: normal;

  @media ${Device.mobile} {
    transform: translate(-50%, 0);
    left: 50%;
  }
`

const ParagraphXl = styled.p`
  margin-top: 48px;
  color: ${Colors.Neutral[300]};

  font-size: 24px;
  line-height: 36px;
  color: ${Colors.Neutral[300]};

  @media ${Device.mobile} {
    margin-top: 24px;
    width: 88%;
    font-size: 16px;
    line-height: 24px;
  }
`

const StyledPressContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  color ${Colors.Neutral[400]};
  margin-top: 32px;

  @media ${Device.mobile} {
    display: none;
  }
`
