import styled from 'styled-components'
import { Colors, Fonts } from '../../constants'

export const BlockTitle = styled.h3<{ defaultTextTransform?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: ${Fonts.Inter};
  color: ${Colors.Neutral[900]};
  text-transform: ${({ defaultTextTransform }) => (defaultTextTransform ? 'none' : 'capitalize')};
`

export const HeadingBlockTitle = styled(BlockTitle)`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.Neutral[900]};
`

export const StandardBlockTitle = styled(BlockTitle)`
  font-weight: 500;
  line-height: 16px;
`
