import { ChainId } from './chainId'

export const MULTICALL_ADDRESS: { [key in ChainId]: string } = {
  [ChainId.Mainnet]: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  [ChainId.Kovan]: '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a',
  [ChainId.Rinkeby]: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
  [ChainId.Goerli]: '0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e',
  [ChainId.Ropsten]: '0x53c43764255c17bd724f74c4ef150724ac50a3ed',
  [ChainId.xDai]: 'unsupported',
  [ChainId.Localhost]: 'unsupported',
  [ChainId.Hardhat]: 'unsupported',
  [ChainId.BSC]: 'unsupported',
  [ChainId.Mumbai]: 'unsupported',
  [ChainId.Polygon]: 'unsupported',
  [ChainId.Harmony]: 'unsupported',
  [ChainId.Harmony]: 'unsupported',
  [ChainId.Moonriver]: 'unsupported',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
