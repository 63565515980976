import { CHAIN_NAMES, ChainId, useEthers } from '@usedapp/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import CoinbaseWalletIcon from '../../../public/icons/WalletsIcons/CoinbaseWalletIcon.svg'
import MetamaskIcon from '../../../public/icons/WalletsIcons/MetamaskIcon.svg'
import TrustWalletIcon from '../../../public/icons/WalletsIcons/TrustWalletIcon.svg'
import WalletConnectIcon from '../../../public/icons/WalletsIcons/WalletConnectIcon.svg'
import { Colors, SUPPORTED_CHAINS } from '../../constants'
import { Button, ButtonInner, ButtonVariant } from '../base/Button'
import { DialogWrapper, FormWrapper, Header, Modal, ModalTitle } from '../base/Modal'
import { DeploymentProcessWrapper, DeploymentSpinner } from '../icons/DeploymentSpinner'
import { CommonImage } from '../page/CommonImage'
import { useRequestAuth } from '../../providers/requestAuth/context'
import { infuraRpcUrl } from '../../../config/getApplicationConfig'

const injected = new InjectedConnector({ supportedChainIds: SUPPORTED_CHAINS })
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: infuraRpcUrl(CHAIN_NAMES[ChainId.Mainnet]) },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

interface SelectWalletItemProps {
  walletName: string
  walletIcon: string
  onClick: () => void
}

export function SelectWalletItem({ walletName, walletIcon, onClick }: SelectWalletItemProps) {
  return (
    <WalletListItem>
      <WalletItem variant={ButtonVariant.secondary} onClick={onClick}>
        {walletName}
        <WalletItemIcon src={walletIcon} />
      </WalletItem>
    </WalletListItem>
  )
}

interface SelectWalletModalProps {
  open: boolean
  onClose: () => void
}

export function SelectWalletModal({ open, onClose }: SelectWalletModalProps) {
  const { activate, account } = useEthers()
  const [, setShouldRequestSignature] = useRequestAuth()

  const connect = useCallback(
    (name = '') => {
      if (!account) {
        if (['WalletConnect', 'TrustWallet'].includes(name as string)) {
          activate(walletconnect)
        } else {
          activate(injected)
        }
      }
      onClose()
      setShouldRequestSignature(true)
    },
    [account, activate, onClose, setShouldRequestSignature]
  )

  const availableWallets = [
    {
      name: 'Metamask',
      icon: MetamaskIcon,
      onClick: () => connect(),
    },
    {
      name: 'TrustWallet',
      icon: TrustWalletIcon,
      onClick: () => connect(),
    },
    {
      name: 'Coinbase Wallet',
      icon: CoinbaseWalletIcon,
      onClick: () => connect(),
    },
    {
      name: 'WalletConnect',
      icon: WalletConnectIcon,
      onClick: () => connect('WalletConnect'),
    },
  ]

  return (
    <WalletModal open={open} onClose={onClose}>
      <DialogWrapper>
        <FormWrapper>
          <Header>
            <ModalTitle defaultCase>Connect to a Wallet</ModalTitle>
          </Header>
          <NetworkInfoText>Please connect using Ethereum Mainnet</NetworkInfoText>
          <ListContainer>
            <ListWrapper>
              {!availableWallets && (
                <DeploymentProcessWrapper>
                  Loading
                  <DeploymentSpinner />
                </DeploymentProcessWrapper>
              )}
              <List>
                {availableWallets.map(({ name, icon, onClick }) => (
                  <SelectWalletItem key={name} walletName={name} walletIcon={icon} onClick={onClick} />
                ))}
              </List>
            </ListWrapper>
          </ListContainer>
        </FormWrapper>
      </DialogWrapper>
    </WalletModal>
  )
}

const WalletModal = styled(Modal)`
  height: fit-content;
  width: 452px;
  z-index: 3;

  ${FormWrapper} {
    padding: 16px 24px 24px;
  }
`

const NetworkInfoText = styled.div`
  font-weight: 600;
  margin: 12px 0;
  color: ${Colors.Warning[900]};
`

const WalletListItem = styled.li`
  width: 100%;
  height: fit-content;

  &:not(:last-child) {
    padding-bottom: 16px;
  }
`

const WalletItem = styled(Button)`
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 24px;

  ${ButtonInner} {
    grid-template-columns: 1fr 28px;
    justify-items: start;
    width: 100%;
  }
`

const WalletItemIcon = styled(CommonImage)`
  height: 28px;
  width: auto;
  max-width: 28px;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 12px;
  background-color: ${Colors.White};
  overflow: hidden;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  list-style: none;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  height: 100%;
`
