import Image from 'next/image'
import React from 'react'
import { Colors, Fonts, Shadows } from 'src/newDesign/styles'
import styled from 'styled-components'
import { UpIcon } from '../icons'

interface Props {
  src: string
  title: string
  value: string
  isOpacityNeeded: boolean
  className?: string
  subtitle?: string
}

export const InvestInCard = ({ src, title, value, isOpacityNeeded, subtitle, className }: Props) => {
  return (
    <Container Opacity={isOpacityNeeded} className={className}>
      <Image src={src} alt={`avatar-${title}`} width="50px" height="50px" />
      <TitleContainer>
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : <span />}
      </TitleContainer>
      <Value>
        <p>{value}</p>
        <UpIcon />
      </Value>
    </Container>
  )
}

const Container = styled.div<{ Opacity: Boolean }>`
  max-width: 302px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 11px 17px;
  background: #ffffff;
  box-shadow: ${Shadows.medium};
  border-radius: 14.6734px;
  width: 100%;

  animation-name: ${(props) => props.Opacity && 'opacity'};
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  & img {
    width: 49px;
    height: 49px;
    flex-basis: 50px;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`

const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: ${Colors.Neutral[300]};
  margin-bottom: 5px;
  font-family: ${Fonts.secondary};
`

const Subtitle = styled.p`
  color: ${Colors.Neutral[200]};
  font-family: ${Fonts.secondary};
  font-size: 16px;
  font-weight: 600;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 170px;
  padding: 5px 10px;

  & span {
    width: 30.57px;
    height: 12.23px;
    left: 0px;
    top: 19.89px;
    background: #efeff3;
    opacity: 0.8;
    border-radius: 19.5646px;
  }
`

const Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-family: ${Fonts.secondary};

  & p {
    font-size: 18px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.03em;
    color: ${Colors.Positive[900]};
    font-weight: 800;
    margin: 0 5px 0 0;
  }

  & .arrow {
    width: 20px;
    height: 20px;
    background: #dbffd4;
    border-radius: 43px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    animation-name: hide;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    & span {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid #00b04c;

      animation-name: arrow-hide;
      animation-duration: 3s;
      animation-timing-function: ease-out;
      animation-fill-mode: both;
      animation-iteration-count: 1;

      @keyframes arrow-hide {
        70% {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid ${Colors.Positive[900]};
        }
        100% {
          border-left: 0 solid transparent;
          border-right: 0 solid transparent;
          border-bottom: 0 solid ${Colors.Positive[900]};
        }
      }
    }

    @keyframes hide {
      0% {
        width: 20px;
        height: 20px;
      }
      70% {
        width: 20px;
        height: 20px;
      }
      100% {
        width: 0;
        height: 0;
      }
    }
  }
`
