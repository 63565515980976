import { ApolloProvider } from '@apollo/client'
import React, { ReactNode } from 'react'
import { useApollo } from '../../infrastructure/subgraph/useSubgraphClient'
import { AppProps } from 'next/app'

interface Props {
  children: ReactNode
  pageProps: AppProps['pageProps']
}

export function GraphQLProvider({ pageProps, children }: Props) {
  const client = useApollo(pageProps)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
