import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Colors, Fonts, Gradients } from '../../styles'
import Link from 'next/link'

export enum LinkVariants {
  primary = 'primary',
  secondary = 'secondary',
  vampireDash = 'vampireDash',
  outline = 'outline',
  tertiary = 'tertiary',
}

export enum LandingLinkSize {
  xs,
  s,
  default,
  m,
  l,
}

interface Props extends React.LinkHTMLAttributes<HTMLLinkElement> {
  children: ReactNode
  onClick?: () => void
  link?: boolean
  variant?: LinkVariants
  size?: LandingLinkSize
  href: string | undefined
}

export const ELink = ({
  children,
  type,
  href = '#',
  link = false,
  variant = LinkVariants.primary,
  size = LandingLinkSize.default,
  ...other
}: Props) => {
  return (
    <>
      {/*// @ts-ignore*/}
      <StyledLink variant={variant} size={size} {...other} href={href}>
        <p>{children}</p>
        <Overlay />
      </StyledLink>
    </>
  )
}

export const SLink = ({
  children,
  type,
  href = '#',
  link = false,
  variant = LinkVariants.primary,
  size = LandingLinkSize.default,
  ...other
}: Props) => {
  return (
    <Link href={href} passHref>
      {/*// @ts-ignore*/}
      <StyledLink variant={variant} size={size} {...other}>
        <p>{children}</p>
        <Overlay />
      </StyledLink>
    </Link>
  )
}

const TertiaryLinkCss = css`
  background: ${Colors.Neutral[0]};
  transition: 1s;

  p {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-weight: 600;
  }

  &:hover {
    box-shadow: 0 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;
    background: transparent;
    opacity: 0.7;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, rgba(21, 21, 21, 0.82) 0%, rgba(0, 0, 0, 0.369) 100%);
`
const OutlineLinkCss = css`
  background: transparent;
  transition: 1s;
  box-shadow: unset;

  p {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  &:hover {
    background: transparent;
    opacity: 0.7;
  }
`

const SecondaryLinkCss = css`
  background: ${Colors.Neutral[0]};
  transition: 1s;

  p,
  a {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-weight: 600;
  }

  @keyframes dash {
    0% {
      background: url('/images/landing/backgrounds/btn1.png');
    }
    20% {
      background: url('/images/landing/backgrounds/btn2.png');
    }
    40% {
      background: url('/images/landing/backgrounds/btn3.png');
    }
    60% {
      background: url('/images/landing/backgrounds/btn4.png');
    }
    80% {
      background: url('/images/landing/backgrounds/btn5.png');
    }
    100% {
      background: url('/images/landing/backgrounds/btn6.png');
    }
  }

  &:hover {
    animation: dash 1.5s ease;
    animation-iteration-count: infinite;
    box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;

    ${Overlay} {
      display: block;
    }

    p,
    span {
      -webkit-background-clip: auto;
      color: ${Colors.Neutral[0]};
      background: none;
      -webkit-text-fill-color: initial;
      position: relative;
      z-index: 4;
    }
  }
`

const PrimaryLinkCss = css`
  color: inherit;

  &:hover {
    background: linear-gradient(0deg, rgba(222, 222, 222, 0.72), rgba(222, 222, 222, 0.72)),
      radial-gradient(
          110.03% 307.14% at 87.5% -121.43%,
          #9985ff 0%,
          rgba(255, 103, 249, 0.88) 53.15%,
          rgba(255, 168, 87, 0.88) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;
    background-blend-mode: overlay, normal;
  }

  &:active {
    background: linear-gradient(0deg, rgba(166, 166, 166, 0.72), rgba(166, 166, 166, 0.72)),
      radial-gradient(
        110.03% 307.14% at 87.5% -121.43%,
        #9985ff 0%,
        rgba(255, 103, 249, 0.88) 53.15%,
        rgba(255, 168, 87, 0.88) 100%
      );
    background-blend-mode: overlay, normal;
  }
`

const VampireDashCSS = css`
  ${SecondaryLinkCss};
  animation: dash 1.5s ease;
  animation-iteration-count: infinite;
`

const StyledLink = styled.a<{ variant: LinkVariants; size: LandingLinkSize }>`
  background: ${Gradients.soft};
  border-radius: 12px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  width: fit-content;
  font-family: ${Fonts.secondary};
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  ${Overlay} {
    display: none;
  }

  & span {
    line-height: initial;
    margin: 0 auto;
    width: fit-content;
    position: relative;
    z-index: 5;
  }

  min-width: ${(props) => {
    switch (props.size) {
      case LandingLinkSize.xs:
      case LandingLinkSize.s:
        return 'fit-content'
      default:
        return '184px'
    }
  }};

  padding: ${(props) => {
    switch (props.size) {
      case LandingLinkSize.xs:
        return '10px 22px'
      case LandingLinkSize.s:
        return '18px 24px'
      default:
        return '16px 24px 16px 24px'
    }
  }};

  ${(props) => {
    switch (props.variant) {
      case LinkVariants.tertiary:
        return [TertiaryLinkCss]
      case LinkVariants.outline:
        return [OutlineLinkCss]
      case LinkVariants.vampireDash:
        return [VampireDashCSS]
      case LinkVariants.secondary:
        return [SecondaryLinkCss]
      default:
        return [PrimaryLinkCss]
    }
  }};
`
