import React from 'react'
import styled from 'styled-components'

interface IconProps {
  color?: string
  className?: string
}

export function SearchIcon({ color, className }: IconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" color={color || 'currentColor'} className={className}>
      <path
        fill="currentColor"
        d="M7.7 0A7.6 7.6 0 000 7.5c0 4.1 3.5 7.4 7.7 7.4 1.8 0 3.4-.6 4.7-1.5l4 3.8 1.4-1.4-4-3.8c1-1.3 1.6-2.8 1.6-4.5C15.4 3.3 12 0 7.7 0zM2 7.5C2 4.5 4.5 2 7.7 2s5.7 2.5 5.7 5.5-2.5 5.4-5.7 5.4A5.6 5.6 0 012 7.5z"
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
