import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { Button, SocialMedia } from '../base'
import { Colors, Device, Transitions } from '../../styles'
import { EnsoLogoLinkExpanded } from '../../../components/icons/EnsoLogoLink'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

interface SubscribeFormProps {
  status: 'sending' | 'success' | 'error' | null
  message: string | Error | null
  onValidated: ({ EMAIL }: { EMAIL: string }) => void
}

const SubscribeForm = ({ status, message, onValidated }: SubscribeFormProps) => {
  const email = useRef<HTMLInputElement>(null)

  const submit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    email.current &&
      email.current.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.current?.value,
      })
  }

  return (
    <>
      <SubscribeInputForm onSubmit={submit}>
        <StyledInput type="email" placeholder="your@email.here" ref={email} buttonSpacing={145} />
        <Button type="submit" size={0}>
          {status === 'sending' ? 'Sending' : status === 'success' ? 'Success' : 'Subscribe'}
        </Button>
      </SubscribeInputForm>
    </>
  )
}

export const Footer = () => {
  const url = 'https://finance.us1.list-manage.com/subscribe/post?u=457e322af40e469788895d67c&amp;id=a4cbd264ba'

  return (
    <Content>
      <TopLineContainer>
        <EnsoLogoLinkExpandedStyle href="/" />
        <SocialWrapper />
      </TopLineContainer>
      <MainPart>
        <FirstCol>
          <Navigation>
            <ul>
              <li>
                <Link href="/#main">
                  <a>About</a>
                </Link>
              </li>
              <li>
                <Link href="/team#main">
                  <a>Team</a>
                </Link>
              </li>
              <li>
                <a href="https://github.com/EnsoFinance" target="_blank">
                  Github
                </a>
              </li>
              <li>
                <a href="https://manifesto.enso.finance/" target="_blank">
                  Manifesto
                </a>
              </li>
              <li>
                <a href="https://docs.enso.finance/" target="_blank">
                  Docs
                </a>
              </li>
              <li>
                <Link href="/terms">
                  <a>Terms</a>
                </Link>
              </li>
              <li>
                <Link href="/privacy-policy">
                  <a>Privacy Policy</a>
                </Link>
              </li>
            </ul>
          </Navigation>
          <Description>
            Information shown above is for display purposes and does not yet represent created strategies on the Enso
            Protocol.
          </Description>
          <h5>Enso Finance 2022 © All rights reserved</h5>
        </FirstCol>
        <SecondCol>
          <h3>Stay in the loop</h3>
          <Description>Keep updated with Enso. Promise, no spam only fun things</Description>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <SubscribeForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
            )}
          />
          {/*<SubscribeInputForm onSubmit={onSubmit}>*/}
          {/*  <StyledInput type="email" placeholder="your@email.here" onChange={handleChangeEmail} />*/}
          {/*  <Button type="submit" size={0}>*/}
          {/*    Subscribe*/}
          {/*  </Button>*/}
          {/*</SubscribeInputForm>*/}
        </SecondCol>
      </MainPart>
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 48px 48px;

  @media ${Device.mobile} {
    padding: 40px 24px 48px;
  }
`

const MainPart = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 0 0;
  margin: 40px 0 0;

  justify-content: space-between;
  position: relative;
  align-items: end;

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    height: 2px;
    background: ${Colors.Primary[100]};
  }

  & a {
    font-weight: 600;
    &:visited {
      color: ${Colors.Neutral[300]};
    }
  }

  & h5,
  a {
    color: ${Colors.Neutral[300]};
  }

  & h3 {
    color: ${Colors.Primary[800]};
  }

  & h5 {
    margin: 24px 0 0 0;
  }

  @media ${Device.mobile} {
    flex-direction: column-reverse;
  }
`

const Navigation = styled.nav`
  margin-bottom: 48px;

  & ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 24px;

    @media ${Device.mobile} {
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
    }
  }

  & a {
    transition: ${Transitions.default};
    &:hover {
      color: ${Colors.Neutral[400]};
    }
  }
`

const FirstCol = styled.div`
  width: 50%;
  max-width: 500px;

  @media ${Device.mobile} {
    width: 100%;
    margin: 70px 0 0 0;
    max-width: none;
  }
`

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.Neutral[300]};

  @media ${Device.mobile} {
    font-size: 14px;
    line-height: 24px;
  }
`
const SecondCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;

  & h3 {
    margin: 0 0 16px 0;
  }

  & ${Description} {
    margin: 0 0 48px 0;
  }

  @media ${Device.mobile} {
    text-align: left;
    width: 100%;
  }
`

export const SubscribeInputForm = styled.form`
  position: relative;
  width: 100%;
  padding: 0 0 0 30px;

  & button {
    padding: 12px 24px;
    width: 125px;
    min-width: auto;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
  }

  @media ${Device.mobile} {
    padding: 0;
  }
`

export const StyledInput = styled.input<{ buttonSpacing?: number }>`
  position: relative;
  width: 100%;
  height: 56px;
  padding: 8px ${({ buttonSpacing }) => buttonSpacing ?? 24}px 8px 24px;
  box-shadow: 10px 12px 141px rgba(126, 129, 164, 0.2);
  border-radius: 16px;
  font-size: 20px;

  ::-webkit-input-placeholder {
    color: ${Colors.Neutral[200]};
  }
  ::-moz-placeholder {
    color: ${Colors.Neutral[200]};
  }
  :-ms-input-placeholder {
    color: ${Colors.Neutral[200]};
  }
  :-moz-placeholder {
    color: ${Colors.Neutral[200]};
  }

  @media ${Device.mobile} {
    font-size: 14px;
  }
`

const TopLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const EnsoLogoLinkExpandedStyle = styled(EnsoLogoLinkExpanded)`
  height: 24px;
  width: 97px;
`

const SocialWrapper = styled(SocialMedia)`
  flex-basis: 115px;

  & svg {
    fill: ${Colors.Primary[800]};
    color: ${Colors.Primary[800]};
    width: 20px;
    height: 20px;
  }
`
