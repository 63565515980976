import React from 'react'
import { Colors } from '../../../constants'
import styled from 'styled-components'
interface MediumSocialIconProps {
  color?: string
  className?: string
}
export function MediumSocialIcon({ className, color }: MediumSocialIconProps) {
  return (
    <SocialIcon viewBox="0 -1 25 17" fill={color ?? Colors.Neutral[900]} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.84611 0.0454102C11.9583 0.0454102 15.2916 3.44823 15.2916 7.64554C15.2916 11.8428 11.958 15.2454 7.84611 15.2454C3.73419 15.2454 0.400391 11.8428 0.400391 7.64554C0.400391 3.44823 3.73394 0.0454102 7.84611 0.0454102ZM19.7363 0.490723C21.7924 0.490723 23.4591 3.69377 23.4591 7.64566H23.4594C23.4594 11.5965 21.7926 14.8006 19.7365 14.8006C17.6804 14.8006 16.0137 11.5965 16.0137 7.64566C16.0137 3.6948 17.6802 0.490723 19.7363 0.490723ZM26.8001 7.64553C26.8001 4.10551 26.2137 1.23584 25.4907 1.23584C24.7678 1.23584 24.1816 4.10576 24.1816 7.64553C24.1816 11.1853 24.7675 14.0552 25.4907 14.0552C26.2139 14.0552 26.8001 11.1845 26.8001 7.64553Z"
        fill="#474A76"
      />
    </SocialIcon>
  )
}

export const SocialIcon = styled.svg<MediumSocialIconProps>`
  width: 16px;
  height: 16px;
  position: relative;
`
