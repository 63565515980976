export type EtherPriceAction =
  | { type: 'priceFetched'; price: number | undefined }
  | { type: 'fetchError'; error: Error }
export interface EtherPriceModel {
  price: number | undefined
  fetchError?: Error
}

export function etherPriceReducer(state: EtherPriceModel, action: EtherPriceAction): EtherPriceModel {
  switch (action.type) {
    case 'priceFetched':
      return {
        ...state,
        price: action.price,
      }

    case 'fetchError':
      return {
        ...state,
        fetchError: action.error,
      }
  }
}
