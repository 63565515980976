import { ENS_GATEWAY_BASE_URL, IPFS_GATEWAY_BASE_URL } from '../../components/TokenLists/constants/urls'
import { UnknownProtocol } from '../../model/Error'
import { isEnsAddress } from './isEnsAddress'

export function uriToHttpUrl(uri: string): string {
  const protocol = uri.split(':')[0].toLowerCase()

  if (isEnsAddress(uri)) {
    return `${ENS_GATEWAY_BASE_URL}${uri}`
  } else if (protocol === 'https' || protocol === 'http') {
    return uri
  } else if (protocol === 'ipfs') {
    const hash = uri.match(/^ipfs:(\/\/)?(.*)$/i)?.[2]
    return `${IPFS_GATEWAY_BASE_URL}${hash}`
  } else if (protocol === 'ipns') {
    const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2]
    return `${ENS_GATEWAY_BASE_URL}${name}`
  } else {
    throw new UnknownProtocol(protocol)
  }
}
