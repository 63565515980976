import React from 'react'
import styled from 'styled-components'

interface InfoIconProps {
  color?: string
  className?: string
}

export function InfoIcon({ color, className }: InfoIconProps) {
  return (
    <InfoIconSvg viewBox="0 0 16 16" fill="none" color={color || 'currentColor'} className={className}>
      <path
        d="M4.66 1.95A2.67 2.67 0 0 0 2 4.62v6.67c0 1.47 1.2 2.66 2.67 2.66h6.67c1.47 0 2.66-1.2 2.66-2.66V4.62c0-1.47-1.19-2.67-2.66-2.67H4.66Zm0 1.34h6.67c.73 0 1.33.6 1.33 1.33v6.67c0 .73-.6 1.33-1.33 1.33H4.66c-.74 0-1.33-.6-1.33-1.33V4.62c0-.74.6-1.33 1.33-1.33Zm3.3 1.33A2 2 0 0 0 6 6.1c-.09.36.13.74.48.83.36.1.72-.12.82-.48.07-.29.34-.5.64-.5.37 0 .67.3.67.67 0 .32-.18.65-.52 1l-.31.27-.25.2a.69.69 0 0 0-.09.97c.24.28.66.3.94.06l.27-.2.38-.36c.57-.58.92-1.2.91-1.94a2 2 0 0 0-2-2Zm0 5.33a.67.67 0 1 0 0 1.34.67.67 0 0 0 0-1.34Z"
        fill="currentColor"
      />
    </InfoIconSvg>
  )
}

export const InfoIconSvg = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
