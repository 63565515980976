import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Colors, Fonts, Gradients } from '../../styles'

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  outline = 'outline',
  negative = 'negative',
  positive = 'positive',
  tertiary = 'tertiary',
}

export enum LandingButtonSize {
  xs,
  s,
  default,
  m,
  l,
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  onClick?: () => void
  variant?: ButtonVariants
  size?: LandingButtonSize
}

export const Button = ({
  children,
  type,
  variant = ButtonVariants.primary,
  size = LandingButtonSize.default,
  ...other
}: Props) => {
  return (
    <StyledButton variant={variant} size={size} {...other}>
      <p>{children}</p>
      <Overlay />
    </StyledButton>
  )
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, rgba(21, 21, 21, 0.82) 0%, rgba(0, 0, 0, 0.369) 100%);
`

const NegativeButtonCss = css`
  cursor: not-allowed;
  background: ${Colors.Negative[400]};
  color: ${Colors.Negative[700]};
  &:hover {
    opacity: 0.8;
  }
`
const PositiveButtonCss = css`
  cursor: not-allowed;
  background: ${Colors.Positive[400]};
  color: ${Colors.Positive[600]};
  &:hover {
    opacity: 0.8;
  }
`

const OutlineButtonCss = css`
  background: transparent;
  transition: 1s;
  box-shadow: unset;

  p {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  &:hover {
    background: transparent;
    opacity: 0.7;
  }
`

const SecondaryButtonCss = css`
  background: ${Colors.Neutral[0]};
  transition: 1s;

  p {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-weight: 600;
  }

  &:hover {
    box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;

    ${Overlay} {
      display: block;
    }

    p {
      -webkit-background-clip: auto;
      color: ${Colors.Neutral[0]};
      background: none;
      -webkit-text-fill-color: initial;
    }
  }
`

const TertiaryButtonCss = css`
  background: ${Colors.Neutral[0]};
  transition: 1s;

  p {
    background: ${Gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-weight: 600;
  }

  &:hover {
    box-shadow: 0 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;
    background: transparent;
    opacity: 0.7;
  }
`

const PrimaryButtonCss = css`
  &:hover {
    background: linear-gradient(0deg, rgba(222, 222, 222, 0.72), rgba(222, 222, 222, 0.72)),
      radial-gradient(
          110.03% 307.14% at 87.5% -121.43%,
          #9985ff 0%,
          rgba(255, 103, 249, 0.88) 53.15%,
          rgba(255, 168, 87, 0.88) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #f2d2fe, 0px 7px 40px #ffe0d3;
    background-blend-mode: overlay, normal;
  }

  &:active {
    background: linear-gradient(0deg, rgba(166, 166, 166, 0.72), rgba(166, 166, 166, 0.72)),
      radial-gradient(
        110.03% 307.14% at 87.5% -121.43%,
        #9985ff 0%,
        rgba(255, 103, 249, 0.88) 53.15%,
        rgba(255, 168, 87, 0.88) 100%
      );
    background-blend-mode: overlay, normal;
  }
`

export const StyledButtonCss = css<{ variant: ButtonVariants; size: LandingButtonSize }>`
  all: unset;
  background: ${Gradients.soft};
  border-radius: 12px;
  min-height: 20px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  width: fit-content;
  font-family: ${Fonts.secondary};
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;

  ${Overlay} {
    display: none;
  }

  & p {
    line-height: initial;
    margin: 0 auto;
    width: fit-content;
    position: relative;
    z-index: 5;
  }

  min-width: ${(props) => {
    switch (props.size) {
      case LandingButtonSize.xs:
      case LandingButtonSize.s:
        return 'auto'
      default:
        return '184px'
    }
  }};

  padding: ${(props) => {
    switch (props.size) {
      case LandingButtonSize.xs:
        return '10px 22px'
      case LandingButtonSize.s:
        return '18px 24px'
      default:
        return '16px 24px 16px 24px'
    }
  }};

  ${(props) => {
    switch (props.variant) {
      case ButtonVariants.tertiary:
        return [TertiaryButtonCss]
      case ButtonVariants.positive:
        return [PositiveButtonCss]
      case ButtonVariants.negative:
        return [NegativeButtonCss]
      case ButtonVariants.outline:
        return [OutlineButtonCss]
      case ButtonVariants.secondary:
        return [SecondaryButtonCss]
      default:
        return [PrimaryButtonCss]
    }
  }};
`

const StyledButton = styled.button<{ variant: ButtonVariants; size: LandingButtonSize }>`
  ${StyledButtonCss};
`
