import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Colors, Shadows } from '../../styles'
import { ChartSvg } from '../icons/ChartIcon'
import { UpIcon } from '../icons/UpIcon'
import Img from 'next/image'
interface Investor {
  avatar: string
}

interface Strategy {
  children?: ReactNode
  id: number
  avatar: string
  title: string
  currency: string
  investors: {
    count: number
    data: Investor[]
  }
  tvl: string
  pctChange: string
}
interface Props {
  data: Strategy
}

export const StrategyCard = ({ data: { id, title, currency, investors, tvl, pctChange, avatar } }: Props) => {
  return (
    <Container>
      <div className="StrategyCard_col StrategyCard_firstCol">
        <div className="StrategyCard_id paragraph-md">{id}</div>
        <div className="StrategyCard_titleContainer">
          <Avatar>
            <Img width={48} height={48} src={avatar} alt="Avatar" layout="fixed" />
          </Avatar>
          <div className="StrategyCard_value">
            <h5>{title}</h5>
            <p className="StrategyCard_value paragraph-md">{currency}</p>
          </div>
        </div>
      </div>
      <div>
        <p className="paragraph-sm">Investors</p>
        <div className="flex_row flex_center">
          <div className="StrategyCard_value StrategyCard_investorsWrapper">
            {investors.data.map((inv, i) => {
              return (
                <ImageContainer key={`${i}_${id}`}>
                  <Img src={inv.avatar} alt="Avatar" layout="fill" />
                </ImageContainer>
              )
            })}
          </div>
          <h5 className="StrategyCard_investorsCount">+{investors.count}</h5>
        </div>
      </div>
      <div>
        <p className="paragraph-sm">TVL</p>
        <div className="StrategyCard_value StrategyCard_tvl">{tvl}</div>
      </div>
      <div>
        <p className="paragraph-sm">24H Change</p>
        <div className="StrategyCard_value StrategyCard_pctChange">
          {pctChange} <UpIcon />
        </div>
      </div>
      <div className="StrategyCard_col">
        <ChartSvg />
      </div>
    </Container>
  )
}

const Avatar = styled.div`
  margin: 0 10px 0 0;
  filter: drop-shadow(3.35704px 6.71408px 7.38548px rgba(66, 57, 104, 0.1));
`

const ImageContainer = styled.div``

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  background-color: ${Colors.Neutral[0]};
  justify-content: space-between;
  padding: 20px 24px;
  box-shadow: ${Shadows.medium};
  border-radius: 16px;
  cursor: pointer;

  & .paragraph-md,
  .paragraph-sm,
  .StrategyCard_investorsCount {
    color: ${Colors.Neutral[300]};
  }

  & .StrategyCard {
    &_col {
      display: flex;
      align-items: center;
    }

    &_firstCol {
      width: 27%;
    }

    &_pctChange {
      color: ${Colors.Positive[900]};
      font-weight: 700;
      display: flex;
      align-items: center;

      & svg {
        margin: 0 0 0 8px;
      }
    }

    &_tvl {
      color: ${Colors.Neutral[300]};
      font-weight: 600;
    }

    &_value {
      margin-top: 4px;
    }

    &_id {
      font-weight: 700;
      width: 20%;
    }

    &_titleContainer {
      min-width: 120px;
      display: flex;
      justify-content: space-between;

      & h5 {
        color: ${Colors.Neutral[400]};
      }
    }

    &_investorsWrapper {
      display: flex;
      margin: 0 8px 0 0;
      position: relative;
      min-width: 48px;
      height: 24px;

      & ${ImageContainer} {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
      }

      & ${ImageContainer}:nth-child(1) {
        position: absolute;
        left: 0;
      }
      & ${ImageContainer}:nth-child(2) {
        position: absolute;
        left: 12px;
      }
      & ${ImageContainer}:nth-child(3) {
        position: absolute;
        left: 24px;
      }
    }
  }
`
