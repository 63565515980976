import { useEffect, useState } from 'react'
import { truncateDecimals } from '../misc/utils'

const NUMBER_REGEX = /^\d*(\.\d*)?$/

export function useNumberInput(decimals = 6, initialState = '') {
  const [value, setValue] = useState(initialState)

  function set(value: string) {
    if (NUMBER_REGEX.test(value)) {
      setValue(truncateDecimals(stripLeadingZeroes(value), decimals))
    }
  }

  useEffect(() => {
    setValue(truncateDecimals(value, decimals))
  }, [decimals, value])

  return [value, set] as const
}

function stripLeadingZeroes(value: string) {
  if (value === '') {
    return value
  }
  const leadingZeroes = value.match(/^0*/)?.[0].length
  if (leadingZeroes) {
    value = value.substring(leadingZeroes)
  }
  if (value.startsWith('.') || value === '') {
    value = '0' + value
  }
  return value
}
