import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const Gtm = () => {
  useEffect(
    () =>
      TagManager.initialize({
        gtmId: 'GTM-K8V5GRT',
      }),
    []
  )
  return <></>
}
export default Gtm
