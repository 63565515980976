import React from 'react'
import styled from 'styled-components'

import { ContentContainer, Question } from '../base'
import { Colors, Device } from '../../../newDesign/styles'

const DATA = [
  {
    title: 'What is Enso?',
    answer: `Enso is the investment app where friends invest and build strategies for each other. Watch your friends trades, review past performance and grow with your investment community.`,
  },
  {
    title: 'What is a strategy?',
    answer: `A strategy is an investment strategy, so anything that makes money for you and your friends. This could be a bundle of governance, staking, leverage, utility, and yield farming tokens. With Enso you can use tokens from a yield farm in another yield farm by concurrently stepping returned tokens resulting in more potential yield.`,
  },
  {
    title: 'How Enso works?',
    answer: `Envision an aggregator of aggregators, bringing multiple DeFi apps into one unified user interface allowing you to connect with the DeFi realm in one place and monetise your investment strategies. Built on Ethereum Smart Contracts, Enso is a fully non-custodial social trading application.`,
  },
  {
    title: 'How can I earn with Enso?',
    answer: `Create a strategy, share with your friends, and generate a percentage of their profit by having good performance.`,
  },
  {
    title: 'What chains does Enso support?',
    answer: `Enso is built on Ethereum, and may enter the multi-chain world in the future.`,
  },
]

export const FAQ = () => {
  return (
    <ContentContainer>
      <Content>
        <Title>Frequently asked questions</Title>
        {DATA.map((question, i) => (
          <Question key={i} title={question.title} answer={question.answer} />
        ))}
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  padding: 0 0 120px 0;
  width: 100%;
`

const Title = styled.div`
  font-weight: 800;
  font-size: 81px;
  line-height: 80px;
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 80px;
  color: ${Colors.Primary[800]};

  @media ${Device.mobile} {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 48px;
    font-weight: 800;
  }
`
