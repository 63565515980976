import React, { ChangeEventHandler, ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { InfoIcon } from '../icons/InfoIcon'
import { BorderRad, Colors, Fonts, Transitions } from '../../constants'
import { GlobalTooltip, TooltipHolder, TooltipTitle } from './GlobalTooltip'
import { Exclamation } from '../icons/Exclamation'

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  popupTitle?: string
  popupText?: string
  subscription?: string
  placeholder?: string
  type?: string
  disabled?: boolean
  value?: any
  readOnly?: boolean
  tabIndex?: number
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  children?: React.ReactNode
  bold?: boolean
  units?: string
  align?: 'left' | 'right' | 'center'
  backgroundColor?: string
  transparent?: boolean
  fontSize?: number
  height?: number
  maxLength?: number
  prefix?: string
  error?: boolean
}

export const Input = forwardRef(function Input(
  {
    popupTitle,
    popupText,
    title,
    subscription,
    placeholder,
    type,
    disabled,
    value,
    onChange,
    readOnly,
    tabIndex,
    className,
    children,
    bold,
    units,
    align,
    backgroundColor,
    transparent,
    fontSize,
    maxLength,
    height,
    prefix,
    error,
    ...other
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <InputContainer subscription={subscription} className={className}>
      {popupTitle && (
        <TooltipHolder>
          <TooltipTitle>{popupTitle}</TooltipTitle>
          <GlobalTooltip tooltipTrigger={<Exclamation />} tooltipToShow={popupText} />
        </TooltipHolder>
      )}
      {!popupTitle && title && <InputTitle>{title}</InputTitle>}
      <InputFrame
        disabled={disabled || readOnly}
        transparent={transparent}
        backgroundColor={backgroundColor}
        fontSize={fontSize}
        error={error}
      >
        {prefix && <InputPrefix disabled={disabled || readOnly}>{prefix}</InputPrefix>}
        <InputStyles
          placeholder={placeholder}
          hasPrefix={!!prefix}
          type={type}
          disabled={disabled || readOnly}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          tabIndex={tabIndex}
          bold={bold}
          align={align ?? 'left'}
          ref={ref}
          maxLength={maxLength}
          height={height}
          onFocus={(e) => {
            if (other.onFocus) {
              other.onFocus(e)
            } else {
              e.target.select()
            }
          }}
          {...other}
        />
        {units && (
          <FakeInputContainer align={align} fontSize={fontSize} bold={bold}>
            {prefix && <FakePrefix disabled={disabled || readOnly}>{prefix}</FakePrefix>}
            <FakeInputValue align={align} hasPrefix={!!prefix}>
              {value || placeholder}
            </FakeInputValue>
            <InputUnits disabled={disabled || readOnly} bold={bold} align={align} color={'inherit'}>
              {units}
            </InputUnits>
          </FakeInputContainer>
        )}
        {children}
      </InputFrame>
      {subscription && (
        <Subscription>
          <InfoIcon />
          {subscription}
        </Subscription>
      )}
    </InputContainer>
  )
})

interface InputTextStylesProps {
  bold?: boolean
  align?: 'left' | 'right' | 'center'
  hasPrefix?: boolean
  fontSize?: number
  height?: number
}

export const InputFrame = styled.div<{
  disabled?: boolean
  transparent?: boolean
  backgroundColor?: string
  fontSize?: number
  error?: boolean
}>`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: ${BorderRad.m};
  background-color: ${({ disabled, transparent, backgroundColor }) =>
    disabled && !transparent ? Colors.Disabled : transparent ? 'transparent' : backgroundColor ?? Colors.White};
  overflow: hidden;
  transition: ${Transitions.all};
  font-family: ${Fonts.Inter};
  font-size: ${({ fontSize }) => (fontSize ? fontSize + 'px' : '14px')};

  &:focus-within,
  &:hover {
    outline: none;
    border-color: ${({ disabled, error }) =>
      disabled ? Colors.Border : error ? Colors.Negative[900] : Colors.Primary[500]};
  }
`

export const InputStyles = styled.input<InputTextStylesProps>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: ${({ height }) => (height ?? 40) + 'px'};
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${({ align }) => (align === 'right' ? '26px' : '16px')};
  padding-left: ${({ hasPrefix }) => (hasPrefix ? '0' : '16px')};
  font-family: ${Fonts.Inter};
  line-height: 24px;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  color: ${Colors.Neutral[900]};
  text-align: ${({ align }) => align};
  font-feature-settings: 'tnum';
  resize: none;
  outline: none;
  transition: ${Transitions.all};
  font-size: inherit;
  background-color: inherit;
  ${({ align }) => {
    return (
      align === 'center' &&
      css`
        transform: translateX(-0.5ch);
      `
    )
  }}

  &::placeholder {
    font-weight: 400;
    color: ${Colors.Neutral[600]};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    color: ${Colors.Neutral[600]};
    cursor: not-allowed;
  }
  &:hover&:disabled {
    border-color: ${Colors.Neutral[200]};
  }
`

export const FakeInputContainer = styled.div<InputTextStylesProps>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: ${({ align }) => {
    switch (align) {
      case 'center':
        return 'center'
      case 'right':
        return 'flex-end'
      case 'left':
      default:
        return 'flex-start'
    }
  }};
  height: 40px;
  width: 100%;
  ${({ align }) => {
    switch (align) {
      case 'center':
        return css`
          right: 50%;
          transform: translateX(50%);
        `
      case 'right':
        return css`
          right: 0;
        `
      default:
        return css`
          left: 0;
        `
    }
  }};
  margin-top: 1px;
  padding: 0;
  font-family: ${Fonts.Inter};
  line-height: 24px;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  user-select: none;
  pointer-events: none;
`

const FakeInputValue = styled.div<InputTextStylesProps>`
  width: fit-content;
  color: transparent;
  // height: 0;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${({ hasPrefix }) => (hasPrefix ? '0' : '16px')};
  white-space: nowrap;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  font-feature-settings: 'tnum';
`

const InputUnits = styled.span<InputTextStylesProps & { disabled?: boolean; align?: string }>`
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  padding-right: ${({ align }) => (align === 'right' ? '14px' : '0px')};
  color: ${({ disabled }) => (disabled ? Colors.Neutral[700] : 'inherit')};
  transition: ${Transitions.all};
`

const WithSubscription = css`
  ${InputStyles} {
    border-color: ${Colors.Negative[900]};
  }
`

export const InputTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.Neutral[700]};
  margin-bottom: 4px;
  user-select: none;
`

export const InputContainer = styled.div<InputProps>`
  display: grid;
  position: relative;
  height: fit-content;
  align-items: center;
  ${({ subscription }) => subscription !== undefined && WithSubscription}

  ${TooltipHolder} {
    margin-bottom: 4px;
  }

  ${TooltipTitle} {
    font-size: 12px;
    line-height: 24px;
    color: ${Colors.Neutral[700]};
    user-select: none;
  }
`

export const InputPrefix = styled.div<{ disabled?: boolean; align?: string }>`
  line-height: 20px;
  font-family: inherit;
  font-size: inherit;
  padding: 0 4px;
  width: fit-content;
  padding-right: ${({ align }) => (align === 'right' ? '26px' : '4px')};
  color: ${({ disabled }) => (disabled ? Colors.Neutral[700] : 'inherit')};
`

const FakePrefix = styled(InputPrefix)`
  visibility: hidden;
`
export const Subscription = styled.span`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  align-items: center;
  width: fit-content;
  margin-top: 4px;
  font-size: 10px;
  line-height: 14px;
  font-family: ${Fonts.Inter};
  font-weight: 400;
  color: ${Colors.Negative[900]};
`
