import { Button, ButtonProps, ButtonVariant } from './Button'
import { useEthers } from '@usedapp/core'
import { useAuthentication } from '../../providers/authentication/context'
import React, { memo, useState } from 'react'
import { NoAccountConnectButton } from '../page/Navbar'
import { SelectWalletModal } from '../top/SelectWalletModal'
import { CheckIcon } from '../icons/CheckIcon'

export const ConnectionButton = memo(({ children, ...rest }: ButtonProps) => {
  const [isSelectWalletActive, setSelectWalletActive] = useState(false)
  const { account } = useEthers()
  const [auth, _, status] = useAuthentication()
  const isLoggedIn = !!account && !!auth

  return (
    <>
      {!isLoggedIn ? (
        <NoAccountConnectButton {...rest} onClick={() => setSelectWalletActive(true)} loading={status === 'LOADING'}>
          Connect
        </NoAccountConnectButton>
      ) : (
        <Button {...rest}>{children ?? <CheckIcon inherit />}</Button>
      )}
      <SelectWalletModal open={isSelectWalletActive} onClose={() => setSelectWalletActive(false)} />
    </>
  )
})
