import React from 'react'
import styled from 'styled-components'
import { Alert } from '../icons/Alert'
import { SuccessIcon } from '../icons/SuccessIcon'
import { Colors } from '../../constants'

export enum AlertType {
  Success = 'Success',
  Error = 'Error',
}

interface AlertNotificationProps {
  text: string | undefined
  type?: AlertType
}

export function AlertNotification({ text, type }: AlertNotificationProps) {
  return (
    <>
      {text && (
        <AlertContainer type={type}>
          {type === AlertType.Success ? <SuccessIcon /> : <Alert />}
          <AlertText>{text}</AlertText>
        </AlertContainer>
      )}
    </>
  )
}

export const AlertContainer = styled.div<{ className?: any; type?: AlertType }>`
  display: inline-flex;
  align-items: center;
  color: ${({ type }) => (type === AlertType.Success ? Colors.Positive[900] : Colors.Negative[900])};
`

const AlertText = styled.span`
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
`
