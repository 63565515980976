import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { AuthenticationContext } from './context'
import { Authentication } from '../../model/Authentication'
import { useChainId } from '../../hooks/useChainId'
import { useEthers } from '@usedapp/core'
import { fetchJwtWithNonce, fetchNonce, useNonce } from '../../infrastructure/fetchAuthDetails'
import { useRequestAuth } from '../requestAuth/context'
import { useStatus } from '../../hooks/useStatus'
import { parseErrorMessage } from '../../misc/utils'
import { useToastMessage } from '../toasts/context'
import { usePrevious } from '../../misc/hooks/usePrevious'

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const { setError, setLoading, setSuccess, status } = useStatus()
  const tm = useToastMessage()

  const [auth, setAuth] = useState<Authentication | null>(null)
  const [shouldRequestSignature, setShouldRequestSignature] = useRequestAuth()
  const { account, library } = useEthers()
  const { chainId } = useChainId()
  const nonce = useNonce()

  const errorCallback = useCallback(() => {
    setError('Could not retrieve web token')
    tm('Authentication failed', { type: 'error' })
  }, [setError, tm])

  useEffect(() => {
    ;(async () => {
      if (!auth) {
        if (!!account && !!chainId) {
          try {
            // detect if user has a jwt
            // if user has a jwt, return the Authentication object
            const jwtFromLS = window?.localStorage?.getItem('auth' + '/' + account)
            if (jwtFromLS) {
              setAuth(new Authentication(account, chainId, true, jwtFromLS))
              setSuccess()
            } else if (shouldRequestSignature) {
              setShouldRequestSignature(false)
              setLoading()
              const newNonce = nonce ?? (await fetchNonce(account))
              if (newNonce) {
                // request a signature
                const jwt: string | null = await fetchJwtWithNonce(newNonce, account, library, errorCallback)
                // if message is signed, return the jwt
                if (jwt) {
                  setAuth(new Authentication(account, chainId, true, jwt))
                  setSuccess()
                } else {
                  setError('Could not retrieve web token')
                }
              } else {
                setError('Could not get nonce')
              }
            }
          } catch (e: any) {
            const errorMessage = parseErrorMessage(e?.message ?? '')
            tm(errorMessage || 'Could not authenticate', { type: 'error' })
            setError(errorMessage ?? e)
          }
        }
      }
    })()
  }, [
    account,
    auth,
    chainId,
    errorCallback,
    library,
    nonce,
    setError,
    setLoading,
    setShouldRequestSignature,
    setSuccess,
    shouldRequestSignature,
    tm,
  ])
  const clearAuth = useCallback(() => {
    setAuth(null)
    window?.localStorage?.removeItem('auth' + '/' + account)
  }, [account, setAuth])
  const previousAccount = usePrevious(account, null)
  useEffect(() => {
    if (previousAccount && account && previousAccount !== account) {
      clearAuth()
    }
  }, [previousAccount, account, clearAuth])

  return <AuthenticationContext.Provider value={[auth, clearAuth, status]}>{children}</AuthenticationContext.Provider>
}
