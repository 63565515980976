import styled from 'styled-components'
import { BorderRad, Colors, Fonts, Shadows, Wrappers } from '../../constants'
import { BlockTitle } from './BlockTitle'

export const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-family: ${Fonts.DMSans};
  user-select: none;
`

export const SidePanelGroup = styled.div`
  display: grid;
  grid-row-gap: 16px;
  width: 100%;
  font-family: ${Fonts.DMSans};

  ${BlockTitle} {
    margin-bottom: 0;
  }
`

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${Wrappers.main};
  max-height: 100%;
  margin: 0 auto 32px;
  padding-left: 32px;
  padding-right: 32px;
`

export const RowGapBlock = styled.div<{ gap?: number }>`
  display: grid;
  position: relative;
  grid-row-gap: ${({ gap }) => (gap ? gap + 'px' : '24px')};
  width: 100%;
  height: fit-content;
`

export const CenteredRowGapBlock = styled(RowGapBlock)`
  justify-content: center;
  justify-items: center;
`

export const WhiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;
  border-radius: ${BorderRad.l};
  background-color: ${Colors.White};
  box-shadow: ${Shadows.defaultEnso};
`

export const ColumnGapBlock = styled.div<{ gap?: number }>`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-column-gap: ${({ gap }) => (gap ? gap + 'px' : '24px')};
  width: fit-content;
`
