import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

import { Colors, Device, Shadows } from '../../styles'

export enum SimpleCardSize {
  m,
  l,
}

interface Props {
  children: ReactNode
  onClick?: () => void
  title?: string
  subtitle?: string
  size?: SimpleCardSize
  className?: string
}

export const SimpleCardLayout = forwardRef(
  ({ children, title, subtitle, size, ...other }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Container size={size} ref={ref}>
        <h3>{title}</h3>
        <p className="paragraph-lg">{subtitle}</p>
        {children}
      </Container>
    )
  }
)

const Container = styled.div<{ size?: SimpleCardSize }>`
  padding: 32px 32px 0;
  box-shadow: ${Shadows.large};
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  background: ${Colors.Neutral[0]};

  min-height: ${(props) => {
    switch (props.size) {
      case SimpleCardSize.l:
        return '555px'
      default:
        return '480px'
    }
  }};

  @media ${Device.mobile} {
    min-height: ${(props) => {
      switch (props.size) {
        default:
          return '480px'
      }
    }};
  }

  & .paragraph-lg {
    margin-top: 16px;
  }
`
