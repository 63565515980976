import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { ToastContainer, toast, ToastOptions } from 'react-toastify'
import styled, { css } from 'styled-components'
import { getExplorerTransactionLink, useNotifications } from '@usedapp/core'
import { Colors, Fonts, Overflow, Transitions } from '../../constants'
import { WalletIcon } from '../../components/icons/WalletIcon'
import { ClockIcon } from '../../components/icons/ClockIcon'
import { AlertGhost } from '../../components/icons/AlertGhost'
import { SuccessGhost } from '../../components/icons/SuccessGhost'
import { Notification as NotificationModel } from '@usedapp/core/dist/esm/src/providers/notifications/model'
import { useChainId } from '../../hooks/useChainId'
import { OpenIcon } from '../../components/icons/OpenIcon'
import _deepClone from 'lodash/cloneDeep'
import { ToastMessageContext } from './context'
import { EnsoGradient } from '../../components/icons/EnsoLogoLink'

export const DEFAULT_TOAST_SETTINGS: ToastOptions = {
  position: 'bottom-left',
  autoClose: 3500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export function ToastsProvider() {
  const { notifications } = useNotifications()
  const reversedNotifications = [...notifications].reverse()

  const toastMessage = useCallback((node: ReactNode, options: ToastOptions) => {
    toast(node, { ..._deepClone(DEFAULT_TOAST_SETTINGS), ...options })
  }, [])

  return (
    <ToastMessageContext.Provider value={toastMessage}>
      <>
        <StyledToastContainer />
        {reversedNotifications.map((notification: NotificationModel, i) => (
          <ToastNotification notification={notification} toastMessage={toastMessage} key={`notification-${i}`} />
        ))}
      </>
    </ToastMessageContext.Provider>
  )
}

interface NotificationProps {
  notification: NotificationModel
  toastMessage: Function
}

enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  DEFAULT = 'default',
}
const StatusMapper: Record<NotificationModel['type'], ToastType> = {
  walletConnected: ToastType.DEFAULT,
  transactionStarted: ToastType.SUCCESS,
  transactionSucceed: ToastType.INFO,
  transactionFailed: ToastType.ERROR,
}

export function ToastNotification({ notification, toastMessage }: NotificationProps) {
  const [shown, setShown] = useState(false)
  const { removeNotification } = useNotifications()
  const { chainId } = useChainId()
  const transactionName = ('transactionName' in notification && notification.transactionName) || undefined
  const notificationTitle = formatNotificationTitle(notificationContent[notification.type].title, transactionName)

  const onCloseHandler = useCallback(
    () => removeNotification({ notificationId: notification.id, chainId }),
    [chainId, notification.id, removeNotification]
  )

  const settings: ToastOptions = useMemo(() => {
    const settings = _deepClone(DEFAULT_TOAST_SETTINGS)
    settings.onClose = onCloseHandler
    settings.type = StatusMapper[notification.type]

    switch (notification.type) {
      case 'transactionFailed':
        settings.icon = <AlertGhost />
        settings.autoClose = false
        break
      case 'transactionSucceed':
        settings.icon = <SuccessGhost />
        break
      case 'transactionStarted':
        settings.icon = <ClockIcon />
        break
      case 'walletConnected':
        settings.icon = <WalletIcon />
        break
      default:
        settings.icon = <OpenIcon />
        break
    }

    return settings
  }, [notification.type, onCloseHandler])

  useEffect(() => {
    if (chainId && notification && notificationTitle && removeNotification && !shown) {
      toastMessage(
        <NotificationContent>
          <MessageWrapper>
            <NotificationTitle title={notificationTitle}>{notificationTitle}</NotificationTitle>
            {notification.type !== 'walletConnected' && (
              <NotificationLink
                href={getExplorerTransactionLink(notification.transaction.hash, notification.transaction.chainId)}
                target="_blank"
              >
                View on Etherscan
                <OpenIcon />
              </NotificationLink>
            )}
          </MessageWrapper>
        </NotificationContent>,
        settings
      )
      setShown(true)
    }
  }, [chainId, notification, notificationTitle, removeNotification, settings, shown, toastMessage])

  return <></>
}
const MessageWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const NotificationTitle = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  color: ${Colors.Neutral[900]};
  ${Overflow.FullDots};
`

const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  font-family: ${Fonts.DMSans};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  color: ${Colors.Neutral[700]};
  justify-content: center;
  align-items: center;
`

const WalletNotificationIcon = styled(WalletIcon)`
  width: 24px;
  height: 24px;
  color: ${Colors.Primary[500]};
`
const PendingNotificationIcon = styled(ClockIcon)`
  width: 24px;
  height: 24px;
  color: ${Colors.Primary[500]};
`
const FailureNotificationIcon = styled(AlertGhost)`
  width: 24px;
  height: 24px;
  color: ${Colors.Negative[900]};
`
const SuccessNotificationIcon = styled(SuccessGhost)`
  width: 24px;
  height: 24px;
  color: ${Colors.Positive[900]};
`

const NotificationLink = styled.a`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.Primary[500]};
  transition: ${Transitions.all};

  &:hover {
    color: ${Colors.Primary[600]};
  }
`

type NotificationTypeContent = { [key in NotificationModel['type']]: { title: string; icon: ReactElement } }

const notificationContent: NotificationTypeContent = {
  walletConnected: {
    title: 'Wallet connected',
    icon: <WalletNotificationIcon />,
  },
  transactionStarted: {
    title: 'Transaction pending',
    icon: <PendingNotificationIcon />,
  },
  transactionSucceed: {
    title: 'Transaction confirmed',
    icon: <SuccessNotificationIcon />,
  },
  transactionFailed: {
    title: 'Transaction failed',
    icon: <FailureNotificationIcon />,
  },
}

export const formatNotificationTitle = (title: string, transactionName?: string) => {
  if (!transactionName) {
    return title
  }

  return `${transactionName} ${title.toLowerCase()}`
}
const FontStyles = css`
  font-family: ${Fonts.DMSans};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  color: ${Colors.Neutral[700]};
`
const StyledToastContainer = styled(ToastContainer)`
  ${FontStyles};
  width: 340px;
  .Toastify__toast {
    ${FontStyles};
    position: relative;
    .Toastify__toast-body {
      max-width: calc(100% - 14px);
      text-align: center;
      > div:last-child {
        width: 180px;
        clear: both;
      }
    }
    .Toastify__progress-bar-theme--light {
      background: ${EnsoGradient};
    }
  }
`
