import React from 'react'
import styled from 'styled-components'

export function TwitterSocialIcon({ className }: { className?: string }) {
  return (
    <SocialIcon viewBox="0 0 16 16" fill="none" color="#1DA1F2" className={className}>
      <g clipPath="url(#clip0_161_3718)">
        <path
          d="M15.6373 2.96199C15.386 3.07347 15.1275 3.16756 14.8635 3.24395C15.1761 2.8904 15.4144 2.4744 15.5599 2.01918C15.5926 1.91714 15.5588 1.80545 15.4749 1.73871C15.3911 1.67192 15.2747 1.66392 15.1825 1.71858C14.6217 2.05118 14.0167 2.2902 13.3823 2.42996C12.7433 1.80555 11.8754 1.4502 10.9782 1.4502C9.08422 1.4502 7.54337 2.99099 7.54337 4.88488C7.54337 5.03404 7.55282 5.18237 7.5715 5.32865C5.22131 5.1223 3.03638 3.96715 1.53599 2.12669C1.48252 2.06109 1.40015 2.02573 1.31581 2.03249C1.23142 2.0391 1.15561 2.08669 1.11297 2.15982C0.808663 2.68199 0.647786 3.2792 0.647786 3.88684C0.647786 4.71445 0.94327 5.49969 1.46523 6.11327C1.30652 6.0583 1.15251 5.9896 1.00551 5.908C0.926599 5.86408 0.830289 5.86475 0.751889 5.9097C0.673437 5.95466 0.62425 6.03734 0.622186 6.12772C0.621824 6.14294 0.621824 6.15817 0.621824 6.1736C0.621824 7.40895 1.2867 8.52116 2.30322 9.12735C2.21589 9.11863 2.12861 9.10599 2.0419 9.08942C1.95251 9.07233 1.86059 9.10366 1.8003 9.17184C1.73991 9.23997 1.71994 9.33494 1.74776 9.42165C2.12402 10.5964 3.09274 11.4604 4.26384 11.7238C3.29253 12.3322 2.18177 12.6509 1.01573 12.6509C0.772431 12.6509 0.527734 12.6366 0.28825 12.6082C0.169282 12.5941 0.0555276 12.6643 0.0150115 12.7775C-0.0255047 12.8907 0.0174373 13.0168 0.11865 13.0817C1.61661 14.0422 3.34869 14.5499 5.12753 14.5499C8.62451 14.5499 10.8121 12.9008 12.0315 11.5174C13.552 9.79249 14.424 7.50929 14.424 5.25334C14.424 5.1591 14.4226 5.06392 14.4197 4.96906C15.0196 4.51708 15.5361 3.97009 15.9564 3.34139C16.0202 3.24591 16.0133 3.11972 15.9393 3.03182C15.8655 2.94387 15.7424 2.91543 15.6373 2.96199Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_161_3718">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SocialIcon>
  )
}

export const SocialIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
