import React from 'react'
import styled, { css } from 'styled-components'
import { BorderRad } from '../../../constants'
import { assetHTTPUrl } from '../../../misc/helpers/address'
import { TokenIconFallback } from '../../base/TokenIconFallback'
import { Token } from '../../../model'
import { GlobalTooltip } from '../../base/GlobalTooltip'

export interface TokenLogoProps {
  symbol: string
  logoURI: any
  size?: number
  marginless?: boolean
  title?: string
  className?: any
  baseUnderlying?: Token[]
  noAlt?: boolean
}

export function TokenLogoIcon({
  symbol,
  logoURI,
  size = 24,
  marginless,
  title,
  className,
  baseUnderlying,
  noAlt,
}: TokenLogoProps) {
  const isNotUrl = logoURI?.src

  return (
    <GlobalTooltip
      tooltipTrigger={
        <TokenLogo
          src={isNotUrl ? logoURI : assetHTTPUrl(logoURI)}
          alt={noAlt ? undefined : `${symbol} logo`}
          className={className}
          title={title}
          size={size}
          marginless={marginless}
          symbol={symbol ?? ''}
        />
      }
      tooltipToShow={baseUnderlying?.length ? baseUnderlying.map(({ symbol }) => symbol).join('+') : title ?? symbol}
    />
  )
}

export const TokenLogo = styled(TokenIconFallback)<Pick<TokenLogoProps, 'size' | 'marginless'>>`
  width: ${({ size }) => size + 'px'};
  min-width: ${({ size }) => size + 'px'};
  max-width: ${({ size }) => size + 'px'};
  min-height: ${({ size }) => size + 'px'};
  max-height: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  ${({ marginless }) =>
    !marginless &&
    css`
      margin-right: 8px;
    `};
  border-radius: ${BorderRad.m};
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`
