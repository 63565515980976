import React from 'react'
import styled from 'styled-components'

export function ClockIcon({ className }: { className?: string }) {
  return (
    <ClockIconStyle viewBox="0 0 24 24" fill="none" color="currentColor" className={className}>
      <path
        d="M11 2.5C9.02219 2.5 7.08879 3.08649 5.4443 4.1853C3.79981 5.28412 2.51809 6.8459 1.76121 8.67317C1.00433 10.5004 0.806299 12.5111 1.19215 14.4509C1.578 16.3907 2.53041 18.1725 3.92894 19.5711C5.32746 20.9696 7.10929 21.922 9.0491 22.3079C10.9889 22.6937 12.9996 22.4957 14.8268 21.7388C16.6541 20.9819 18.2159 19.7002 19.3147 18.0557C20.4135 16.4112 21 14.4778 21 12.5C21 11.1868 20.7413 9.88642 20.2388 8.67317C19.7363 7.45991 18.9997 6.35752 18.0711 5.42893C17.1425 4.50035 16.0401 3.76375 14.8268 3.2612C13.6136 2.75866 12.3132 2.5 11 2.5ZM11 20.5C9.41775 20.5 7.87104 20.0308 6.55544 19.1518C5.23985 18.2727 4.21447 17.0233 3.60897 15.5615C3.00347 14.0997 2.84504 12.4911 3.15372 10.9393C3.4624 9.38743 4.22433 7.96197 5.34315 6.84315C6.46197 5.72433 7.88743 4.9624 9.43928 4.65372C10.9911 4.34504 12.5997 4.50346 14.0615 5.10896C15.5233 5.71447 16.7727 6.73984 17.6518 8.05544C18.5308 9.37103 19 10.9177 19 12.5C19 14.6217 18.1572 16.6566 16.6569 18.1569C15.1566 19.6571 13.1217 20.5 11 20.5ZM14.1 13.13L12 11.92V7.5C12 7.23478 11.8946 6.98043 11.7071 6.79289C11.5196 6.60536 11.2652 6.5 11 6.5C10.7348 6.5 10.4804 6.60536 10.2929 6.79289C10.1054 6.98043 10 7.23478 10 7.5V12.5C10 12.5 10 12.58 10 12.62C10.0059 12.6889 10.0228 12.7564 10.05 12.82C10.0706 12.8793 10.0974 12.9363 10.13 12.99C10.1574 13.0468 10.1909 13.1005 10.23 13.15L10.39 13.28L10.48 13.37L13.08 14.87C13.2324 14.9564 13.4048 15.0012 13.58 15C13.8014 15.0015 14.0171 14.9296 14.1932 14.7953C14.3693 14.6611 14.4959 14.4722 14.5531 14.2583C14.6103 14.0444 14.5948 13.8176 14.5092 13.6134C14.4236 13.4092 14.2726 13.2392 14.08 13.13H14.1Z"
        fill="currentColor"
      />
    </ClockIconStyle>
  )
}

export const ClockIconStyle = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
