import { ChainId, SupportedChainId } from '../src/constants'
import { config as mainnetNewConfig } from './config.mainnetNew'
import { config as mainnetOldConfig } from './config.mainnetOld'
import { PlatformVersion } from '../src/hooks/usePlatformVersion'

export type NetworkConfig = ExternalNetworkConfig & EnsoNetworkConfig

export interface ExternalNetworkConfig {
  multicallAddress: string
  wethAddress: string
  uniswapFactoryAddress: string
  uniswapV2SubgraphUrl: string
  uniswapV3SubgraphUrl: string
}

export interface EnsoNetworkConfig {
  sushiSwapAdapter: string
  kyberSwapAdapter: string
  curveDepositZapRegistry: string
  fullRouter: string
  defaultEstimator: string
  uniswapV3Registry: string
  strategyEstimator: string
  tokenRegistry: string
  oracleAddress: string
  whitelistAddress: string
  uniswapV2Adapter: string
  uniswapV3Adapter: string
  aaveLendAdapter: string
  curveAdapter: string
  compoundAdapter: string
  curveLPAdapter: string
  curveRewardsAdapter: string
  strategyControllerAddress: string
  loopRouterAddress: string
  strategyProxyFactoryAddress: string
  metastrategyAdapterAddress: string
  batchDepositRouterAddress: string
  subgraphUrl: string
  ensoTournament: string
  yearnV2Adapter: string
  synthetixAdapterAddress: string
}

export function getChainConfig(chainId: SupportedChainId): NetworkConfig {
  switch (chainId) {
    case ChainId.Mainnet:
    default:
      return mainnetNewConfig
  }
}
