import { useEthers } from '@usedapp/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Colors, Transitions } from '../../constants/styles'
import { getExplorerAddressLink } from '@usedapp/core'
import { DefaultButton } from '../base/Button'
import { CopyIcon, CopyIconStyle } from '../icons/CopyIcon'
import { OpenIcon, OpenIconStyle } from '../icons/OpenIcon'
import { useAuthentication } from '../../providers/authentication/context'

interface AccountMenuProps extends CollapsedMenuProps {
  address: string
}

interface CollapsedMenuProps {
  isCollapsed?: boolean
}

export function AccountMenu({ address, isCollapsed }: AccountMenuProps) {
  const { deactivate } = useEthers()
  const { account, chainId } = useEthers()
  const [, clear] = useAuthentication()
  const disconnect = useCallback(() => {
    deactivate()
    clear()
  }, [clear, deactivate])

  return (
    <DropDownMenu isCollapsed={isCollapsed}>
      <DropDownItem>
        <Copy onClick={() => navigator.clipboard.writeText(address)}>
          <CopyIcon />
          Copy address
        </Copy>
      </DropDownItem>
      <DropDownItem>
        <Open target="_blank" href={getExplorerAddressLink(account || '', chainId || 0)}>
          <OpenIcon />
          Open in Etherscan
        </Open>
      </DropDownItem>
      <Separator />
      <DropDownItem>
        <Disconnect onClick={disconnect}>Disconnect</Disconnect>
      </DropDownItem>
    </DropDownMenu>
  )
}

const DropDownMenu = styled.ul<CollapsedMenuProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 223px;
  list-style: none;
  bottom: -4px;
  left: ${({ isCollapsed }) => (isCollapsed ? '0' : 'unset')};
  right: ${({ isCollapsed }) => (isCollapsed ? 'unset' : '0')};
  margin: 0;
  padding: 16px;
  border: 1px solid ${Colors.Neutral[200]};
  border-radius: 4px;
  background: ${Colors.White};
  transform: translateY(calc(100% - 0px));
  transition: ${Transitions.all};
  cursor: auto;
  z-index: 20;
  animation: 0.25s showMenu ease;

  @keyframes showMenu {
    from {
      transform: translateY(calc(100% - 4px));
      opacity: 0;
    }
  }
`

const DropDownItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Neutral[900]};
  text-align: right;

  & + & {
    margin-top: 16px;
  }

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: ${Colors.Primary[300]};
    fill: ${Colors.Primary[300]};
  }
`

const Copy = styled(DefaultButton)`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Neutral[900]};

  ${CopyIconStyle} {
    color: ${Colors.Primary[300]};
    transition: ${Transitions.all};
  }

  &:hover {
    ${CopyIconStyle} {
      color: inherit;
    }
  }
`

export const Open = styled.a<{ className?: string }>`
  display: inline-flex;
  align-items: center;
  color: ${Colors.Neutral[900]};
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: ${Transitions.all};

  &:visited {
    color: ${Colors.Neutral[900]};
  }

  ${OpenIconStyle} {
    color: ${Colors.Primary[300]};
    transition: ${Transitions.all};
  }

  &:hover {
    color: ${Colors.Primary[500]};

    ${OpenIconStyle} {
      color: inherit;
    }
  }
`

const Disconnect = styled(DefaultButton)`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Negative[900]};
`

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  border: none;
  background-color: ${Colors.Neutral[200]};
`
