import React from 'react'
import { Colors, Device, Fonts } from '../../newDesign/styles'
import styled, { css } from 'styled-components'

export type DeviceType = keyof typeof Device
interface HideOnDeviceProps {
  on: DeviceType
  disableHide?: boolean
  children: React.ReactNode
}

export const HideOnDevice = ({ on, disableHide, children }: HideOnDeviceProps) => {
  return (
    <DeviceHide deviceType={on} disabled={!!disableHide}>
      {children}
    </DeviceHide>
  )
}

const DeviceHide = styled.div<{ deviceType: DeviceType; disabled: boolean }>`
  ${({ disabled, deviceType }) =>
    !disabled &&
    css`
      @media ${Device[deviceType]} {
        display: none !important;
      }
    `}
`
