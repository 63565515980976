export const logoRewrite = (s: string) => {
  // const test = URL_MAPPER.reduce((acc, [stringIn, stringOut]) => acc.replace(stringIn, stringOut), s)
  // console.log('test', test)
  return s
}

const URL_MAPPER = [
  ['https://assets.coingecko.com', '/_next/image?url=' + encodeURIComponent('https://assets.coingecko.com')],
] as const

export const logoRewriteForColorThief = (s: string) => {
  return s.includes(URL_MAPPER[0][0]) ? s.replace(URL_MAPPER[0][0], URL_MAPPER[0][1]) + '&w=48&q=75' : s
}
