import { shortenIfAddress } from '../misc/helpers/address'

export class InvalidAddress extends Error {
  constructor(readonly address: string, message: string) {
    super(message)
    Object.setPrototypeOf(this, InvalidAddress.prototype)
  }

  toString() {
    return `${shortenIfAddress(this.address)} is not a token`
  }
}

export class InvalidToken extends InvalidAddress {
  constructor(address: string) {
    super(address, `${address} is not a proper ERC20 token`)
    Object.setPrototypeOf(this, InvalidToken.prototype)
  }
}

export class TokenHasNoPairOnUniswap extends Error {
  constructor(address: string) {
    super(`Token with address ${address} has no pair on Uniswap`)
  }
}

export class MetamaskSignatureDenied extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, MetamaskSignatureDenied.prototype)
  }
}

export class TransactionFailed extends Error {
  constructor(readonly transactionHash: string, readonly transactionName?: string) {
    super(
      transactionName
        ? `${transactionName} transaction has failed (${transactionHash})`
        : `Transaction has failed (${transactionHash})`
    )
    Object.setPrototypeOf(this, TransactionFailed.prototype)
  }
}

export class TransactionFailedWithReason extends Error {
  constructor(readonly transactionHash: string, readonly failureReason: string, readonly transactionName?: string) {
    super(
      transactionName
        ? `${transactionName} transaction has failed with reason: ${failureReason}, hash: ${transactionHash}`
        : `Transaction has failed with reason: ${failureReason}, hash: ${transactionHash}`
    )
    Object.setPrototypeOf(this, TransactionFailed.prototype)
  }
}

export class UnknownProtocol extends Error {
  constructor(protocol: string) {
    super(`Protocol '${protocol}' is not supported`)
    Object.setPrototypeOf(this, InvalidToken.prototype)
  }
}
