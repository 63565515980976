export const Colors = {
  Primary: {
    800: '#1A183F',
    200: '#F9F9FF',
    100: '#EFF0F8',
  },
  Neutral: {
    500: '#46445F',
    400: '#474A76',
    300: '#979EB8',
    200: '#A4A8CA',
    150: '#EFEFF3',
    100: '#64748B',
    0: '#FDFCFF',
  },
  Negative: {
    800: '#FF017B',
    700: '#E9506B',
    400: '#FFEAEE',
    300: '#FFE9F1',
  },
  Positive: {
    900: '#00B04C',
    600: '#62CE52',
    400: '#D4F8E0',
    300: '#DBFED5',
  },
  Accent: {
    900: '#2F79E8',
    400: '#E4F2FF',
    100: '#F8FCFF',
  },
}

export const Gradients = {
  primary:
    'radial-gradient(102.07% 286.25% at 20.45% -21.25%, #9985FF 0%, rgba(255, 103, 249, 0.88) 53.15%, rgba(255, 168, 87, 0.88) 91.13%)',
  soft: 'linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)), radial-gradient(102.07% 286.25% at 20.45% -21.25%, #9985FF 0%, rgba(255, 103, 249, 0.88) 53.15%, rgba(255, 168, 87, 0.88) 91.13%)',
}

export const Fonts = {
  primary: '"Inter", sans-serif',
  secondary: '"Lexend Deca", sans-serif',
}

export const Shadows = {
  large: '10px 12px 141px rgba(126, 129, 164, 0.2)',
  medium: '0px 12px 24px rgba(0, 0, 0, 0.06)',
  small: '0px 4px 72px rgba(126, 129, 164, 0.06)',
  extraSmall: '0px 1px 4px rgba(66, 57, 104, 0.08)',
  special: '0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #F2D2FE, 0px 7px 40px #FFE0D3',
}

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '520px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1120px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const Device = {
  mobile: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  mobileL: `(max-width: ${size.mobileL})`,
}

export const Transitions = {
  default: '.5s',
}
