import { UnsupportedChainIdError } from '@web3-react/core'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { AlertNotification } from './components/base/AlertNotification'
import { chainIdFromError, Colors, getUnsupportedNetworkMessage, SUPPORTED_CHAINS } from './constants'
import { useEthers } from '@usedapp/core'

interface Props {
  children: ReactNode
}

export function SupportedChains({ children }: Props) {
  const { error } = useEthers()

  if (error instanceof UnsupportedChainIdError) {
    const chainId = chainIdFromError(error.message)
    const message = getUnsupportedNetworkMessage(SUPPORTED_CHAINS, chainId)
    return (
      <InfoContainer>
        <Info>
          <AlertNotification text={message} />
        </Info>
      </InfoContainer>
    )
  }
  return <>{children}</>
}

const Info = styled.div`
  padding: 1em 1.5em;
  border-radius: 0.75em;
  background-color: ${Colors.White};
`

const InfoContainer = styled.section`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
