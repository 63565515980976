import { getChainConfig } from '../../config/getChainConfig'
import { SupportedChainId } from '../constants'
import { ChainId } from '../constants/chainId'
import { getDefaultChainId, useChainId } from './useChainId'
import { useMemo } from 'react'
import { normalizedConfig } from '../../config/misc'

export function useConfig() {
  return useMemo(() => normalizedConfig(getChainConfig(ChainId.Mainnet)), [])
}

export function getDefaultConfig() {
  const { chainId } = getDefaultChainId()
  return getChainConfig(chainId as SupportedChainId)
}
