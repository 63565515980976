import React, { useCallback, useEffect, useRef, useState } from 'react'
import UnknownTokenLogo from '../../components/icons/unknown-token.png'
import { assetHTTPUrl } from '../../misc/helpers/address'
import Image from 'next/image'
import styled from 'styled-components'
import { useImageSourceFromSymbol } from '../../hooks/useImageSourceFromSymbol'
import _isNumber from 'lodash/isNumber'

interface TokenIconFallbackProps {
  src: any
  alt?: string
  className?: string
  name?: string
  title?: string
  symbol?: string
  size?: number
}

export const FallbackImageFromSymbol = ({ symbol = '', name }: { symbol?: string; name?: string }) => {
  const source = useImageSourceFromSymbol(symbol)
  return source ? (
    <Image
      src={assetHTTPUrl(source)}
      placeholder="empty"
      width={1}
      height={1}
      alt={symbol}
      layout="responsive"
      sizes="4vw"
    />
  ) : (
    <Image
      src={UnknownTokenLogo}
      placeholder="empty"
      width={1}
      height={1}
      alt="Unknown token logo"
      layout="responsive"
      sizes="4vw"
    />
  )
}

const isImageValid = (src: string) => {
  return new Promise((resolve) => {
    let img = document.createElement('img')
    img.onerror = () => resolve(false)
    img.onload = () => resolve(true)
    img.src = src
  })
}

export const TokenIconFallback = ({ src, alt, className, title, symbol, size }: TokenIconFallbackProps) => {
  const [source, setSource] = useState(src)
  const [status, setStatus] = useState<'LOADING' | 'ERROR' | 'DONE'>('LOADING')
  const isLocalImage = !!src?.src
  const onErrorHandler = useCallback(() => setStatus('ERROR'), [])
  const onLoadHandler = useCallback((e) => setStatus('DONE'), [])
  const noError = status !== 'ERROR'

  useEffect(() => {
    ;(async () => {
      const valid = await isImageValid(src)
      if (!valid) {
        onErrorHandler()
      }
    })()
  }, [onErrorHandler, src])

  useEffect(() => {
    setSource(src)
  }, [src])

  return (
    <ImageContainer className={className} title={title}>
      {noError ? (
        isLocalImage ? (
          <Image
            src={source}
            alt="Unknown token logo"
            layout="responsive"
            sizes="4vw"
            placeholder="empty"
            width={1}
            height={1}
            onError={onErrorHandler}
            onLoadingComplete={onLoadHandler}
            loading={'lazy'}
          />
        ) : src ? (
          <Image
            src={assetHTTPUrl(source)}
            placeholder="empty"
            width={1}
            height={1}
            alt={alt}
            layout="responsive"
            sizes="4vw"
            onError={onErrorHandler}
            loading={'lazy'}
            onLoadingComplete={onLoadHandler}
          />
        ) : null
      ) : (
        <FallbackImageFromSymbol symbol={symbol} />
      )}
    </ImageContainer>
  )
}

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;

  & > * {
    width: 100%;
  }
`
