import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { Authentication } from '../../model/Authentication'
import { DEFAULT_STATUS, Status } from '../../hooks/useStatus'

export const AuthenticationContext = createContext<[Authentication | null, () => void, Status]>([
  null,
  () => {},
  DEFAULT_STATUS.status,
])

export function useAuthentication() {
  return useContext(AuthenticationContext)
}
