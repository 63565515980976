import React, { HTMLAttributes } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { BorderRad, Colors, Fonts, Gradients, Transitions } from '../../constants'
import {
  BlackButtonCSS,
  ButtonIcon,
  ButtonInner,
  ButtonSize,
  ButtonVariant,
  DefaultButtonCSS,
  GhostButtonCSS,
  GlowPurpleButtonCSS,
  GradientBlueButtonCSS,
  GradientPurpleButtonCSS,
  IconOnly,
  LinkButtonCSS,
  NegativeButtonCSS,
  OutlineButtonCSS,
  PrimaryButtonCSS,
  RainbowGradientButtonCSS,
  SecondaryButtonCSS,
  WhiteButtonCSS,
  WhiteOutlinedButtonCSS,
} from './Button'
import { UrlObject } from 'url'

interface LinkButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  href: UrlObject | string
  children?: React.ReactNode
  variant?: ButtonVariant
  icon?: React.ReactNode
  iconRight?: boolean
  iconOnly?: boolean
  blank?: boolean
  size?: ButtonSize
  iconColor?: string
  className?: string
}

export const LinkButton = function LinkButton({
  children,
  variant,
  icon,
  iconRight,
  iconOnly,
  iconColor,
  href,
  blank,
  size,
  className,
  ...anchorProps
}: LinkButtonProps) {
  return (
    <Link href={href} passHref>
      <LinkButtonComponent
        target={blank ? '_blank' : ''}
        variant={variant}
        iconOnly={iconOnly}
        size={size}
        className={className}
        {...anchorProps}
      >
        <ButtonInner>
          {icon && (
            <ButtonIcon iconColor={iconColor} iconRight={iconRight}>
              {icon}
            </ButtonIcon>
          )}
          {!iconOnly && children}
        </ButtonInner>
      </LinkButtonComponent>
    </Link>
  )
}

export const LinkButtonComponent = styled.a<{ iconOnly?: boolean; variant?: ButtonVariant; size?: ButtonSize }>`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size === ButtonSize.full ? '100%' : 'fit-content')};
  min-width: ${(props) => {
    switch (props.size) {
      case ButtonSize.s:
        return '130px'
      case ButtonSize.m:
      default:
        return '140px'
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case ButtonSize.s:
        return '32px'
      case ButtonSize.l:
        return '48px'
      case ButtonSize.m:
      default:
        return '40px'
    }
  }};
  padding: ${(props) => {
    switch (props.size) {
      case ButtonSize.s:
        return '0px 24px'
      case ButtonSize.m:
      default:
        return '4px 24px'
    }
  }};
  font-family: ${Fonts.Inter};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: ${BorderRad.m};
  cursor: pointer;
  transition: ${Transitions.all};

  ${(props) => {
    switch (props.iconOnly) {
      case true:
        return IconOnly
    }
  }};
  ${(props) => {
    switch (props.variant) {
      case ButtonVariant.white:
        return WhiteButtonCSS
      case ButtonVariant.whiteOutlined:
        return WhiteOutlinedButtonCSS
      case ButtonVariant.secondary:
        return SecondaryButtonCSS
      case ButtonVariant.ghost:
        return GhostButtonCSS
      case ButtonVariant.gradientPurpleButton:
        return GradientPurpleButtonCSS
      case ButtonVariant.gradientBlueButton:
        return GradientBlueButtonCSS
      case ButtonVariant.outline:
        return OutlineButtonCSS
      case ButtonVariant.link:
        return LinkButtonCSS
      case ButtonVariant.negative:
        return NegativeButtonCSS
      case ButtonVariant.glowPurpleButton:
        return GlowPurpleButtonCSS
      case ButtonVariant.glowRainbowButton:
        return RainbowGradientButtonCSS
      case ButtonVariant.black:
        return BlackButtonCSS
      case ButtonVariant.primary:
      default:
        return PrimaryButtonCSS
    }
  }};
`

export const DefaultLinkButton = styled(Link)`
  ${DefaultButtonCSS}

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[500]};
    outline: none;
  }

  &:active {
    color: ${Colors.Primary[600]};
  }
`
