import React from 'react'
import styled from 'styled-components'

export function CopyIcon() {
  return (
    <CopyIconStyle viewBox="0 0 16 16" fill="none" color="currentColor">
      <path
        d="M10.6667 0.666504H2.66667C1.93 0.666504 1.33334 1.26317 1.33334 1.99984V11.3332H2.66667V1.99984H10.6667V0.666504ZM12.6667 3.33317H5.33334C4.59667 3.33317 4 3.92984 4 4.6665V13.9998C4 14.7365 4.59667 15.3332 5.33334 15.3332H12.6667C13.4033 15.3332 14 14.7365 14 13.9998V4.6665C14 3.92984 13.4033 3.33317 12.6667 3.33317ZM12.6667 13.9998H5.33334V4.6665H12.6667V13.9998Z"
        fill="currentColor"
      />
    </CopyIconStyle>
  )
}

export const CopyIconStyle = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
