import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { BorderRad, Colors, Fonts, Gradients, Shadows, Transitions } from '../../constants'
import { Spinner } from '../icons/Spinner'
import { GlobalTooltip, TooltipHolder } from './GlobalTooltip'

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  whiteOutlined = 'whiteOutlined',
  ghost = 'ghost',
  link = 'link',
  negative = 'negative',
  negativeAccent = 'negativeAccent',
  warningAccent = 'warningAccent',
  gradientPurpleButton = 'gradientPurpleButton',
  gradientBlueButton = 'gradientBlueButton',
  glowPurpleButton = 'glowPurpleButton',
  glowRainbowButton = 'glowRainbowButton',
  outline = 'outline',
  outlineNoBorder = 'outlineNoBorder',
  black = 'black',
  grey = 'grey',
}

export enum ButtonSize {
  xs,
  s,
  m,
  l,
  full,
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean | false
  children?: React.ReactNode
  variant?: ButtonVariant
  loadingChildren?: React.ReactNode
  icon?: React.ReactNode
  iconRight?: boolean
  iconOnly?: boolean
  size?: ButtonSize
  className?: string
  iconColor?: string
  tabIndex?: number
  spinnerSize?: number
  loading?: boolean
  tooltip?: React.ReactNode
}

export const Button = forwardRef(function Button(
  {
    children,
    variant,
    icon,
    iconRight,
    iconOnly,
    iconColor,
    disabled,
    onClick,
    tooltip,
    size,
    className,
    tabIndex,
    loading,
    loadingChildren,
    spinnerSize,
    ...rest
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const component = (
    <ButtonComponent
      variant={variant}
      iconOnly={iconOnly}
      disabled={disabled || loading}
      onClick={onClick}
      size={size}
      className={className}
      tabIndex={tabIndex}
      ref={ref}
      {...rest}
    >
      <ButtonInner>
        {icon && !loading && (
          <ButtonIcon iconColor={iconColor} iconRight={iconRight}>
            {icon} {loadingChildren}
          </ButtonIcon>
        )}
        {!iconOnly && !loading && children}
        {loading && <Spinner size={spinnerSize ?? 32} />}
      </ButtonInner>
    </ButtonComponent>
  )

  if (tooltip) {
    return <GlobalTooltip tooltipToShow={tooltip} tooltipTrigger={component} />
  }

  return component
})

export const IconOnly = css<{ size?: ButtonSize }>`
  padding: 8px;
  width: ${(props) => {
    switch (props.size) {
      case ButtonSize.xs:
        return '16px'
      case ButtonSize.s:
        return '32px'
      case ButtonSize.l:
        return '48px'
      case ButtonSize.m:
      default:
        return '40px'
    }
  }};
  min-width: 0;
`

const IconRight = css`
  order: 2;
`

export const ButtonIcon = styled.span<{ iconRight?: boolean; iconColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  ${(props) => props.iconRight === true && IconRight};
  color: ${({ iconColor }) => (iconColor ? iconColor : 'inherit')};
  transition: ${({ iconColor }) => (iconColor ? Transitions.all : 'none')};
`

export const PrimaryButtonCSS = css`
  color: ${Colors.White};
  border-color: ${Colors.Primary[500]};
  background-color: ${Colors.Primary[500]};

  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${Colors.Primary[400]};
    border-color: ${Colors.Primary[400]};
    color: ${Colors.White};
  }
  &:active {
    background-color: ${Colors.Primary[500]};
    border-color: ${Colors.Primary[500]};
    color: ${Colors.White};
  }
`

const PrimaryButtonDisabled = css`
  &:disabled,
  &.disabled {
    background-color: ${Colors.Primary[200]};
    border-color: ${Colors.Primary[200]};
    color: ${Colors.White};
    cursor: not-allowed;
  }
`

export const SecondaryButtonCSS = css`
  color: ${Colors.Neutral[900]};
  border-color: ${Colors.Neutral[200]};
  background-color: transparent;

  ${ButtonIcon} {
    color: ${Colors.Primary[500]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: transparent;
    border-color: ${Colors.Primary[500]};
    color: ${Colors.Neutral[900]};
  }
  &:active {
    background-color: transparent;
    border-color: ${Colors.Primary[600]};
    color: ${Colors.Neutral[900]};

    ${ButtonIcon} {
      color: ${Colors.Primary[600]};
    }
  }
`

const SecondaryButtonDisabled = css`
  &:disabled,
  &.disabled {
    background-color: transparent;
    border-color: ${Colors.Neutral[200]};
    color: ${Colors.Neutral[200]};
    cursor: not-allowed;

    ${ButtonIcon} {
      color: ${Colors.Neutral[200]};
    }
  }
`

export const NegativeButtonCSS = css`
  color: ${Colors.White};
  border-color: ${Colors.Negative[900]};
  background-color: ${Colors.Negative[900]};

  ${ButtonIcon} {
    color: ${Colors.White};
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.White};
    border-color: ${Colors.Negative[900]};
    background-color: ${Colors.Negative[900] + '80'};
  }
  &:active {
    color: ${Colors.White};
    border-color: ${Colors.Negative[900]};
    background-color: ${Colors.Negative[900] + 'CC'};
  }
`

const NegativeButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Negative[900]};
    border-color: ${Colors.Negative[900]};
    background-color: ${Colors.Negative[900] + '80'};
    opacity: 0.5;

    ${ButtonIcon} {
      color: ${Colors.Negative[900]};
    }
  }
`

export const NegativeAccentButtonCSS = css`
  color: ${Colors.Negative[900]};
  border-color: transparent;
  background-color: ${Colors.Negative[400]};
  opacity: 1;

  ${ButtonIcon} {
    color: ${Colors.Negative[900]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Negative[900]};
    background-color: ${Colors.Negative[400] + '80'};
  }
  &:active {
    color: ${Colors.Negative[900]};
    background-color: ${Colors.Negative[400] + 'CC'};
  }
`
export const NegativeAccentButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Negative[900]};
    border-color: transparent;
    background-color: ${Colors.Negative[400]};
    opacity: 0.5;
    ${ButtonIcon} {
      color: ${Colors.Negative[900]};
    }
  }
`

export const WarningAccentButtonCSS = css`
  color: ${Colors.Warning[900]};
  border-color: transparent;
  background-color: ${Colors.Warning[400]};
  opacity: 1;

  ${ButtonIcon} {
    color: ${Colors.Warning[900]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Warning[900]};
    background-color: ${Colors.Warning[400] + '80'};
  }
  &:active {
    color: ${Colors.Warning[900]};
    background-color: ${Colors.Warning[400] + 'CC'};
  }
`
export const WarningAccentButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Warning[900]};
    border-color: transparent;
    background-color: ${Colors.Warning[400]};
    opacity: 0.5;
    ${ButtonIcon} {
      color: ${Colors.Warning[900]};
    }
  }
`

export const WhiteOutlinedButtonCSS = css`
  color: ${Colors.Neutral[900]};
  border: 1px solid ${Colors.ButtonBorder};
  background-color: ${Colors.White};
  box-shadow: ${Shadows.defaultEnso};

  ${ButtonIcon} {
    color: ${Colors.Primary[500]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${Colors.Neutral[50]};
    border-color: transparent;
    color: ${Colors.Primary[500]};
    box-shadow: ${Shadows.hoverEnso};
  }
  &:active {
    background-color: ${Colors.White};
    border-color: transparent;
    color: ${Colors.Primary[600]};
    box-shadow: ${Shadows.activeEnso};

    ${ButtonIcon} {
      color: ${Colors.Primary[600]};
    }
  }
`

const WhiteOutlinedButtonDisabled = css`
  &:disabled,
  &.disabled {
    background-color: ${Colors.Neutral[100]};
    border-color: transparent;
    color: ${Colors.Neutral[500]};
    box-shadow: ${Shadows.transparent};

    ${ButtonIcon} {
      color: ${Colors.Neutral[500]};
    }
  }
`
export const WhiteButtonCSS = css`
  color: ${Colors.Neutral[700]};
  border-color: transparent;
  background-color: ${Colors.White};
  box-shadow: ${Shadows.defaultEnso};

  ${ButtonIcon} {
    color: ${Colors.Primary[500]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${Colors.Neutral[50]};
    border-color: transparent;
    color: ${Colors.Primary[500]};
    box-shadow: ${Shadows.hoverEnso};
  }
  &:active {
    background-color: ${Colors.White};
    border-color: transparent;
    color: ${Colors.Primary[600]};
    box-shadow: ${Shadows.activeEnso};

    ${ButtonIcon} {
      color: ${Colors.Primary[600]};
    }
  }
`

const WhiteButtonDisabled = css`
  &:disabled,
  &.disabled {
    background-color: ${Colors.Neutral[100]};
    border-color: transparent;
    color: ${Colors.Neutral[500]};
    box-shadow: ${Shadows.transparent};

    ${ButtonIcon} {
      color: ${Colors.Neutral[500]};
    }
  }
`

export const GhostButtonCSS = css`
  color: ${Colors.Neutral[900]};
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &.disabled {
    background-color: transparent;
    border-color: transparent;
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[400]};
  }
  &:active {
    color: ${Colors.Primary[600]};
  }
`

const GhostButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Neutral[400]};
  }
`

export const LinkButtonCSS = css`
  color: ${Colors.Primary[500]};
  background-color: transparent;

  text-decoration: underline;
  min-width: 0;

  &:hover ${ButtonIcon} {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &.disabled {
    background-color: transparent;
    border-color: transparent;
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[400]};
  }
  &:active {
    color: ${Colors.Primary[600]};
  }
`

const LinkButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Neutral[400]};
  }
`

export const GradientPurpleButtonCSS = css`
  position: relative;
  border: 0 solid transparent;
  color: ${Colors.White};
  background-color: transparent;
  background-image: linear-gradient(295deg, #7918f2 -25%, #4801ff 115%);
  box-shadow: ${Shadows.transparent};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: ${Gradients.purple};
    background-size: 150% 100%;
    background-position: 25% 50%;
    background-repeat: no-repeat;
    transition: ${Transitions.all};
    border-radius: inherit;
  }

  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: ${Shadows.hoverEnso};
    &:before {
      background-position: 75% 50%;
    }
  }
  &:active {
    box-shadow: ${Shadows.activeEnso};

    &:before {
      background-position: 100% 50%;
    }
  }
`

const GradientPurpleButtonDisabled = css`
  &:disabled,
  &.disabled {
    opacity: 0.5;
    box-shadow: ${Shadows.transparent};

    &:before {
      background-position: 25% 50%;
    }
  }
`

export const GradientBlueButtonCSS = css`
  position: relative;
  border: 0 solid transparent;
  color: ${Colors.White};
  background-color: transparent;
  background-image: linear-gradient(295deg, #0acffe -25%, #495aff 115%);
  box-shadow: ${Shadows.transparent};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(295deg, transparent -25%, #4365ff 50%, transparent 115%);
    background-size: 150% 100%;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    transition: ${Transitions.all};
  }

  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: ${Shadows.hoverEnso};
    &:before {
      background-position: 50% 50%;
    }
  }
  &:active {
    box-shadow: ${Shadows.activeEnso};

    &:before {
      background-position: 25% 50%;
    }
  }
`

const GradientBlueButtonDisabled = css`
  &:disabled,
  &.disabled {
    opacity: 0.5;
    box-shadow: ${Shadows.transparent};

    &:before {
      background-position: 25% 50%;
    }
  }
`

export const OutlineButtonCSS = css`
  color: ${Colors.Primary[500]};
  border-color: ${Colors.Primary[500]};
  background-color: ${Colors.Primary[100]};

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[500]};
    border-color: ${Colors.Primary[500]};
    background-color: ${Colors.Neutral[50]};
  }
  &:active {
    color: ${Colors.Primary[500]};
    border-color: ${Colors.Primary[500]};
    background-color: ${Colors.Primary[100]};
  }
`

export const OutlineNoBorderButtonCSS = css`
  color: ${Colors.Primary[500]};
  border: none;
  background-color: ${Colors.Primary[100]};

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[500]};
    border: none;
    background-color: ${Colors.Neutral[50]};
  }
  &:active {
    color: ${Colors.Primary[500]};
    border: none;
    background-color: ${Colors.Primary[100]};
  }
`

export const GreyButtonCSS = css`
  color: ${Colors.Neutral[700]};
  border-color: ${Colors.BorderCollapsible};
  background-color: ${Colors.Neutral[100]};

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Neutral[700]};
    border-color: ${Colors.BorderCollapsible};
    background-color: ${Colors.Neutral[50]};
  }
  &:active {
    color: ${Colors.Neutral[700]};
    border-color: ${Colors.BorderCollapsible};
    background-color: ${Colors.Neutral[100]};
  }
`

const OutlineButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Primary[500]};
    border-color: ${Colors.Primary[500]};
    background-color: ${Colors.Primary[100]};
    opacity: 0.5;
  }
`
const GreyButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Neutral[700]};
    border-color: ${Colors.BorderCollapsible};
    background-color: ${Colors.Neutral[100]};
    opacity: 0.5;
  }
`

const OutlineNoBorderButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Primary[500]};
    border: none;
    background-color: ${Colors.Primary[100]};
    opacity: 0.5;
  }
`
export const BlackButtonCSS = css`
  color: ${Colors.White};
  border-color: ${Colors.Neutral[900]};
  background-color: ${Colors.Neutral[900]};

  ${ButtonIcon} {
    color: ${Colors.White};
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${Colors.White};
    border-color: ${Colors.Neutral[900]};
    color: ${Colors.Neutral[900]};
  }
  &:active {
    background-color: transparent;
    border-color: ${Colors.Primary[600]};
    color: ${Colors.Primary[600]};

    ${ButtonIcon} {
      color: ${Colors.Primary[600]};
    }
  }
`

const BlackButtonDisabled = css`
  &:disabled,
  &.disabled {
    background-color: ${Colors.Neutral[700]};
    border-color: ${Colors.Neutral[700]};
    color: ${Colors.White};
    cursor: not-allowed;

    ${ButtonIcon} {
      color: ${Colors.White};
    }
  }
`

export const RainbowGradientButtonCSS = css`
  overflow: visible;
  position: relative;
  background: white;
  border: 1px solid ${Colors.ButtonBorder};

  &:after {
    content: '';
    top: 50%;
    left: 50%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(-50%, -50%);
    background-image: ${Gradients.rainbow};
    border-radius: ${BorderRad.m};
    transition: ${Transitions.all};
    filter: blur(2px);

    position: absolute;
    z-index: -1;
  }

  &:hover,
  &:focus,
  &:focus-within {
    &:after {
      filter: blur(4px);
    }
  }
`

export const GlowPurpleButtonCSS = css`
  ${GradientPurpleButtonCSS};
  overflow: visible;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate(-50%, -50%);
    background-image: ${Gradients.purple};
    border-radius: ${BorderRad.m};
    filter: blur(2px);
    transition: ${Transitions.allM};
    z-index: -1;
  }

  &:hover,
  &:focus,
  &:focus-within {
    &:after {
      transform: translate(-50%, -50%) scale(0.5, 0.5);
    }
  }
`

const GlowPurpleButtonDisabled = css`
  &:disabled,
  &.disabled {
    opacity: 0.5;
    box-shadow: ${Shadows.transparent};

    &:before {
      background-position: 25% 50%;
    }
  }
`

export const ButtonInner = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
  color: inherit;
  z-index: 2;
  user-select: none;
`

export const ButtonComponent = styled.button<{ iconOnly?: boolean; variant?: ButtonVariant; size?: ButtonSize }>`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size === ButtonSize.full ? '100%' : 'fit-content')};
  min-width: ${(props) => {
    switch (props.size) {
      case ButtonSize.s:
        return '130px'
      case ButtonSize.m:
      default:
        return '140px'
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case ButtonSize.xs:
        return '32px'
      case ButtonSize.s:
        return '32px'
      case ButtonSize.l:
        return '48px'
      case ButtonSize.m:
      default:
        return '40px'
    }
  }};
  padding: ${(props) => {
    switch (props.size) {
      case ButtonSize.s:
        return '0px 24px'
      case ButtonSize.m:
      default:
        return '4px 24px'
    }
  }};
  font-family: ${Fonts.Inter};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: ${BorderRad.m};
  cursor: pointer;
  transition: ${Transitions.all};
  outline: none;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  ${({ iconOnly }) => {
    switch (iconOnly) {
      case true:
        return IconOnly
    }
  }};
  ${(props) => {
    switch (props.variant) {
      case ButtonVariant.white:
        return [WhiteButtonCSS, WhiteButtonDisabled]
      case ButtonVariant.whiteOutlined:
        return [WhiteOutlinedButtonCSS, WhiteOutlinedButtonDisabled]
      case ButtonVariant.secondary:
        return [SecondaryButtonCSS, SecondaryButtonDisabled]
      case ButtonVariant.ghost:
        return [GhostButtonCSS, GhostButtonDisabled]
      case ButtonVariant.gradientPurpleButton:
        return [GradientPurpleButtonCSS, GradientPurpleButtonDisabled]
      case ButtonVariant.gradientBlueButton:
        return [GradientBlueButtonCSS, GradientBlueButtonDisabled]
      case ButtonVariant.outline:
        return [OutlineButtonCSS, OutlineButtonDisabled]
      case ButtonVariant.outlineNoBorder:
        return [OutlineNoBorderButtonCSS, OutlineNoBorderButtonDisabled]
      case ButtonVariant.grey:
        return [GreyButtonCSS, GreyButtonDisabled]
      case ButtonVariant.link:
        return [LinkButtonCSS, LinkButtonDisabled]
      case ButtonVariant.negative:
        return [NegativeButtonCSS, NegativeButtonDisabled]
      case ButtonVariant.glowPurpleButton:
        return [GlowPurpleButtonCSS, GlowPurpleButtonDisabled]
      case ButtonVariant.black:
        return [BlackButtonCSS, BlackButtonDisabled]
      case ButtonVariant.negativeAccent:
        return [NegativeAccentButtonCSS, NegativeAccentButtonDisabled]
      case ButtonVariant.warningAccent:
        return [WarningAccentButtonCSS, WarningAccentButtonDisabled]
      case ButtonVariant.primary:
      default:
        return [PrimaryButtonCSS, PrimaryButtonDisabled]
    }
  }};
`

export const ButtonsGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  width: fit-content;
`

export const DefaultButtonCSS = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-family: ${Fonts.Inter};
  color: ${Colors.Neutral[700]};
  outline: none;
  transition: ${Transitions.all};
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus,
  &:focus-within {
    color: ${Colors.Primary[500]};
    outline: none;
  }

  &:active {
    color: ${Colors.Primary[600]};
  }
`

const DefaultButtonDisabled = css`
  &:disabled,
  &.disabled {
    color: ${Colors.Neutral[200]};
    cursor: not-allowed;
  }
`

export const DefaultButton = styled.button`
  ${DefaultButtonCSS};
  ${DefaultButtonDisabled};
`
