import React, { useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { SocialMedia } from '../base'
import { Search } from '../base/Search'
import { Button } from '../../../components/base/Button'
import { ELink, LandingLinkSize, LinkVariants, SLink } from '../base/StyledLink'
import { GlobalSearch } from '../../../components/GlobalSearch/GlobalSearch'
import { EnsoLogoLinkExpanded } from '../../../components/icons/EnsoLogoLink'

import { Colors, Device, Fonts, Transitions } from '../../styles'
import { HideOnDevice } from 'src/components/base/DeviceVisibility'
import { UserButtons } from 'src/components/page/Navbar'
import { PageLoadingBar } from '../../../components/base/PageLoadingBar'

interface NavbarProps {
  menuButtons: React.ReactNode
  socials: React.ReactNode
  userButtons: React.ReactNode
  isLandingPage?: boolean
}

export const NavbarContainers = ({ menuButtons, socials, userButtons, isLandingPage }: NavbarProps) => {
  const [isSearchOpen, setSearchOpen] = useState(false)
  return (
    <NavbarGrid>
      <NavbarGroup justifyContent="start">
        <EnsoLogoLinkExpandedStyle href="/" />
        <LinksWrapper>{menuButtons}</LinksWrapper>
      </NavbarGroup>
      <NavbarGroup hideOnMobile>
        <GlobalSearchWrapper>
          <GlobalSearch isOpen={isSearchOpen} setOpen={setSearchOpen} />
        </GlobalSearchWrapper>
      </NavbarGroup>
      <NavbarGroup justifyContent="end" fullWidthMobile>
        {socials}
        <HideOnDevice on="mobile" disableHide={isLandingPage}>
          {userButtons}
        </HideOnDevice>
      </NavbarGroup>
      <PageLoadingBar />
    </NavbarGrid>
  )
}

const ButtonsMenu = () => {
  return (
    <>
      <Link key="explore" href="/explore/strategies/1" passHref>
        <StyledLink>Explore</StyledLink>
      </Link>
      <Link key="create" href="/create" passHref>
        <StyledLink>Create</StyledLink>
      </Link>
      <Link key="early" href="/early-access" passHref>
        <StyledLink>Early Access</StyledLink>
      </Link>
    </>
  )
}

export const NavbarLandingPage = () => {
  return (
    <HeaderWrapper id="main">
      <NavbarContainers
        isLandingPage
        menuButtons={<ButtonsMenu />}
        socials={<SocialWrapper />}
        userButtons={<UserButtons />}
      />
    </HeaderWrapper>
  )
}

export const NavbarMainApp = () => {
  return (
    <HeaderWrapper>
      <NavbarContainers menuButtons={<ButtonsMenu />} socials={<SocialWrapper />} userButtons={<UserButtons />} />
    </HeaderWrapper>
  )
}

const NavbarGrid = styled.div<{ singleColumnMobile?: boolean }>`
  display: grid;
  height: 80px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;

  @media ${Device.laptopM} {
    grid-template-columns: 1fr 300px 1fr;
  }

  @media ${Device.mobile} {
    grid-template-columns: ${({ singleColumnMobile }) => (singleColumnMobile ? '1fr' : '1fr 1fr 1fr')};
  }
`

const NavbarGroup = styled.div<{ justifyContent?: string; hideOnMobile?: boolean; fullWidthMobile?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};

  ${({ fullWidthMobile }) =>
    fullWidthMobile &&
    css`
      @media ${Device.mobile} {
        min-width: 100%;
      }
    `}

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media ${Device.mobile} {
        > * {
          display: none !important;
        }
      }
    `}
`

const HeaderWrapper = styled.header`
  width: 100%;
  min-width: 375px;
  height: 80px;
  padding: 0 48px;
  z-index: 100;
  position: relative;

  & .enter-button {
    margin-left: 32px;
  }

  @media ${Device.mobile} {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 16px;
  }
`

const EnsoLogoLinkExpandedStyle = styled(EnsoLogoLinkExpanded)`
  height: 24px;
  width: 97px;
  min-width: 97px;
  margin-right: 32px;
  border-right: 1px solid #a4a8ca;
  flex-grow: 0;
`

const StyledLink = styled.a`
  font-family: ${Fonts.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: inherit;
  max-width: 100px;
  margin-right: 32px;
  white-space: nowrap;

  transition: ${Transitions.default};
  &:hover {
    color: ${Colors.Neutral[200]};
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 24px;
  border-left: 1px solid rgba(25, 10, 53, 0.15);
  align-items: center;
  width: 22%;
  justify-content: flex-start;
  color: inherit;
  padding-left: 32px;
  min-width: 300px;

  @media ${Device.laptop} {
    display: none;
  }
`

const GlobalSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 2;
`

const SocialWrapper = styled(SocialMedia)`
  flex-basis: 130px;
  margin-left: 30px;
  margin-right: 10px;
  min-width: 90px;
  align-items: center;

  svg {
    fill: ${Colors.Neutral[400]};
    color: ${Colors.Neutral[400]};
  }

  @media ${Device.laptop} {
    display: none;
  }
`
