import { ReactNode, useEffect, useState } from 'react'
import { ClaimableAmount, VampireContext } from './context'
import { BigNumber } from 'ethers'
import { TOKENS } from './data'
import { useVampireDetails } from './useVampireDetails'
import { useTokenDetails } from '../../hooks/useTokenDetails'
import { getDefaultChainId, useChainId } from 'src/hooks/useChainId'

const flattenedTokens = Object.values(TOKENS).reduce((acc, tokens) => {
  return [...acc, ...tokens]
}, [] as string[])

export function VampireProvider({ children }: { children: ReactNode }) {
  const chainId = useChainId().chainId
  const defaultChainId = getDefaultChainId().chainId
  const isOnCorrectNetwork = chainId === defaultChainId

  // don't search tokens if on different network
  const tokens = useTokenDetails(isOnCorrectNetwork ? flattenedTokens : [])
  const vampireDetails = useVampireDetails(flattenedTokens)
  const [claims, setClaims] = useState<ClaimableAmount[]>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (vampireDetails.length && tokens.length) {
      // @ts-ignore
      const claims: ClaimableAmount[] = vampireDetails.reduce((acc: ClaimableAmount[], amountInArr: BigNumber[], i) => {
        if (amountInArr) {
          const amount = amountInArr[0]
          if (amount && amount?.gt(0)) {
            return [...acc, { token: tokens[i], amount }] as ClaimableAmount[]
          }
        }
        return acc
      }, [] as ClaimableAmount[])
      setClaims(claims)
    }
  }, [vampireDetails, tokens])

  return <VampireContext.Provider value={{ claims, open, setOpen }}>{children}</VampireContext.Provider>
}
