import { useCallback, useEffect, useState } from 'react'
import { size } from '../newDesign/styles'

export const useIsMobile = () => {
  const [width, setWidth] = useState<number>(parseInt(size.laptop))

  const handleWindowSizeChange = useCallback(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }, [])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [handleWindowSizeChange])

  return width <= parseInt(size.tablet)
}
