import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../constants'

interface SpinnerProps {
  className?: string
  size?: number
  color?: string
}
export function Spinner({ className, color = 'white', size = 40 }: SpinnerProps) {
  return (
    <SpinnerSVGContainer className={className} size={size}>
      <SpinnerSVG viewBox="0 0 50 50" size={size}>
        <Circle stroke={color} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </SpinnerSVG>
    </SpinnerSVGContainer>
  )
}

export const SpinnerSVGContainer = styled.div<SpinnerProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size ?? 40}px;
  height: ${({ size }) => size ?? 40}px;
  flex-shrink: 0;
  flex-grow: 0;
`
const Circle = styled.circle`
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
`

export const SpinnerSVG = styled.svg<SpinnerProps>`
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -${({ size }) => (size ?? 40) / 2}px 0 0 -${({ size }) => (size ?? 40) / 2}px;
  width: ${({ size }) => size ?? 40}px;
  height: ${({ size }) => size ?? 40}px;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`
