import React from 'react'
import styled from 'styled-components'

export function DiscordSocialIcon({ className }: { className?: string }) {
  return (
    <SocialIcon viewBox="0 0 14 11" fill="#6A79FF" className={className}>
      <path d="M12.0909 1.75C12.0909 1.75 10.632 0.62875 8.90909 0.5L8.75382 0.805C10.3116 1.17937 11.0263 1.71594 11.7727 2.375C10.4857 1.72969 9.21486 1.125 7 1.125C4.78514 1.125 3.51432 1.72969 2.22727 2.375C2.97373 1.71594 3.82391 1.12031 5.24618 0.805L5.09091 0.5C3.28332 0.667812 1.90909 1.75 1.90909 1.75C1.90909 1.75 0.279682 4.07031 0 8.625C1.64245 10.4853 4.13636 10.5 4.13636 10.5L4.65786 9.81719C3.77268 9.515 2.77295 8.97531 1.90909 8C2.93936 8.76562 4.49432 9.5625 7 9.5625C9.50568 9.5625 11.0606 8.76562 12.0909 8C11.227 8.97531 10.2273 9.515 9.34214 9.81719L9.86364 10.5C9.86364 10.5 12.3575 10.4853 14 8.625C13.7203 4.07031 12.0909 1.75 12.0909 1.75ZM4.93182 7.375C4.31677 7.375 3.81818 6.81531 3.81818 6.125C3.81818 5.43469 4.31677 4.875 4.93182 4.875C5.54686 4.875 6.04545 5.43469 6.04545 6.125C6.04545 6.81531 5.54686 7.375 4.93182 7.375ZM9.06818 7.375C8.45314 7.375 7.95455 6.81531 7.95455 6.125C7.95455 5.43469 8.45314 4.875 9.06818 4.875C9.68323 4.875 10.1818 5.43469 10.1818 6.125C10.1818 6.81531 9.68323 7.375 9.06818 7.375Z" />
    </SocialIcon>
  )
}

export const SocialIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
