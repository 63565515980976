import _reduce from 'lodash/reduce'
import { normalizeString } from '../src/misc/utils'
import { NetworkConfig } from './getChainConfig'

export const ONE_HOUR = 60 * 60
export const ONE_DAY = ONE_HOUR * 24
export const normalizedConfig = (config: NetworkConfig): NetworkConfig =>
  _reduce(
    config,
    (acc, value, key) => ({
      ...acc,
      [key]: normalizeString(value),
    }),
    {} as NetworkConfig
  )
