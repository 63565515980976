import React from 'react'
import { AppProps } from 'next/app'
import { SupportedChains } from '../SupportedChains'
import { Providers } from '../providers'
import once from 'lodash/once'
import { ErrorBoundary } from '../components/ErrorBoundary'

try {
  const COMMIT_HASH =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA &&
    JSON.stringify(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA).trim().length
      ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      : 'local'

  if (typeof window !== 'undefined') {
    ;(window as any).COMMIT_HASH = COMMIT_HASH
    const logCommitHash = once(() => {
      console.log(`Commit-Ref for Application: ${COMMIT_HASH}`)
    })
    logCommitHash()
  }
} catch (e) {
  console.log(e)
}

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <Providers pageProps={pageProps}>
        <SupportedChains>
          <Component {...pageProps} />
        </SupportedChains>
      </Providers>
    </ErrorBoundary>
  )
}
