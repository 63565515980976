import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { fetch } from 'cross-fetch'
import { getDefaultConfig, useConfig } from '../../hooks/useConfig'
import { SubgraphClientName } from './SubgraphClientName'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { AppProps } from 'next/app'
import { useMemo } from 'react'
import { NetworkConfig } from '../../../config/getChainConfig'
import { setContext } from '@apollo/client/link/context'

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

export function createApolloClient(config: NetworkConfig) {
  const authLink = setContext((_, context) => {
    const { headers, auth } = context

    return {
      headers: {
        ...headers,
        Authorization: auth ? `Bearer ${auth}` : '',
      },
    }
  })

  // the nextjs rewrite on <page-url>/gql was done to avoid cors issues
  // but when doing serverside rendering we need the absolute url, so fallback
  // to <backend url>/gql as we won't have cors issues there.
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : process.env.NEXT_PUBLIC_BACKEND_URL

  const ensoBackendLink = new HttpLink({ uri: `${baseUrl}/gql`, fetch })
  const ensoBackendLinkWithAuth = authLink.concat(ensoBackendLink)
  const uniswapV2SubgraphLink = new HttpLink({ uri: config.uniswapV2SubgraphUrl, fetch })
  const uniswapV3SubgraphLink = new HttpLink({ uri: config.uniswapV3SubgraphUrl, fetch })
  const cache = new InMemoryCache()
  const link = ApolloLink.split(
    (operation) => operation.getContext().clientName === SubgraphClientName.Enso,
    ensoBackendLinkWithAuth,
    ApolloLink.split(
      (operation) => {
        return operation.getContext().clientName === SubgraphClientName.UniV2
      },
      uniswapV2SubgraphLink,
      uniswapV3SubgraphLink
    )
  )
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache,
  })
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  config: NetworkConfig = getDefaultConfig()
) {
  const _apolloClient = apolloClient ?? createApolloClient(config)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: AppProps['pageProps']) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: AppProps['pageProps']) {
  const config = useConfig()

  const state = pageProps[APOLLO_STATE_PROP_NAME]
  return useMemo(() => initializeApollo(state, config), [state, config])
}
