import React from 'react'
import styled from 'styled-components'

export function Close({ className }: { className?: string }) {
  return (
    <CloseIcon
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
      className={className}
    >
      <path d="M 2 2 L 14 14 M 14 2 L 2 14" fill="currentColor" color="currentColor" />
    </CloseIcon>
  )
}

export const CloseIcon = styled.svg`
  width: 14px;
  height: 14px;
  position: relative;
`
