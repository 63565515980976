import { BigNumber, utils } from 'ethers'
import { isAddress } from 'ethers/lib/utils'
import { shortenAddress } from '../utils'
import { uriToHttpUrl } from '../utils/uriToHttpUrl'

export function shortenIfAddress(address: string) {
  if (isAddress(address)) {
    return shortenAddress(address)
  }
  return address
}

export function addressEqual(address0: string, address1: string): boolean {
  return utils.getAddress(address0)?.toLowerCase() === utils.getAddress(address1)?.toLowerCase()
}

export function compareAddresses(address0: string, address1: string): number {
  const isGreater = BigNumber.from(address0).gte(BigNumber.from(address1))
  return isGreater ? 1 : -1
}

export function assetHTTPUrl(url?: string): string {
  if (url) {
    try {
      return uriToHttpUrl(url)
    } catch (e) {
      return ''
    }
  }
  return ''
}
