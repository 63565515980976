import { fetch } from 'cross-fetch'
import { BASE_URL, PRICE_API_ETH_ID } from './coinpapricaConfig'
import { EtherPriceModel } from './reducer'

export function getURLForPrice(baseURL: string, currencyId: string) {
  const parameters = `price-converter?base_currency_id=${currencyId}&quote_currency_id=usd-us-dollars&amount=1`
  return baseURL.endsWith('/') ? `${baseURL}${parameters}` : `${baseURL}/${parameters}`
}

export function fetchEtherPrice(fetchFunction: <T>(url: string) => Promise<T>) {
  return fetchFunction<EtherPriceModel>(getURLForPrice(BASE_URL, PRICE_API_ETH_ID))
}

export async function apiCall<T>(url: string): Promise<T> {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const body = await response.json()
  return body
}
