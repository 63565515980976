import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Overflow, Transitions } from '../../constants'
import Link from 'next/link'

interface Props {
  href: string
  className?: string
  smallMedia?: boolean
}

export const EnsoLogoLink = ({ className, href }: Props) => {
  return (
    <Link href={href} passHref>
      <EnsoLogoContainer className={className}>
        <EnsoLogoHiddenText>Enso - Main Page</EnsoLogoHiddenText>
      </EnsoLogoContainer>
    </Link>
  )
}

export const EnsoLogoLinkExpanded = ({ className, href, smallMedia }: Props) => {
  return (
    <Link href={href} passHref>
      <EnsoLogoContainerExpanded className={className} smallMedia={smallMedia}>
        <EnsoLogoHiddenText>Enso - Main Page</EnsoLogoHiddenText>
      </EnsoLogoContainerExpanded>
    </Link>
  )
}
export const EnsoGradient = css`linear-gradient(146.82deg, #6a79ff 5.41%, #c745c8 57.2%, #5f37dc 91.72%) !important`
export const EnsoLogoContainer = styled.a`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: ${Colors.Neutral[900]};
  mask-image: url('/images/EnsoLogo.svg');
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  transition: ${Transitions.all};
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(146.82deg, #6a79ff 5.41%, #c745c8 57.2%, #5f37dc 91.72%);
    opacity: 0;
    transition: ${Transitions.all};
  }

  &:hover:before,
  &:focus:before,
  &:focus-within:before {
    opacity: 1;
  }
`

export const EnsoLogoContainerExpanded = styled(EnsoLogoContainer)<{ smallMedia?: boolean }>`
  mask-image: url('/images/EnsoLogoExpanded.svg');
  width: ${({ smallMedia }) => (smallMedia ? '112px' : '146px')};
  height: ${({ smallMedia }) => (smallMedia ? '28px' : '40px')};

  &.navbar {
    height: 24px;
  }
`

const EnsoLogoHiddenText = styled.span`
  position: absolute;
  opacity: 0;
  max-width: 0;
  user-select: none;
  pointer-events: none;
  ${Overflow.Dots};
`
