import { InjectedConnector } from '@web3-react/injected-connector'
import { useEffect } from 'react'
import { getApplicationConfig } from '../../../config/getApplicationConfig'
import { SUPPORTED_CHAINS } from '../../constants'
import { useEthers } from '@usedapp/core'
import { NetworkConnector } from '../../infrastructure/connectors/NetworkConnector'

interface CreateProfilePayload {
  id: string
}

interface CreateProfileResponse {
  id: string
  name: string
}

const injected = new InjectedConnector({ supportedChainIds: SUPPORTED_CHAINS })

export function LegacyActivator() {
  const { activate, account } = useEthers()
  const config = getApplicationConfig()

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected)
      } else {
        activate(new NetworkConnector(config.network))
      }
    })
  }, [activate, config.network])

  return null
}
