import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'

import { TeamCardProps } from '../../../components/pages/index/TeamCard'
import { Colors, Device, Fonts, Gradients, Shadows, Transitions } from '../../../newDesign/styles'
import { Transition } from '@headlessui/react'
import { LinkedIcon } from '../icons/LinkedIcon'
import { TwitterIcon } from '../icons/TwitterIcon'

export const TeamMemberCard = (cardData: TeamCardProps) => {
  const { name, title, image, twitter, linkedin, position } = cardData

  return (
    <CardContainer>
      <ImageContainer>
        <Image className="avatar" src={image} alt={name} width={456} height={548} objectFit="cover" />
        <Overlay />
        <Accent />
      </ImageContainer>

      <Description>
        <About>
          <p className="name">{name}</p>
          <Role>{position}</Role>
        </About>
        <SocialsContainer>
          {linkedin && (
            <IconContainer>
              <a href={`https://www.linkedin.com/in/${linkedin}/`} target="_blank">
                <LinkedIcon />
              </a>
            </IconContainer>
          )}
          {twitter && (
            <IconContainer>
              <a href={`https://twitter.com/${twitter}`} target="_blank">
                <TwitterIcon />
              </a>
            </IconContainer>
          )}
        </SocialsContainer>
      </Description>
      <MemberTitle>
        <p className="quotes-container">
          <Image src="/images/landing/quotes.svg" alt="quotes" width={20} height={20} />
        </p>
        <Title>{title}</Title>
      </MemberTitle>
    </CardContainer>
  )
}

const Role = styled.p`
  font-weight: 400;
  margin: 4px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.Neutral[300]};
`

const IconContainer = styled.div`
  & svg {
    filter: brightness(50%) grayscale(1);
    transition: ${Transitions.default};
  }

  &:hover {
    & svg {
      filter: brightness(100%);
    }
  }
`

const Accent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url('/images/landing/backgrounds/accent.png');
  background-size: cover;
  opacity: 0;
  transition: ${Transitions.default};
  width: 100%;
  border-radius: 20px;
  height: 99%;
`

const CardContainer = styled.div`
  width: 456px;
  height: 570px;
  position: relative;
  border-radius: 24px;
  background: ${Colors.Neutral[0]};
  box-shadow: ${Shadows.medium};
  overflow: hidden;

  & .avatar {
    width: 100%;
    border-radius: 24px 24px 26px 26px;
  }

  & :nth-child(2n + 1) {
    margin: 20px 12px 20px 0;
  }

  & :nth-child(2n) {
    margin: 20px 0 20px 12px;
  }

  @media ${Device.mobile} {
    height: 470px;
    width: 341px;

    & .avatar {
      width: 342px !important;
    }

    & :nth-child(2n + 1),
    & :nth-child(2n) {
      margin: 16px 0;
    }
  }

  &:hover {
    ${Accent} {
      opacity: 1;
    }
  }
`

const Description = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
  border-radius: 0 0 24px 24px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const About = styled.div`
  & .name {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.03em;
  }
`

const SocialsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 96px;

  & a {
    width: 40px;
    height: 40px;
    background: ${Colors.Primary[200]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${IconContainer} {
    margin-left: 8px;
  }
`

export const CardSocial = styled.a`
  width: 16px;
  height: 16px;
  color: ${Colors.Neutral[0]};

  &:hover,
  &:focus {
    color: ${Colors.Primary[200]};
  }
`

const MemberTitle = styled.div`
  position: absolute;
  bottom: 120px;
  left: 24px;

  & .quotes-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #efe7ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.p`
  margin-top: 9px;
  width: 265px;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.04em;
  background: ${Gradients.soft};
  font-family: ${Fonts.secondary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-blend-mode: overlay, normal;
  mix-blend-mode: normal;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(8.02deg, rgba(0, 0, 0, 0.75) 11.38%, rgba(0, 0, 0, 0) 62.62%);
  width: 100%;
  border-radius: 20px;
  height: 99%;
`
