import React, { ReactNode, useEffect, useState } from 'react'
import { EstimatorContext } from './context'
import { useEthers } from '@usedapp/core'
import { useContracts } from '../../hooks/useContracts'
import { useConfig } from '../../hooks/useConfig'
import { Estimator } from '../../infrastructure/Estimator'
import { PlatformVersion, usePlatformVersion } from '../../hooks/usePlatformVersion'

export function EstimatorProvider({ children }: { children: ReactNode }) {
  const [estimator, setEstimator] = useState<Estimator | null>(null)
  const { library, account } = useEthers()
  const { oracle, tokenRegistry, uniswapV3Registry, curveDepositZapRegistry } = useContracts()
  const config = useConfig()
  const platformVersion = usePlatformVersion()
  const isOld = platformVersion === PlatformVersion.OLD

  useEffect(() => {
    const signer = library?.getSigner()
    if (signer && !estimator && account) {
      setEstimator(
        new Estimator(
          isOld,
          signer,
          oracle,
          tokenRegistry,
          uniswapV3Registry,
          curveDepositZapRegistry,
          config.aaveLendAdapter,
          config.compoundAdapter,
          config.curveAdapter,
          config.curveLPAdapter,
          config.curveRewardsAdapter,
          config.kyberSwapAdapter,
          config.metastrategyAdapterAddress,
          config.sushiSwapAdapter,
          config.synthetixAdapterAddress,
          config.uniswapV2Adapter,
          config.uniswapV3Adapter,
          config.yearnV2Adapter
        )
      )
    }
  }, [config, curveDepositZapRegistry, estimator, library, oracle, tokenRegistry, uniswapV3Registry, account, isOld])

  return <EstimatorContext.Provider value={estimator}>{children}</EstimatorContext.Provider>
}
