import { assetHTTPUrl } from '../misc/helpers/address'
import { getColor } from 'color-thief-react'
import { useQueries, useQuery } from 'react-query'
import { Colors } from '../constants'
import { logoRewriteForColorThief } from './protocols/helpers/logoRewrite'

const defaultColor = Colors.Neutral[200]

interface Params {
  queryKey: [string]
}

const calculateColor = ({ queryKey }: Params) => {
  const [httpUrl] = queryKey
  if (!httpUrl) {
    return Promise.resolve(defaultColor)
  }
  return getColor(logoRewriteForColorThief(httpUrl), 'hex', 'Anonymous')
}

const QUERY_OPTIONS = {
  staleTime: 1000 * 60 * 20,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
}

export function useImageDominantColor(uri: string | undefined): string {
  const httpUrl = assetHTTPUrl(uri)

  // eslint-disable-next-line
  // @ts-ignore
  const { data } = useQuery<string>(httpUrl, calculateColor, QUERY_OPTIONS)

  return (data ?? defaultColor) as string
}

export function useImagesDominantColors(uris: Array<string | undefined>): string[] {
  const httpsUrls = uris.map(assetHTTPUrl)

  const urls = httpsUrls.map((url) => ({ queryKey: url, queryFn: calculateColor, ...QUERY_OPTIONS }))
  // eslint-disable-next-line
  // @ts-ignore
  const results = useQueries(urls)

  return results.map((result) => result.data ?? defaultColor) as string[]
}
