import { Button, ButtonProps, ButtonVariant } from './Button'
import React, { memo, useEffect, useRef, useState } from 'react'
import { TransactionStatus } from '@usedapp/core/src/model/TransactionStatus'
import { ConnectionButton } from './ConnectionButton'
import styled from 'styled-components'
import { BorderRad } from '../../constants'
import { useToastMessage } from '../../providers/toasts/context'
import { parseErrorMessage } from '../../misc/utils'

interface ButtonPropsWithTransaction extends ButtonProps {
  transactionStatus: TransactionStatus
}

export const TransactionButton = memo(({ onClick, transactionStatus, ...other }: ButtonPropsWithTransaction) => {
  const previousTransaction = useRef<TransactionStatus>(transactionStatus)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (previousTransaction.current !== transactionStatus && transactionStatus.status === 'Exception') {
      toastMessage(parseErrorMessage(transactionStatus?.errorMessage ?? '') || transactionStatus.errorMessage, {
        type: 'error',
      })
    } else {
      setErrorMessage(null)
    }
  }, [toastMessage, transactionStatus, transactionStatus.errorMessage, transactionStatus.status])

  return errorMessage ? (
    <Button {...other} variant={ButtonVariant.negative} disabled>
      {errorMessage}
    </Button>
  ) : (
    <ConnectionButton {...other} loading={transactionStatus.status === 'Mining'} onClick={onClick} />
  )
})

export const ClaimButton = styled(TransactionButton)`
  min-width: initial;
  height: 24px;
  padding: 0 4px;
  float: right;
  width: 72px;
  border-radius: ${BorderRad.s};
`
