import { ChainId, CHAIN_NAMES } from '@usedapp/core'
import { ENVIRONMENT_CONFIG } from './environment'
const { INFURA_PROJECT_ID, ENVIRONMENT, MAINNET_RPC_URL } = ENVIRONMENT_CONFIG
export const infuraRpcUrl = (network: string) => `https://${network}.infura.io/v3/${INFURA_PROJECT_ID}`

const applicationConfig = {
  network: {
    urls: {
      1: ENVIRONMENT === 'PRODUCTION' ? infuraRpcUrl(CHAIN_NAMES[ChainId.Mainnet]) : MAINNET_RPC_URL,
      3: infuraRpcUrl(CHAIN_NAMES[ChainId.Ropsten]),
      4: infuraRpcUrl(CHAIN_NAMES[ChainId.Rinkeby]),
      5: infuraRpcUrl(CHAIN_NAMES[ChainId.Goerli]),
      42: infuraRpcUrl(CHAIN_NAMES[ChainId.Kovan]),
    },
    defaultChainId: ChainId.Mainnet,
  },

  locales: {
    enUS: {
      locale: 'en-US',
      currency: 'USD',
    },
  },

  createPortfolio: {
    defaultThreshold: 50,
    defaultSlippage: 970,
  },

  leaderboards: {
    portfolioCard: {
      tokenIconsCount: 3,
    },
  },

  notifications: {
    checkInterval: 500,
    expirationPeriod: 15000,
  },
}

export function getApplicationConfig() {
  return applicationConfig
}
