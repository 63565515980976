import { createGlobalStyle } from 'styled-components'
import { Colors, Device, Fonts } from './styles'

export const LandingGlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;

    &::-webkit-scrollbar {
      display: none;
    }
  }


  body, html {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    font-family: ${Fonts.primary};
    font-size: 16px;
    line-height: 16px;
    background-color: ${Colors.Primary[200]}
  }

  body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
  }

  body > #__next {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 150%;
    font-weight: 700;
  }

  h1 {
    font-size: 81px;
    line-height: 80px;
    font-weight: 800;
    font-family: ${Fonts.secondary};
    color: ${Colors.Primary[800]};

    @media ${Device.mobile} {
      font-size: 36px;
      line-height: 36px;
    }
  }

  h2 {
    font-size: 54px;
    line-height: 56px;
    font-weight: 800;
    font-family: ${Fonts.secondary};
    color: ${Colors.Primary[800]}
  }

  h3 {
    font-size: 36px;
    line-height: 36px;
    font-weight: 800;
    font-family: ${Fonts.secondary};
    color: ${Colors.Neutral[400]};

    @media ${Device.mobile} {
      font-size: 24px;
      line-height: 24px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    font-family: ${Fonts.secondary};
    color: ${Colors.Neutral[400]}
  }

  h5 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    font-family: ${Fonts.secondary};
  }

  h6 {
    font-size: 10px;
    line-height: 8px;
    font-weight: 600;
    font-family: ${Fonts.secondary};
  }

  p {
    margin: 0;
    line-height: 24px;
  }

  .paragraph-xl {
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.Neutral[300]};

    @media ${Device.mobile} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .paragraph-lg {
    font-size: 20px;
    line-height: 32px;
    color: ${Colors.Neutral[300]};
  }

  .paragraph-md {
    font-size: 16px;
    line-height: 24px;
    color ${Colors.Neutral[400]};
  }

  .paragraph-sm {
    font-size: 14px;
    line-height: 20px;
    color ${Colors.Neutral[400]};
  }

  .flex_row {
    display: flex;
  }

  .flex_center {
    align-items: center;
  }
`
