import React from 'react'
import styled from 'styled-components'

export function TwitterIcon({ className }: { color?: string; className?: string }) {
  return (
    <SvgIcon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.9671 2.81782C19.2334 3.14366 18.4452 3.36292 17.618 3.46182C18.4629 2.95582 19.1107 2.15466 19.4158 1.19939C18.6262 1.66782 17.7506 2.00822 16.8184 2.19222C16.0724 1.39719 15.0098 0.900391 13.8337 0.900391C11.5751 0.900391 9.74433 2.73196 9.74433 4.98979C9.74433 5.31026 9.78113 5.62306 9.85013 5.92129C6.45149 5.75109 3.43849 4.12269 1.42063 1.64789C1.06949 2.25202 0.867859 2.95429 0.867859 3.70486C0.867859 5.12319 1.58929 6.37516 2.68639 7.10809C2.01633 7.08662 1.38536 6.90262 0.834126 6.59672C0.834126 6.61436 0.834126 6.63046 0.834126 6.64809C0.834126 8.62992 2.24326 10.2829 4.11469 10.6578C3.77199 10.7513 3.41013 10.8011 3.03676 10.8011C2.77379 10.8011 2.51696 10.7751 2.26779 10.7283C2.78836 12.3529 4.29869 13.5358 6.08809 13.5688C4.68893 14.6659 2.92559 15.3199 1.00893 15.3199C0.679259 15.3199 0.353426 15.3007 0.032959 15.2624C1.84306 16.4223 3.99203 17.0993 6.30199 17.0993C13.8245 17.0993 17.9369 10.8678 17.9369 5.46359C17.9369 5.28649 17.9331 5.11016 17.9254 4.93459C18.7251 4.35729 19.4189 3.63739 19.9671 2.81782Z"
        fill="url(#paint0_radial_1339_83276)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1339_83276"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.11039 -2.54187) rotate(77.2461) scale(47.5423 27.1564)"
        >
          <stop stopColor="#9985FF" />
          <stop offset="0.531506" stopColor="#FF67F9" stopOpacity="0.88" />
          <stop offset="0.911307" stopColor="#FFA857" stopOpacity="0.88" />
        </radialGradient>
      </defs>
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 20px;
  height: 18px;
  position: relative;
`
