import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Img from 'next/image'

import { Colors, Device, Fonts } from '../../../styles'

export const CreateYourStrategyAnimation = () => {
  const [activeId, setActiveId] = useState<number>(0)
  const [animationFinished, setAnimationFinished] = useState<boolean>(false)

  const strategies = [
    {
      id: 3,
      title: 'Nested Strategies',
    },
    {
      id: 2,
      title: 'Yield Farming',
    },
    {
      id: 1,
      title: 'Assets',
    },
    {
      id: 0,
      title: 'Your strategy',
    },
  ]

  const animationLength = strategies.length - 1

  useEffect(() => {
    if (!animationFinished) {
      setTimeout(() => setActiveId(activeId + 1), 1000)
      activeId + 1 === animationLength && setAnimationFinished(true)
    }
  }, [animationFinished, activeId, animationLength])

  return (
    <Container>
      <StrategyList activeId={activeId}>
        {strategies.map((item, i) => {
          return (
            <StrategyListItem key={i} active={activeId === item.id} onClick={() => setActiveId(item.id)}>
              {item.title}
            </StrategyListItem>
          )
        })}
      </StrategyList>
      <StrategyContainer activeId={activeId}>
        <HeaderContainer active={activeId === 0}>
          <Header>
            <Avatar>
              <Img src="/images/landing/avatars/s1.png" alt="Avatar" layout="fill" objectFit="contain" />
            </Avatar>
            <Title>Trading & yi...</Title>
            <Arrow />
          </Header>
          <Subheader>
            <TokenItem>
              <TokenHeader>
                <TokenLogo>
                  <Img src="/images/landing/tokens/comp.svg" alt="Avatar" layout="fill" objectFit="contain" />
                </TokenLogo>
                <TokenTitle>
                  COMP <span>24%</span>
                </TokenTitle>
              </TokenHeader>
              <TokenLine />
            </TokenItem>
            <TokenItem>
              <TokenHeader>
                <TokenLogo>
                  <Img src="/images/landing/tokens/usdc.svg" alt="Avatar" layout="fill" objectFit="cover" />
                </TokenLogo>
                <TokenTitle>
                  USDC <span>44%</span>
                </TokenTitle>
              </TokenHeader>
              <TokenLineBlue />
            </TokenItem>
            <TokenItem>
              <TokenHeader>
                <TokenLogoWrapper>
                  <TokenLogo>
                    <Img src="/images/landing/tokens/uni.svg" alt="Avatar" layout="fill" objectFit="contain" />
                  </TokenLogo>
                  <TokenLogo>
                    <Img src="/images/landing/tokens/comp2.svg" alt="Avatar" layout="fill" objectFit="contain" />
                  </TokenLogo>
                </TokenLogoWrapper>
                <TokenTitle>
                  <span>+ 2 more</span>
                </TokenTitle>
              </TokenHeader>
              <TokenLinRose />
            </TokenItem>
          </Subheader>
        </HeaderContainer>

        <ItemsContainer>
          <Item active={activeId === 1}>
            <Avatar>
              <Img src="/images/landing/tokens/comp.svg" alt="Avatar" layout="fill" objectFit="contain" />
            </Avatar>
            <ItemContent>
              <Title>COMP</Title>
              <ItemLinesContainer>
                <TokenLine />
                <TokenLine />
              </ItemLinesContainer>
            </ItemContent>
          </Item>

          <Item active={activeId === 2}>
            <Avatar>
              <Img src="/images/landing/tokens/usdc.png" alt="Avatar" layout="fill" objectFit="contain" />
            </Avatar>
            <ItemContent>
              <Title>yvUSDC</Title>
              <ItemLinesContainer>
                <TokenLine />
                <TokenLine />
              </ItemLinesContainer>
            </ItemContent>
          </Item>
          <Item active={activeId === 3}>
            <Avatar>
              <Img src="/images/landing/tokens/yield.png" alt="Avatar" layout="fill" objectFit="contain" />
            </Avatar>
            <ItemContent>
              <Title>The Yield Farm</Title>
              <ItemLinesContainer>
                <TokenLine />
                <TokenLine />
              </ItemLinesContainer>
            </ItemContent>
          </Item>
        </ItemsContainer>

        <ShowMore>Show +1 More</ShowMore>
      </StrategyContainer>
    </Container>
  )
}

const HeaderContainer = styled.div<{ active: boolean }>`
  width: 100%;
  transition: 0.5s;

  opacity: ${(props) => (props.active ? 1 : 0.2)};
`

const Container = styled.div`
  position: absolute;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 400px;
  margin: 40px 0 0 0;
`

const StrategyList = styled.div<{ activeId: number }>`
  width: 100%;
  max-width: 131px;
  position: absolute;
  top: -105px;
  transition: 0.5s;

  transform: translateY(${(props) => props.activeId * 25}%);

  @media ${Device.mobile} {
    left: 10%;
  }

  @media ${Device.mobileL} {
    left: 0%;
  }
`

const StrategyListItemActiveCss = css`
  opacity: 1;
`

const StrategyListItem = styled.div<{ active: boolean }>`
  margin: 40px 0 0 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: ${Fonts.secondary};
  color: ${Colors.Neutral[400]};
  width: 100%;
  position: relative;
  opacity: 0.2;
  cursor: pointer;

  &:after {
    content: '';
    width: 100%;
    background: url('/images/landing/line.svg') no-repeat;
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 2px;
  }

  ${({ active }) => {
    return active ? [StrategyListItemActiveCss] : []
  }};
`

const StrategyContainer = styled.div<{ activeId: number }>`
  max-width: 300px;
  padding: 20px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  filter: drop-shadow(0px -2.4px 31.2px rgba(0, 0, 0, 0.04));
  border-radius: 16px;
  position: absolute;
  right: 0px;
  transition: 0.5s;

  bottom: ${(props) => {
    return `${props.activeId * 100 - 160}px`
  }};

  @media ${Device.mobile} {
    right: 10%;
  }

  @media ${Device.mobileL} {
    right: -110px;
  }
`

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
`

const Title = styled.h4`
  width: 75%;
`

const Arrow = styled.div`
  width: 30px;
  height: 30px;
  background: red;
  border-radius: 50%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TokenItem = styled.div`
  display: flex;
  flex-direction: column;
`
const TokenHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
`

const TokenLogo = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`
const TokenTitle = styled.p`
  font-size: 10px;
  color: ${Colors.Neutral[400]};
  margin: 0 0 0 8px;
  font-weight: 600;
  display: flex;

  & span {
    color: ${Colors.Neutral[300]};
    margin: 0 0 0 4px;
  }
`

const TokenLine = styled.div`
  width: 100%;
  height: 10px;
  margin: 8px 0 0 0;
  background: #d4f8e0;
  border-radius: 20px;
`

const TokenLineBlue = styled.div`
  width: 100%;
  height: 10px;
  margin: 8px 0 0 0;
  background: #e8f7ff;
  border-radius: 20px;
`

const TokenLinRose = styled.div`
  width: 100%;
  height: 10px;
  margin: 8px 0 0 0;
  background: #ffe9f1;
  border-radius: 20px;
`

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-bottom: 0.6px solid #f3f3f3;
  padding: 0 0 20px 0;
`

const TokenLogoWrapper = styled.div`
  position: relative;
  width: 25px;
  height: 20px;

  ${TokenLogo} {
    position: absolute;
  }

  ${TokenLogo}:nth-child(1) {
    left: 0;
    z-index: 2;
  }

  ${TokenLogo}:nth-child(2) {
    left: 6px;
    z-index: 1;
  }
`

const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 16px 0 0 0;
`

const ItemLinesContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  ${TokenLine}:nth-child(1) {
    background: ${Colors.Neutral[200]};
    opacity: 0.2;
  }

  ${TokenLine}:nth-child(2) {
    width: 30%;
    background: ${Colors.Neutral[200]};
    opacity: 0.1;
  }
`

const ItemContent = styled.div`
  width: 100%;
`
const ItemNotActiveCss = css`
  opacity: 0.2;
`

const Item = styled.div<{ active: boolean }>`
  display: flex;
  gap: 16px;
  width: 100%;
  background: #ffffff;
  box-shadow: 2.4px 4.8px 7.2px rgba(66, 57, 104, 0.04);
  border-radius: 10px;
  padding: 12px 14px;
  transition: 0.5s;

  ${Title} {
    font-size: 14px;
  }

  ${({ active }) => {
    return !active ? [ItemNotActiveCss] : []
  }};
`

const ShowMore = styled.p`
  margin: 38px 0 0 0;
  font-family: ${Fonts.secondary};
  color: #a4a8ca;
  font-style: normal;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 14px;
`
