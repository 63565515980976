import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'

import { Colors, Device, Fonts } from '../../../styles'

enum Direction {
  UP = 'up',
  DOWN = 'down',
}

const ANIMATION_NUM_VALUES = [
  [9, 2, 5, 4, 3, 7],
  [2, 8, 5, 1, 4, 3],
  [0, 3, 1, 9, 6, 5],
]

export const EarnFromCreationsAnimation = () => {
  const showNumbers = (numbers: Number[], direction: Direction) => (
    <List direction={direction}>
      {numbers.map((num, i) => (
        <li className="item" key={i}>
          {num}
        </li>
      ))}
    </List>
  )

  return (
    <Container>
      <FirstBlock>
        <Title>Performance fee</Title>
        <Value>
          <div className="fees-value">
            <div className="symbols">+</div>
            {showNumbers(ANIMATION_NUM_VALUES[0], Direction.DOWN)}
            {showNumbers(ANIMATION_NUM_VALUES[1], Direction.UP)}
            <div className="symbols">.</div>
            {showNumbers(ANIMATION_NUM_VALUES[2], Direction.DOWN)}
          </div>
          <Percentage>%</Percentage>
          <div className="index-indicator" />
        </Value>
      </FirstBlock>
      <Slider>
        <div className="line" />
        <div className="mark" />
      </Slider>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 70px;
`

const Percentage = styled.p`
  font-weight: 700;
  font-size: 54px;
  line-height: 56px;
  font-family: ${Fonts.primary};
  color: ${Colors.Positive[900]};
  font-family: ${Fonts.secondary};
`

const FirstBlock = styled.div`
  animation-name: firstBlockOpacity;
  animation-duration: 6s;
  animation-iteration-count: 1;
  animation-fill-mode: both;

  @keyframes firstBlockOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #a4a8ca;
  margin-bottom: 21px;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  overflow: hidden;
  height: 42px;

  & .fees-value {
    display: flex;
    height: 616px;
    font-weight: 700;
    font-size: 54px;
    line-height: 56px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #00b04c;
    font-family: ${Fonts.secondary};

    & .symbols {
      align-self: center;
    }
  }

  & .index-indicator {
    width: 40px;
    height: 40px;
    background: #dbffd4;
    border-radius: 43px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    position: relative;
    margin-left: 8px;

    &::before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 14px solid #00b04c;
      top: 12px;
      left: 12px;
    }
  }

  & img {
    margin-left: 5px;
  }
`

const List = styled.ul<{ direction: Direction }>`
  animation-name: change-${(props) => props.direction};
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  font-weight: 700;
  font-size: 54px;
  line-height: 56px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #00b04c;
  list-style: none;

  @keyframes change-down {
    0% {
      transform: translateY(45.5%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes change-up {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(45.5%);
    }
  }
`

const Slider = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  position: relative;

  & .line {
    animation-name: appearing;
    animation-duration: 6s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1.32%,
      rgba(153, 133, 255, 0.3) 17.2%,
      rgba(255, 103, 249, 0.264) 51.08%,
      rgba(255, 168, 87, 0.264) 79.67%,
      rgba(255, 255, 255, 0) 102.97%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    width: 100%;
    height: 8px;
    position: absolute;
    right: 0;

    @keyframes appearing {
      0% {
        width: 100%;
      }
      100% {
        width: 200%;
      }
    }
  }

  & .mark {
    animation-name: slide;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    width: 28px;
    height: 28px;
    background: radial-gradient(
      102.07% 286.25% at 20.45% -21.25%,
      #9985ff 0%,
      rgba(255, 103, 249, 0.88) 53.15%,
      rgba(255, 168, 87, 0.88) 91.13%
    );
    border: 4px solid #fdfcff;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    bottom: -11px;
    position: absolute;
    left: 0;
    top: 0;

    @keyframes slide {
      0% {
        transform: translateX(0px);
        width: 8px;
        height: 8px;
        border: none;
        top: 10px;
      }
      100% {
        transform: translateX(313px);
        width: 28px;
        height: 28px;
        border: 4px solid #fdfcff;
        top: 0;
      }
    }

    @media ${Device.mobile} {
      @keyframes slide {
        0% {
          transform: translateX(0px);
          width: 8px;
          height: 8px;
          border: none;
          top: 10px;
        }
        100% {
          transform: translateX(180px);
          width: 28px;
          height: 28px;
          border: 4px solid #fdfcff;
          top: 0;
        }
      }
    }
  }
`
