import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { DeploymentSpinner } from 'src/components/icons/DeploymentSpinner'
import styled from 'styled-components'
import { useWhitelistWait } from './useWhitelistWait'
import { useCanWhitelist } from './useWhitelistCheck'
import { useAuthentication } from '../../authentication/context'
import { useEthers } from '@usedapp/core'
import { WrongNetworkDialog } from './wrongNetwork'
import { getDefaultChainId } from 'src/hooks/useChainId'
import { MiniRpcProvider } from 'src/infrastructure/connectors/NetworkConnector'
import QueryString from 'qs'
import { isArray } from 'lodash'

interface UserAddressGuardProps {
  children: React.ReactNode
  whitelistPages: string[]
}

export function UserAddressGuard({ children, whitelistPages }: UserAddressGuardProps) {
  const router = useRouter()
  const pageIsAllowed = whitelistPages?.includes(router.route)
  const pageIsAllowedOrEarlyAccess = pageIsAllowed && router.route !== '/early-access'

  const canWhitelist = useCanWhitelist()
  const hasWhitelistLoaded = useWhitelistWait()
  const { library, active, chainId } = useEthers()

  const isUnconnected = library?.provider === undefined || !active || library?.provider instanceof MiniRpcProvider
  const connectedChainId = isUnconnected ? undefined : chainId
  const isCorrectChainId = connectedChainId === getDefaultChainId().chainId
  const [_, disconnect] = useAuthentication()

  useEffect(() => {
    if (hasWhitelistLoaded) {
      if (!canWhitelist && !pageIsAllowed) {
        // extra check for redirect-loop detection
        const retryFlag = isArray(router.query['r']) ? router.query['r'][0] : router.query['r']
        const qsOptions: Record<string, string> = {}

        if (router.route !== '/') {
          qsOptions['continue'] = encodeURIComponent(router.asPath)
        }

        if (retryFlag) {
          disconnect()
        }

        const cont = Object.keys(qsOptions).length ? '?' + QueryString.stringify(qsOptions) : ''
        router.push(`/early-access${cont}`)
      }
    }
  }, [canWhitelist, disconnect, hasWhitelistLoaded, pageIsAllowed, router])

  if (!isUnconnected && !pageIsAllowedOrEarlyAccess && !isCorrectChainId) {
    return <WrongNetworkDialog />
  }

  if (canWhitelist || pageIsAllowed) {
    return <>{children}</>
  }

  return (
    <FullpageContainer>
      <DeploymentSpinner />
    </FullpageContainer>
  )
}

const FullpageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
