import React from 'react'
import styled from 'styled-components'

export const EditIcon = () => (
  <SvgIcon viewBox="0 0 16 16" fill="none" color="currentColor">
    <path
      d="M7.33301 2.6665H2.66634C2.31272 2.6665 1.97358 2.80698 1.72353 3.05703C1.47348 3.30708 1.33301 3.64622 1.33301 3.99984V13.3332C1.33301 13.6868 1.47348 14.0259 1.72353 14.276C1.97358 14.526 2.31272 14.6665 2.66634 14.6665H11.9997C12.3533 14.6665 12.6924 14.526 12.9425 14.276C13.1925 14.0259 13.333 13.6868 13.333 13.3332V8.6665"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

const SvgIcon = styled.svg`
  width: 100%;
  height: 100%;
  position: relative;
`
