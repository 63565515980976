import { Container, Subtitle, Title } from 'src/components/pages/early-access/NotLoggedIn'
import { Footer, NavbarLandingPage } from 'src/newDesign/components/sections'
import styled from 'styled-components'
import { GlobalStyle } from '../../GlobalStyle'

export const WrongNetworkDialog = () => {
  return (
    <PageLayout>
      <GlobalStyle />
      <NavbarLandingPage />
      <WrongNetworkContainer>
        <Title>Please switch to mainnet</Title>
        <Subtitle>Enso currently only supports Ethereum Mainnet.</Subtitle>
      </WrongNetworkContainer>
      <Footer />
    </PageLayout>
  )
}

const PageLayout = styled.div`
  display: block;
  width: 100vw;
`

const WrongNetworkContainer = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 900px;
`
