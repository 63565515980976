import { useQuery } from '@apollo/client'
import { getPlatform } from 'src/infrastructure/subgraph/backend/queries'
import { PlatformQuery, QueryPlatformArgs } from 'src/infrastructure/subgraph/backend/types'
import { SubgraphClientName } from 'src/infrastructure/subgraph/SubgraphClientName'
import { useRefetchOnNewBlock } from 'src/infrastructure/subgraph/useRefetchOnNewBlock'
import { useChainId } from './useChainId'

export enum PlatformVersion {
  NEW = 'NEW',
  OLD = 'OLD',
}

export const usePlatformVersion = () => {
  const { chainId } = useChainId()

  const { data, refetch, previousData } = useQuery<PlatformQuery, QueryPlatformArgs>(getPlatform, {
    variables: { chainId },
    context: { clientName: SubgraphClientName.Enso },
  })

  useRefetchOnNewBlock<PlatformQuery>(refetch)

  return +(data?.platform?.version ?? previousData?.platform?.version ?? 0) === 1
    ? PlatformVersion.OLD
    : PlatformVersion.NEW
}
