import React from 'react'
import styled from 'styled-components'

interface JoystickIconProps {
  color?: string
  className?: string
}

export function JoystickIcon({ color, className }: JoystickIconProps) {
  return (
    <JoystickIconSvg viewBox="0 0 17 21" fill={color || 'currentColor'} className={className}>
      <path d="M9.99841 0.953186C7.78941 0.953186 5.99841 2.74298 5.99841 4.95078C5.99841 6.22628 6.58641 7.35149 7.51741 8.08349L1.55241 11.0642C1.22641 11.2682 0.998413 11.5512 0.998413 11.9472V15.9442C0.998413 16.3232 1.22241 16.6812 1.56041 16.8502L9.56041 20.8472C9.84241 20.9882 10.1544 20.9882 10.4364 20.8472L18.4364 16.8502C18.7744 16.6812 18.9984 16.3232 18.9984 15.9442V11.9472C18.9984 11.5512 18.7454 11.2202 18.4524 11.0742L12.4714 8.08239C13.4024 7.35039 13.9984 6.22628 13.9984 4.95078C13.9984 2.74298 12.2074 0.953186 9.99841 0.953186ZM7.99841 10.0732V10.9472C7.99841 12.0512 8.89341 12.9462 9.99841 12.9462C11.1034 12.9462 11.9984 12.0512 11.9984 10.9472V10.0732L15.7754 11.9462C13.8614 12.9032 11.0794 14.2902 9.98941 14.8352L4.22241 11.9612L7.99841 10.0732Z" />
    </JoystickIconSvg>
  )
}

export const JoystickIconSvg = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
