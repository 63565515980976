import { useEthers } from '@usedapp/core'
import { useEffect, useState } from 'react'
import { Status, useStatus } from 'src/hooks/useStatus'
import { useWhitelistedAddresses } from './useWhitelistCheck'

export const useWhitelistWait = (timeout?: number) => {
  const [_, isLoading] = useWhitelistedAddresses()

  return !isLoading
}
