import { Button, ButtonVariants } from '../../../newDesign/components/base'
import { ELink, LinkVariants } from '../../../newDesign/components/base/StyledLink'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors, Device, Fonts } from '../../../newDesign/styles'
import { SelectWalletModal } from '../../top/SelectWalletModal'

interface NotLoggedInProps {
  setEligibilityDialogActive: (a: boolean) => void
  isEligibilityDialogActive: boolean
}

export const NotLoggedIn = ({ setEligibilityDialogActive, isEligibilityDialogActive }: NotLoggedInProps) => {
  const [isSelectWalletActive, setSelectWalletActive] = useState(false)

  return (
    <>
      <Title>Join the Enso early access</Title>
      <Container>
        <Subtitle>
          Enso is currently only available to a small group of people. Connect to get started or see if you’re eligible
          for early access.
        </Subtitle>
      </Container>
      <ButtonContainer>
        <Button onClick={() => setSelectWalletActive((o) => !o)}>Connect</Button>
        <Button
          variant={ButtonVariants.secondary}
          onClick={() => {
            setEligibilityDialogActive(!isEligibilityDialogActive)
          }}
        >
          Check eligibility
        </Button>
      </ButtonContainer>
      <JoinButton
        variant={LinkVariants.outline}
        size={0}
        href={'https://forms.gle/6cjo81753nm8QfRg9'}
        rel="noopener noreferrer"
      >
        When can I join?
      </JoinButton>
      <SelectWalletModal open={isSelectWalletActive} onClose={() => setSelectWalletActive(false)} />
    </>
  )
}

export const Title = styled.h1`
  font-family: ${Fonts.secondary};
  font-size: 81px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  color: ${Colors.Primary[800]};
  z-index: 2;
  width: 1246px;
  @media ${Device.mobile} {
    font-size: 32px;
    line-height: 36px;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  object-fit: cover;

  @media ${Device.mobile} {
    height: 115px;
  }

  & h1 {
    background: #ffffff;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: 2;
    height: 200px;
    mix-blend-mode: screen;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0;
  gap: 16px;
  height: 56px;

  @media ${Device.mobile} {
    flex-direction: column;
    align-items: center;
    margin: 32px 0 150px 0;
  }
`

export const Subtitle = styled.p`
  max-width: 932px;
  width: 100%;
  font-family: ${Fonts.primary};
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  color: ${Colors.Neutral[300]};
  margin: 48px 0 0 0;
  padding: 0 56px;

  @media ${Device.laptop} {
    margin: 24px auto;
    padding: 0 24px;
    font-size: 16px;
    line-height: 24px;
  }
`

export const JoinButton = styled(ELink)`
  margin: 0 0 600px 0;
`
