import { constants, Contract, utils } from 'ethers'
import { ChainId, MULTICALL_ABI, MULTICALL_ADDRESS } from '../../constants'
import { MulticallRequest } from './MulticallRequest'

const multicallInterface = new utils.Interface(MULTICALL_ABI)

export const encodeCall = (contract: Contract, functionName: string, args: any[]) => ({
  address: contract.address,
  data: contract.interface.encodeFunctionData(functionName, args),
  decode: (data: string) => contract.interface.decodeFunctionResult(functionName, data),
})

export const encodeNoOp = (chainId: ChainId): MulticallRequest => ({
  address: MULTICALL_ADDRESS[chainId],
  data: multicallInterface.encodeFunctionData('getBlockHash', [constants.AddressZero]),
  decode: () => undefined,
})
