import { useLazyQuery, useQuery } from '@apollo/client'
import { useChainId } from '../../../hooks/useChainId'
import { ProfileDetailsQuery } from '../../../infrastructure/subgraph/backend/queries'
import {
  GetProfileDetailsQuery,
  GetProfileDetailsQueryVariables,
  User,
} from '../../../infrastructure/subgraph/backend/types'
import { SubgraphClientName } from '../../../infrastructure/subgraph/SubgraphClientName'
import { useAuthentication } from '../../../providers/authentication/context'
import { useRefetchOnNewBlock } from '../../../infrastructure/subgraph/useRefetchOnNewBlock'
import { useCallback, useEffect, useMemo } from 'react'

export function useUserDetailsWithSettings(id: string) {
  const { chainId } = useChainId()
  const [auth] = useAuthentication()

  const authJwt = auth?.jwt

  const queryOptions = useMemo(
    () => ({
      variables: { chainId, id: id.toLowerCase() },
      context: { clientName: SubgraphClientName.Enso, auth: authJwt },
    }),
    [authJwt, chainId, id]
  )

  const [executeQuery, { data, refetch, loading, error, called }] = useLazyQuery<
    GetProfileDetailsQuery,
    GetProfileDetailsQueryVariables
  >(ProfileDetailsQuery, queryOptions)

  const refetchOnlyIfCalled = useCallback(() => {
    if (!called) return
    return refetch()
  }, [called, refetch])

  useRefetchOnNewBlock(refetchOnlyIfCalled)

  useEffect(() => {
    if (authJwt && id) {
      setTimeout(() => {
        // FIXME: workaround for race condition causing userDetails to be fetched without jwt
        executeQuery({
          variables: { id: id.toLowerCase(), chainId },
          context: {
            clientName: SubgraphClientName.Enso,
            auth: authJwt,
          },
        })
      }, 1000)
    }
  }, [executeQuery, chainId, id, authJwt])

  const user = data?.profileDetails as User
  return {
    user,
    loading: loading || !called,
    error,
  }
}

export function useUserDetails(id: string) {
  const { chainId } = useChainId()
  const [auth] = useAuthentication()

  const { data, refetch, loading, error } = useQuery<GetProfileDetailsQuery, GetProfileDetailsQueryVariables>(
    ProfileDetailsQuery,
    {
      variables: { chainId, id: id.toLowerCase() },
      context: { clientName: SubgraphClientName.Enso, auth: auth?.jwt },
      skip: !id,
    }
  )

  useRefetchOnNewBlock<GetProfileDetailsQuery>(refetch)

  const user = data?.profileDetails as User
  return {
    user,
    loading: loading,
    error,
  }
}

export type UserDetails = NonNullable<GetProfileDetailsQuery['profileDetails']> | undefined
