import React from 'react'
import styled from 'styled-components'

export function UpIcon({ className }: { className?: string }) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      color="currentColor"
    >
      <rect width="16" height="16" rx="8" transform="matrix(-1 0 0 1 16.3 0)" fill="#DBFFD4" />
      <path
        d="M10.8457 9.24275L8.7288 5.71458C8.53459 5.39091 8.0655 5.39091 7.8713 5.71458L5.7544 9.24275C5.55444 9.57601 5.7945 10 6.18314 10H10.417C10.8056 10 11.0457 9.57601 10.8457 9.24275Z"
        fill="#00B04C"
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 17px;
  height: 16px;
  position: relative;
`
