import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'

import { mockData } from '../sections'
import { InvestInCard } from './InvestInCard'
import { Colors, Device } from 'src/newDesign/styles'

interface Data {
  avatar: string
  id: string
}

interface Item {
  id: number
  avatar: string
  title: string
  currency: string
  investors: {
    count: number
    data: Data[]
  }
  tvl: string
  pctChange: string
}

export const InvestInAnimation = () => {
  return (
    <Container>
      <Loader>
        <svg>
          <circle className="bg" cx="27" cy="33" r="20" />
          <circle className="meter" cx="27" cy="27" r="20" />
        </svg>
        <CheckMark>
          <Image src="/images/landing/checkMark.svg" alt="check-mark" width={10} height={10} />
        </CheckMark>
      </Loader>
      <CardWrapper>
        {mockData.map((item: Item, index: number) => (
          <InvestInCard
            key={index}
            src={item.avatar}
            title={item.title}
            value={item.pctChange}
            isOpacityNeeded={index !== 2}
          />
        ))}
      </CardWrapper>
    </Container>
  )
}

const Container = styled.div`
  max-height: 296px;
  overflow: hidden;
  z-index: 0;
  position: relative;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 360px;
  padding: 0 45px;
  transform: translateY(60%);
  align-items: center;

  @media ${Device.mobile} {
    padding: 0;
  }

  animation-name: move;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.79, 0.87, 0.97, 0.55);
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @keyframes move {
    0% {
      transform: translateY(60%);
    }
    50% {
      transform: translateY(-10%);
    }
    100% {
      transform: translateY(-10%);
    }
  }
`

const Loader = styled.div`
  display: flex;
  position: absolute;
  top: 70px;
  left: 168px;
  width: 60px;
  height: 60px;
  opacity: 0;

  animation-name: visible;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @keyframes visible {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${Device.mobile} {
    left: 43%;
  }

  & .bg {
    fill: none;
    stroke-width: 5px;
    stroke: ${Colors.Positive[300]};
  }

  .meter {
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: ${Colors.Positive[900]};
    stroke-dasharray: 290;
    stroke-dashoffset: 560;
    animation: progress-2 8s ease-in-out;
    animation-fill-mode: both;

    @keyframes progress-2 {
      60% {
        stroke-dashoffset: 200;
      }
      100% {
        stroke-dashoffset: 90;
      }
    }
  }
`

const CheckMark = styled.div`
  background: ${Colors.Positive[900]};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transform: rotateZ(90deg);
  position: absolute;
  top: 20px;
  left: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  & img {
    width: 10px;
    height: 10px;
  }

  animation-name: rotate;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @keyframes rotate {
    80% {
      transform: rotateZ(180deg);
      opacity: 0;
    }
    100% {
      transform: rotateZ(0deg);
      opacity: 1;
    }
  }
`
