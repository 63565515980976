import { gql } from '@apollo/client'

export const TOKEN_DERIVED_ETH = gql`
  query GetTokenDerivedEth($id: ID!) {
    token(id: $id) {
      derivedETH
    }
  }
`
export const TOKEN_DERIVED_ETH_V3 = gql`
  query GetTokenDerivedEth($address: ID!) {
    token(id: $address) {
      derivedETH
    }
  }
`

export const TOKENS_DERIVED_ETH = gql`
  query GetTokensDerivedEth($ids: [ID!]!, $amount: Int!) {
    tokens(where: { id_in: $ids }, first: $amount) {
      derivedETH
      id
    }
  }
`

export const TOKENS_DERIVED_ETH_V3 = gql`
  query GetTokensDerivedEth($ids: [ID!]!, $amount: Int!) {
    tokens(first: $amount, where: { id_in: $ids }) {
      derivedETH
      id
    }
  }
`

export const UNI_POOLS_BY_TOKEN = gql`
  query GetUniPoolByToken($ids: [ID!]!, $amount: Int!) {
    pairs(where: { token1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", token0_in: $ids }, first: $amount) {
      id
    }
  }
`
