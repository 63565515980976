import React, { ReactNode, useEffect, useState } from 'react'
import { PosthogContext } from './context'
import { useEthers, useTokenBalance, useEtherBalance } from '@usedapp/core'
import { ENVIRONMENT_CONFIG } from '../../../config/environment'
import { useEtherPrice } from '../etherPrice/context'
import { ethers } from 'ethers'
import { useAuthentication } from '../authentication/context'
import { useUserDetails } from 'src/components/pages/profile/useUserDetails'
import { useUserStrategies } from 'src/components/pages/profile/useUserStrategies'

export function PosthogProvider({ children }: { children: ReactNode }) {
  const [posthog, setPosthog] = useState<any>()
  const [posthogInterval, setPosthogInterval] = useState<ReturnType<typeof setInterval>>()
  const { account } = useEthers()
  const { user } = useUserDetails(account ?? '')
  const {
    meta: { totalCountInvested },
  } = useUserStrategies(account ?? '', 'INVESTED', {}, '', null, 0, 1)
  const [authentication] = useAuthentication()
  const ethBalance = useEtherBalance(account)
  const wethBalance = useTokenBalance('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', account)
  const { price } = useEtherPrice()
  const inProd = ENVIRONMENT_CONFIG.ENVIRONMENT == 'PRODUCTION'

  useEffect(() => {
    if (inProd) {
      setPosthogInterval(
        setInterval(() => {
          const injectedPosthog = (window as any)?.posthog
          if (injectedPosthog) setPosthog(injectedPosthog)
        }, 500)
      )
    }
  }, [inProd])

  useEffect(() => {
    if (posthog && posthogInterval) clearInterval(posthogInterval)
    setTimeout(() => posthogInterval && clearInterval(posthogInterval), 10000)
  }, [posthog, posthogInterval])

  useEffect(() => {
    if (posthog && account)
      posthog.identify(account, null, { firstWalletConnected: new Date(Date.now()).toISOString() })
  }, [account, posthog])

  useEffect(() => {
    if (posthog && account && authentication?.account === account && authentication?.isAuthenticated)
      posthog.identify(account, null, { firstProfileCreated: new Date(Date.now()).toISOString() })
  }, [authentication?.account, account, posthog, authentication?.isAuthenticated])

  useEffect(() => {
    if (posthog && account && ethBalance && wethBalance && price)
      posthog.identify(account, {
        walletBalance: Number(ethers.utils.formatEther(ethBalance.add(wethBalance))) * price,
      })
  }, [account, posthog, ethBalance, wethBalance, price])

  useEffect(() => {
    if (posthog && user)
      posthog.identify(user.id, {
        name: user.name,
        type: user.type,
        followers: user.followerCount,
        following: user.followingCount,
        holdings: user.investorMeta?.investmentsUSD,
        strategies: user.managerMeta?.strategiesCount,
        tvl: user.managerMeta?.tvl,
        investors: user.managerMeta?.holdersCount,
      })
  }, [posthog, user])

  useEffect(() => {
    if (posthog && account && Number.isInteger(totalCountInvested))
      posthog.identify(account, {
        investments: totalCountInvested,
      })
  }, [posthog, account, totalCountInvested])

  return <PosthogContext.Provider value={posthog}>{children}</PosthogContext.Provider>
}
