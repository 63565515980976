import React from 'react'
import styled from 'styled-components'
import { Colors, Device, Transitions } from '../../styles'
import { DiscordSocialIcon } from '../../../components/icons/socials/DiscordSocialIcon'
import { TwitterSocialIcon } from '../../../components/icons/socials/TwitterSocialIcon'
import { MediumSocialIcon } from '../../../components/icons/socials/MediumSocialIcon'

interface Props {
  className?: string
}

export const SocialMedia = ({ className }: Props) => {
  return (
    <SocialWrapper className={className}>
      <a href="https://discord.gg/enso-finance" target="_blank">
        <DiscordSocialIcon />
      </a>
      <a href="https://twitter.com/EnsoFinance/" target="_blank">
        <TwitterSocialIcon />
      </a>
      <a href="https://medium.com/ensofinance" target="_blank">
        <MediumSocialIcon />
      </a>
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  svg {
    fill: ${Colors.Neutral[400]};
    color: ${Colors.Neutral[400]};
    width: 20px;
    height: 20px;

    &:hover {
      & * {
        fill: ${Colors.Neutral[200]};
      }
    }
  }
`
