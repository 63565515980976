import React from 'react'
import { Transitions } from '../../constants'
import styled from 'styled-components'

export interface ArrowProps {
  direction?: 'left' | 'up' | 'right' | 'down'
  className?: string
  size?: number
}

export function Arrow({ className, direction, size = 24 }: ArrowProps) {
  return (
    <ArrowStyles
      x="0px"
      y="0px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      fill="none"
      color="currentColor"
      className={className}
      direction={direction}
      size={size}
    >
      <path
        d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z"
        fill="currentColor"
        className="arrowPath"
      />
    </ArrowStyles>
  )
}

export const ArrowStyles = styled.svg<{ size?: number }>`
  width: ${({ size }) => size}px;
  height: 24px;
  position: relative;

  .arrowPath {
    transition: ${Transitions.allS};
    transform-origin: 50% 50%;
    transform: ${({ direction }) => {
      switch (direction) {
        case 'up':
          return 'rotate(180deg)'
        case 'right':
          return 'rotate(270deg)'
        case 'left':
          return 'rotate(90deg)'
        case 'down':
        default:
          return 'rotate(0deg)'
      }
    }};
  }
`
