import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../constants'
import { UseComboboxGetItemPropsOptions } from 'downshift'
import { DropdownOptions, OptionItem } from './DropdownOptions'
import { DropdownSectionsAndItemIndex } from './GlobalSearch'
import { GlobalSearchAllResultType } from './useGlobalSearch'

interface DropdownCategoriesProps {
  sectionedOptions: DropdownSectionsAndItemIndex<GlobalSearchAllResultType>
  getItemProps: (options: UseComboboxGetItemPropsOptions<any>) => any
  highlightedIndex: number
  hasResults: boolean
}
export const DropdownCategories = ({
  sectionedOptions: { sections },
  getItemProps,
  highlightedIndex,
  hasResults,
}: DropdownCategoriesProps) => {
  if (!hasResults) return <DropdownEmpty />
  return (
    <>
      {sections.map(({ title, options }, sectionIndex) =>
        options.length ? (
          <DropdownCategoryGroup key={sectionIndex}>
            <OptionTitle>{title}</OptionTitle>
            <DropdownOptions options={options} getItemProps={getItemProps} highlightedIndex={highlightedIndex} />
          </DropdownCategoryGroup>
        ) : null
      )}
    </>
  )
}

const DropdownEmpty = () => <OptionsAbsent>Nothing to see here</OptionsAbsent>

const DropdownCategoryGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`

export const OptionTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${Colors.Neutral[500]};

  & + ${OptionItem} {
    margin-top: 8px;
  }
`
export const OptionsAbsent = styled(OptionTitle)`
  color: ${Colors.Neutral[500]};
  text-align: center;
`
