import { EnsoNetworkConfig } from './getChainConfig'
import { ENVIRONMENT_CONFIG } from './environment'
import { ChainId } from '@usedapp/core'
import Deployments from '../deployments.json'
import Deprecated from '../deprecated.json'
const { SUBGRAPH } = ENVIRONMENT_CONFIG
const deployedAddresses = Deployments['mainnet-new']

const external = {
  multicallAddress: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  wethAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  uniswapFactoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  uniswapV2SubgraphUrl: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
  uniswapV3SubgraphUrl: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
}

const enso: EnsoNetworkConfig = {
  curveDepositZapRegistry: deployedAddresses.CurveDepositZapRegistry,
  fullRouter: deployedAddresses.FullRouter,
  uniswapV3Registry: deployedAddresses.UniswapV3Registry,
  uniswapV3Adapter: deployedAddresses.UniswapV3Adapter,
  defaultEstimator: deployedAddresses.DefaultEstimator,
  strategyEstimator: deployedAddresses.StrategyEstimator,
  oracleAddress: deployedAddresses.EnsoOracle,
  tokenRegistry: deployedAddresses.TokenRegistry,
  whitelistAddress: deployedAddresses.Whitelist,
  curveAdapter: deployedAddresses.CurveAdapter,
  curveLPAdapter: deployedAddresses.CurveLPAdapter,
  compoundAdapter: deployedAddresses.CompoundAdapter,
  aaveLendAdapter: deployedAddresses.AaveV2Adapter,
  curveRewardsAdapter: deployedAddresses.CurveGaugeAdapter,
  uniswapV2Adapter: deployedAddresses.UniswapV2Adapter,
  strategyControllerAddress: deployedAddresses.StrategyController,
  loopRouterAddress: deployedAddresses.LoopRouter,
  strategyProxyFactoryAddress: deployedAddresses.StrategyProxyFactory,
  metastrategyAdapterAddress: deployedAddresses.MetaStrategyAdapter,
  batchDepositRouterAddress: deployedAddresses.BatchDepositRouter,
  synthetixAdapterAddress: deployedAddresses.SynthetixAdapter,
  yearnV2Adapter: deployedAddresses.YEarnV2Adapter,
  kyberSwapAdapter: deployedAddresses.KyberSwapAdapter,
  sushiSwapAdapter: deployedAddresses.SushiSwapAdapter,
  subgraphUrl: SUBGRAPH[ChainId.Mainnet],
  ensoTournament: '0x',
}

export const config = { ...external, ...enso }
