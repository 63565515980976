import React from 'react'
import styled from 'styled-components'

interface StarIconProps {
  className?: string
  fillColor?: string
}

export function StarIcon({ className, fillColor }: StarIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="currentColor" color="currentColor" className={className}>
      <path
        d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fillColor}
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 100%;
  height: 100%;
  position: relative;
`
