import React from 'react'
import styled from 'styled-components'

interface AlertProps {
  color?: string
  className?: string
}

export function Alert({ color, className }: AlertProps) {
  return (
    <SvgIcon x="0px" y="0px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1689 18.2514L12.9793 4.5577C12.7723 4.21162 12.4007 4 11.9998 4C11.5989 4 11.2273 4.21158 11.0204 4.5577L2.83078 18.2514C2.61779 18.6076 2.61149 19.0518 2.81434 19.4139C3.0172 19.7761 3.39771 20 3.8102 20H20.1895C20.6019 20 20.9825 19.776 21.1853 19.4139C21.3882 19.0518 21.3819 18.6076 21.1689 18.2514ZM12.0043 8.73682C12.4747 8.73682 12.8727 9.00436 12.8727 9.47854C12.8727 10.202 12.8305 11.0836 12.7883 11.9651C12.746 12.8467 12.7038 13.7282 12.7038 14.4517C12.7038 14.8287 12.2938 14.9867 12.0043 14.9867C11.6185 14.9867 11.2928 14.8286 11.2928 14.4517C11.2928 13.7282 11.2506 12.8467 11.2084 11.9651C11.1662 11.0836 11.124 10.202 11.124 9.47854C11.124 9.00436 11.5099 8.73682 12.0043 8.73682ZM11.0889 16.7621C11.0889 17.2606 11.4869 17.6983 12.0175 17.6983C12.5119 17.6983 12.9341 17.2606 12.9341 16.7621C12.9341 16.2514 12.5119 15.8258 12.0175 15.8258C11.4868 15.8258 11.0889 16.2514 11.0889 16.7621Z"
        fill={color || 'currentColor'}
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 24px;
  height: 24px;
  position: relative;
`
