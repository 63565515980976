import { useMemo } from 'react'
import { useCoinGeckoMarketData } from './queries/useCoinGeckoCalls'
import { useCoingeckoCoins } from './useCoingeckoCoins'

export const useImageSourceFromSymbol = (symbol: string): null | string => {
  const coingeckoCoins = useCoingeckoCoins()
  const coinGeckoElement = (coingeckoCoins ?? []).find(
    (crypto) => crypto.symbol.toLowerCase() === symbol?.toLowerCase()
  )
  const marketData = useCoinGeckoMarketData(coinGeckoElement?.id ? [coinGeckoElement?.id] : undefined)
  return useMemo(() => {
    if (marketData) {
      return marketData[0]?.image
    }
    return null
  }, [marketData])
}
