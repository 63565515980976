import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useIsMobile } from '../../../hooks/useScreenSize'
import { Fonts } from '../../../newDesign/styles'
import { Colors } from '../../../constants'
import { LandingLinkSize, SLink } from '../../../newDesign/components/base/StyledLink'
import { LandingLayout } from '../../../newDesign/components/sections'

const BLACKLISTED_PAGES = [
  '/create',
  '/explore/strategies/[...params]',
  '/strategy/[id]',
  '/profile/[id]',
  '/restructure/[id]',
]

export const MobileGuard = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()
  const pageNotAllowedOnMobile = BLACKLISTED_PAGES.includes(router.route)
  const isOnMobile = useIsMobile()

  return pageNotAllowedOnMobile && isOnMobile ? (
    <LandingLayout>
      <StyledContainer>
        <PhoneWrapper>
          <Header>Enso doesn't support mobile</Header>
          <SubText>Stay tuned for the Enso mobile release in the near future or use the desktop app.</SubText>
          <StyledSLink href="/" size={LandingLinkSize.s}>
            Take me back
          </StyledSLink>
        </PhoneWrapper>
      </StyledContainer>
    </LandingLayout>
  ) : (
    <>{children}</>
  )
}

const StyledSLink = styled(SLink)`
  box-shadow: none;
  margin-top: 32px;
  padding: 16px 24px;
  width: 184px;

  &:hover {
    text-decoration: underline;
  }
`

const SubText = styled.p`
  color: ${Colors.Neutral[400]};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  font-family: ${Fonts.primary};
  text-align: center;
  margin-top: 32px;
`
const Header = styled.h3`
  font-family: ${Fonts.secondary};
  font-size: 36px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.04em;
  text-align: center;
`
const PhoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 280px;
`
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: url('/images/phone.png') no-repeat center;
`
