// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
try {
  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
  const COMMIT_HASH =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA &&
    JSON.stringify(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA).trim().length
      ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      : 'local'

  const environment = !process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
    ? 'local'
    : process.env.NEXT_PUBLIC_ENVIRONMENT.toLowerCase()

  Sentry.init({
    dsn: SENTRY_DSN || 'https://24cbfa671e5f44ee930d9a08c010e32c@o1230292.ingest.sentry.io/6376885',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    tunnel: '/api/sentry',
    release: COMMIT_HASH,
    environment,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
} catch (e) {
  console.log(e)
}
