import React from 'react'
import styled, { css } from 'styled-components'
import { Colors } from '../../constants'

interface DeploymentSpinnerProps {
  className?: string
  size?: number
  padding?: number
  stop?: boolean
}
export function DeploymentSpinner({ className, size = 40, padding = 0, stop }: DeploymentSpinnerProps) {
  return (
    <SpinnerContainer className={className} size={size} padding={padding}>
      <Spinner width="40" height="40" viewBox="0 0 90 90" fill="#393550" stop={stop}>
        <path
          d="M45.7634 90C67.6949 90 84.1426 75.5562 89.6246 59.0752C81.6855 75.5562 66.3711 87.4085 45.7634 87.4085C24.9653 87.4085 4.74028 71.4835 4.74028 47.2246C4.74028 25.9284 21.3784 8.70675 40.8509 8.70675C56.9214 8.70675 68.2644 18.5218 68.2644 25.1878C68.2644 28.5216 66.3748 30.7436 62.7824 30.7436C55.5976 30.7436 52.5728 17.7776 38.3937 17.7776C24.0242 17.7776 11.7365 31.1113 11.7365 47.2211C11.7365 67.5901 28.5632 81.2932 45.0127 81.2932C52.9537 81.2932 56.5442 78.7018 56.5442 78.7018C56.5442 78.7018 52.9537 79.9966 47.2813 79.9966C27.0508 79.9966 18.7326 64.6275 18.7326 54.443C18.7326 46.6651 23.6488 43.3331 27.7997 43.3331C38.3883 43.3331 36.8759 66.1088 63.1614 66.1088C77.1519 66.1088 90 56.8497 90 38.7026C90 18.1488 69.7695 -5.51803e-07 45.7526 -3.61854e-07C20.7963 -1.64477e-07 -5.19597e-09 19.9996 -1.16909e-08 44.9991C-1.81374e-08 69.8121 21.3639 89.9964 45.7526 89.9964"
          fill="#393550"
        />
      </Spinner>
    </SpinnerContainer>
  )
}

export const SpinnerContainer = styled.div<DeploymentSpinnerProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size ?? 40}px;
  height: ${({ size }) => size ?? 40}px;
  flex-shrink: 0;
  flex-grow: 0;
  ${({ padding }) => padding && `padding: ${padding}px`};
`

export const Spinner = styled.svg<{ stop?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;

  ${({ stop }) =>
    !stop &&
    css`
      animation: rotateDeploymentSpinner 1.5s linear infinite;

      @keyframes rotateDeploymentSpinner {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `}
`

export const DeploymentProcessWrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  min-height: 40px;
  margin: 24px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${Colors.Neutral[700]};
`
