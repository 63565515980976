import Image from 'next/image'
import styled from 'styled-components'

export const CommonImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  & img {
    object-fit: contain;
  }
`
