import jazzicon from '@metamask/jazzicon'
import React, { useEffect, useMemo, useRef } from 'react'

function getSeed(address: string) {
  return parseInt(address.slice(2, 10), 16)
}

interface Props {
  address: string
  size: number
  className?: string
}

export function JazzIcon({ address, size, className }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const element = useMemo(() => {
    if (typeof document !== 'undefined') return jazzicon(size, getSeed(address))
  }, [size, address])

  useEffect(() => {
    if (ref.current && element) {
      ref.current.innerHTML = ''
      ref.current.appendChild(element)
    }
  }, [address, element])

  return <span style={{ width: size, height: size }} ref={ref} className={className} />
}
