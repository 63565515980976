import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import styled from 'styled-components'

import { Colors, Device, Shadows } from '../../styles'

interface Props {
  alt: string
  source: string
}

export const PartnerLogo = ({ alt, source }: Props) => {
  return (
    <LogoContainer>
      <Image alt={alt} src={source} width={70} height={70} />
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${Colors.Neutral[0]};
  box-shadow: ${Shadows.large};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;

  @media ${Device.mobile} {
    width: 73px;
    height: 73px;
    margin: 9px;

    & img {
      width: 50px !important;
      height: 50px !important;
    }
  }
`
