import React from 'react'
import LoadingBarLib, { LoadingBarRef } from 'react-top-loading-bar'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { EnsoGradient } from '../../icons/EnsoLogoLink'
export enum LoadingBarAction {
  IDLE = 'IDLE',
  START = 'START',
  PROGRESS = 'PROGRESS',
  FINISH = 'FINISH',
}
export interface LoadingBarProps {
  action: LoadingBarAction
  percent?: number
}
export const LoadingBar = ({ action, percent }: LoadingBarProps) => {
  const [progress, setProgress] = useState(0)
  const ref = useRef<LoadingBarRef>(null)

  useEffect(() => {
    progress === 80 && setProgress(100)
  }, [progress])

  useEffect(() => {
    if (ref?.current) {
      switch (action) {
        case LoadingBarAction.START:
          setProgress(0)
          ref?.current?.continuousStart(0, 50)
          break
        case LoadingBarAction.PROGRESS:
          break
        case LoadingBarAction.FINISH:
        case LoadingBarAction.IDLE:
          ref?.current?.complete()
          setProgress(0)
          break
      }
    }
  }, [action, percent])

  return (
    <>
      <StyledLoadingBar ref={ref} color={'transparent'} onLoaderFinished={() => setProgress(0)} />
    </>
  )
}

const StyledLoadingBar = styled(LoadingBarLib)`
  background: ${EnsoGradient};
  > div {
    background: ${EnsoGradient};
  }
`
