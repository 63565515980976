import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ContentContainer, Button, StrategyCard } from '../base'
import { Colors, Device } from '../../styles'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'
import { StrategyCardSmall } from '../base/StrategyCardSmall'
import { SLink } from '../base/StyledLink'

export const mockData = [
  {
    id: 1,
    avatar: '/images/landing/avatars/1.png',
    title: 'Aped in',
    currency: '$BNKL',
    investors: {
      count: 801,
      data: [
        { avatar: '/images/landing/avatars/s1.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s2.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s3.png', id: 'q13' },
      ],
    },
    tvl: '$320,342.34',
    pctChange: '+23%',
  },
  {
    id: 2,
    avatar: '/images/landing/avatars/2.png',
    title: 'Farmpire',
    currency: '$BNKL',
    investors: {
      count: 801,
      data: [
        { avatar: '/images/landing/avatars/s1.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s2.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s3.png', id: 'q13' },
      ],
    },
    tvl: '$320,342.34',
    pctChange: '+23%',
  },
  {
    id: 3,
    avatar: '/images/landing/avatars/3.png',
    title: 'GOAT Yield',
    currency: '$BNKL',
    investors: {
      count: 801,
      data: [
        { avatar: '/images/landing/avatars/s1.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s2.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s3.png', id: 'q13' },
      ],
    },
    tvl: '$320,342.34',
    pctChange: '+23%',
  },
  {
    id: 4,
    avatar: '/images/landing/avatars/4.png',
    title: 'Stablesgagles',
    currency: '$BNKL',
    investors: {
      count: 801,
      data: [
        { avatar: '/images/landing/avatars/s1.png', id: 'q11' },
        { avatar: '/images/landing/avatars/s2.png', id: 'q12' },
        { avatar: '/images/landing/avatars/s3.png', id: 'q13' },
      ],
    },
    tvl: '$320,342.34',
    pctChange: '+23%',
  },
]

export const BestStrategiesSection = () => {
  const isMobile = useCheckMobileScreen()
  const [isColumn, setIsColumn] = useState(false)
  return (
    <ContentContainer>
      <Content>
        <h1>Explore the best strategies</h1>
        <p className="paragraph-xl">
          There is a wide range available strategies in Enso. Find the best one from the community or even from your
          favorite creator.
        </p>
        <CardsContainer
          onClick={() => {
            setIsColumn(!isColumn)
          }}
          isColumn={isColumn}
        >
          {mockData.map((item, i) => {
            return isMobile ? (
              <StrategyCardSmall key={`${i}_${item.id}`} data={item} />
            ) : (
              <StrategyCard key={`${i}_${item.id}`} data={item} />
            )
          })}
        </CardsContainer>
        <SLink href="/explore/strategies/1">Explore Strategies</SLink>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 88px 0;
  position: relative;
  z-index: 2;

  & > h1 {
    margin-top: 30px;
  }

  & > p {
    margin-top: 32px;
  }

  & .paragraph-xl {
    margin-top: 48px;
    color: ${Colors.Neutral[300]};

    @media ${Device.mobile} {
      margin-top: 24px;
      width: 88%;
    }
  }

  & a {
    margin: 32px 0 0 0;
  }
`

const CardsContainerColumnCss = css`
  min-height: 440px;

  @media ${Device.mobile} {
    min-height: 860px;
  }

  & > div {
    @media ${Device.mobile} {
      transform: translate(-50%, 0) scale(1) !important;
    }
  }

  & > div:nth-child(1) {
    position: relative;
    z-index: 6;
  }
  & > div:nth-child(2) {
    position: absolute;
    top: 110px;
    width: 100%;
    z-index: 5;

    @media ${Device.mobile} {
      top: 220px;
    }
  }
  & > div:nth-child(3) {
    position: absolute;
    top: 220px;
    width: 100%;
    z-index: 4;

    @media ${Device.mobile} {
      top: 440px;
    }
  }
  & > div:nth-child(4) {
    position: absolute;
    top: 330px;
    width: 100%;
    z-index: 3;

    @media ${Device.mobile} {
      top: 660px;
    }
  }
`

const CardsContainer = styled.div<{ isColumn: boolean }>`
  width: 100%;
  position: relative;
  margin-top: 116px;
  transition: 2s;
  min-height: 0;

  & > div {
    left: 50%;
    transform: translate(-50%, 0);
    transition: 2s;
  }

  & > div:nth-child(1) {
    position: relative;
    z-index: 6;
  }
  & > div:nth-child(2) {
    position: absolute;
    top: -35px;
    width: 90%;
    z-index: 5;

    @media ${Device.mobile} {
      top: -45px;
      transform: translate(-50%, 0) scale(0.95);
    }
  }
  & > div:nth-child(3) {
    position: absolute;
    top: -55px;
    width: 80%;
    z-index: 4;

    @media ${Device.mobile} {
      top: -65px;
      transform: translate(-50%, 0) scale(0.85);
    }
  }
  & > div:nth-child(4) {
    position: absolute;
    top: -65px;
    width: 70%;
    z-index: 3;

    @media ${Device.mobile} {
      top: -75px;
      transform: translate(-50%, 0) scale(0.8);
    }
  }

  ${(props) => {
    if (props.isColumn) {
      return [CardsContainerColumnCss]
    }
  }};
`
