import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { Provider } from '@ethersproject/providers'
import { ChainId, MULTICALL_ABI, MULTICALL_ADDRESS } from '../../constants'
import { MulticallRequest } from './MulticallRequest'

export type MulticallOptions = {
  blockTag: number
}

export async function multicall(
  provider: Provider,
  chainId: ChainId,
  requests: MulticallRequest[],
  options?: MulticallOptions
): Promise<any[]> {
  const contract = new Contract(MULTICALL_ADDRESS[chainId], MULTICALL_ABI, provider)
  const calls = requests.map(({ address, data }) => [address, data])
  const [, returnData]: [BigNumber, string[]] = await contract.aggregate(calls, options ?? {})
  return returnData.map((result, i) => requests[i].decode(result))
}
