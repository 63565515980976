import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import {
  Animations,
  BorderRad,
  Colors,
  defaultModalHeight,
  Effects,
  Fonts,
  Overflow,
  Shadows,
  Transitions,
} from '../../constants'
import { useImageDominantColor } from '../../hooks/useImageDominantColor'
import { Close } from '../icons'
import { RowGapBlock } from '../page'
import { CommonImage } from '../page/CommonImage'
import { Button, ButtonInner, DefaultButton } from './Button'
import * as RadixDialog from '@radix-ui/react-dialog'
import { TokenIconFallback } from './TokenIconFallback'
import { FocusOutsideEvent, PointerDownOutsideEvent } from '@radix-ui/react-dismissable-layer'

export const TokenIcon = styled(TokenIconFallback)`
  margin-right: 8px;
  height: 100%;
  width: auto;
  max-height: 24px;
  border-radius: ${BorderRad.round};
  overflow: hidden;
`

interface Props {
  onClose: () => void
  title?: string
  children: ReactNode
  className?: string
  open: boolean
  backgroundColor?: string
  disableModal?: boolean
  onInteractOutsideContent?: (event: PointerDownOutsideEvent | FocusOutsideEvent) => void
}

export function Modal({
  onClose,
  title,
  children,
  className,
  open,
  backgroundColor,
  disableModal,
  onInteractOutsideContent = (e) => {},
}: Props) {
  return (
    <RadixDialog.Root open={open} onOpenChange={onClose} modal={disableModal !== true}>
      <RadixDialog.Portal>
        <Background />
        <PageContent className={className} background={backgroundColor} onInteractOutside={onInteractOutsideContent}>
          {title && <RadixDialog.Title>{title}</RadixDialog.Title>}
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
          {children}
        </PageContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}

export const PageContent = styled(RadixDialog.Content)<{
  background?: string
  level?: number
  onInteractOutside?: Function
}>`
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ background }) => background ?? Colors.White};
  width: 492px;
  height: fit-content;
  min-height: ${defaultModalHeight}px;
  max-height: min(85vh, 750px);
  border-radius: ${BorderRad.m};
  box-shadow: ${Shadows.modal};
  ${Animations.showModalBlock};
`

export const ModalFitHeight = styled(Modal)`
  height: fit-content;
`

export const ModalFixedHeight = styled(Modal)<{ height?: number }>`
  ${({ height }) =>
    height &&
    css`
      min-height: ${height}px;
      height: ${height}px;
    `};
`

export const Background = styled(RadixDialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  inset: 0;
  background-color: ${Colors.ModalGlass};
  backdrop-filter: ${Effects.ModalGlassBlur};
  ${Animations.showModalBackground};
`

export const Header = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-self: flex-start;
  align-items: center;
  width: fit-content;
  margin-bottom: 24px;
`

export const ModalTitle = styled.h3<{ defaultCase?: boolean }>`
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: ${Colors.Neutral[900]};
  text-transform: ${({ defaultCase }) => (defaultCase ? 'none' : 'capitalize')};
`

export const ModalDescription = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${Colors.Neutral[700]};
  text-align: center;
  max-width: 90%;
`

export const CloseButton = styled(DefaultButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  color: ${Colors.Neutral[900]};

  &:hover,
  &:focus {
    outline: none;
    color: ${Colors.Primary[500]};
  }
  &:active {
    color: ${Colors.Primary[600]};
  }
`

export const FormWrapper = styled.div<{ freeBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px 24px ${({ freeBottom }) => (freeBottom ? '0' : '32px')};
  min-height: 0;
`

export const FormWrapperYScrollable = styled(FormWrapper)`
  overflow-y: scroll;
`

export const ModalHeadPart = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% + 48px);
    height: 1px;
    align-self: center;
    background-color: ${Colors.Neutral[200]};
  }
`

export const Table = styled.div<{ borderless?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 100%;
  height: 100%;
  border-radius: ${BorderRad.l} ${BorderRad.l} 0 0;
  ${({ borderless }) =>
    !borderless &&
    css`
      border: 1px solid ${Colors.Neutral[200]};
      padding: 8px;
    `};
  border-bottom: none;
  z-index: 3;
`

export const TableNav = styled.nav`
  display: grid;
  grid-area: tablenav;
  grid-template-rows: 1fr;
  grid-template-columns: 3.2fr repeat(2, 3fr);
  grid-column-gap: 8px;
  padding: 0 16px 0 16px;
`

export const TableColumnTitle = styled(DefaultButton)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  font-size: 10px;
  line-height: 20px;
  font-family: ${Fonts.Inter};
  font-weight: 400;
  color: ${Colors.Neutral[700]};

  &:first-child {
    justify-content: start;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
`

export const TableBody = styled.ul`
  display: grid;
  grid-area: tablebody;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(52px, auto);
  align-items: start;
  height: fit-content;
  max-height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
`

export const TableRow = styled.li`
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  grid-template-columns: 3.2fr repeat(2, 3fr);
  grid-column-gap: 8px;
  justify-items: center;
  align-items: center;
  padding: 8px 16px 12px 16px;
  height: auto;
  min-height: 52px;
`

export const TableRowColumn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-feature-settings: 'tnum';

  &:first-child {
    justify-content: flex-start;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
`

export const TableFooter = styled.div<{ inProgressFooter?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${({ inProgressFooter }) => (inProgressFooter ? '32px' : '0')};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% + 48px);
    height: 1px;
    background-color: ${Colors.Neutral[200]};
    transform: translateX(-50%);
  }
`

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 100%;
  background-color: transparent;
  min-height: 0;
  border-radius: ${BorderRad.l};
  color: ${Colors.Neutral[900]};
  overflow: hidden;
  font-family: ${Fonts.DMSans};
`

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  border-top: 1px solid ${Colors.Neutral[200]};
  background-color: ${Colors.White};
`

export const TokenName = styled.span`
  font-weight: 700;
  color: ${Colors.Neutral[900]};
  text-transform: uppercase;
`

export const RowBottomLines = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background-color: ${Colors.MainBG};
`

interface RowBottomLineProps {
  percentage: string
  logoURI?: string
}

export function RowBottomLine({ percentage, logoURI }: RowBottomLineProps) {
  const color = useImageDominantColor(logoURI)
  return <BottomLine percentage={percentage} lineColor={color} />
}

const BottomLine = styled.div<RowBottomLineProps & { lineColor?: string }>`
  display: inline-flex;
  width: ${({ percentage }) => percentage};
  height: 100%;
  border-radius: 2px;
  background-color: ${({ lineColor }) => lineColor};
  transition: ${Transitions.all};
`

export const MaxAmount = styled(DefaultButton)`
  position: absolute;
  top: 115px;
  right: 124px;
  font-size: 14px;
  line-height: 14px;
  padding: 4px 8px;
  color: ${Colors.Neutral[900]};
  align-self: flex-start;
  text-decoration: underline;
  text-transform: uppercase;
`

export const ResultFormWrapper = styled(FormWrapper)<{ centered?: boolean }>`
  max-width: 492px;
  padding: 32px 24px 0;
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `};

  ${RowGapBlock} {
    justify-items: center;
    justify-content: center;
  }
`

export enum ResultImageSize {
  m = 'm',
  l = 'l',
}

export const ResultImageWrapper = styled.div<{ size?: ResultImageSize }>`
  width: ${(props) => {
    switch (props.size) {
      case ResultImageSize.l:
        return '140px'
      case ResultImageSize.m:
      default:
        return '100px'
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case ResultImageSize.l:
        return '140px'
      case ResultImageSize.m:
      default:
        return '100px'
    }
  }};
`

export const ResultImage = styled(CommonImage)`
  width: 100%;
  height: auto;
`

export const ResultTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
`

export const ResultItemButton = styled(Button)`
  min-width: unset;
  padding: 24px 16px;
  justify-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  ${ButtonInner} {
    grid-column-gap: 12px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    ${Overflow.FullDots};
    color: white;
  }
`
