import React, { useState } from 'react'
import styled from 'styled-components'

import { Tab } from './Tab'
import { Device } from '../../styles'

interface Tab {
  name: string
  title: string
}

type onChange = (tab: string) => void

interface Props {
  tabs: Array<Tab>
  onChange: onChange
}

export const Tabs = ({ tabs, onChange }: Props) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name)

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
    onChange(tab)
  }

  return (
    <TabsContainer>
      {tabs.map((tab, i) => (
        <Tab key={i} isActive={tab.name === activeTab} title={tab.title} name={tab.name} onChange={handleTabChange} />
      ))}
    </TabsContainer>
  )
}

const TabsContainer = styled.div`
  overflow-x: scroll;
  gap: 16px;
  height: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;

  @media ${Device.mobile} {
    gap: 24px;
    justify-content: start;
    margin: 0;
  }
`
