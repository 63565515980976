import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Colors, Shadows } from '../../styles'
import { ChartSvgM, UpIcon } from '../icons'
import Img from 'next/image'

interface Investor {
  avatar: string
}

enum StrategySmallCardVariants {
  default,
  short,
}

interface Strategy {
  children?: ReactNode
  id: number
  avatar: string
  title: string
  currency: string
  investors: {
    count: number
    data: Investor[]
  }
  tvl: string
  pctChange: string
}
interface Props {
  data: Strategy
  variant?: StrategySmallCardVariants
}

export const StrategyCardSmall = ({
  data: { id, title, currency, investors, tvl, pctChange, avatar },
  variant = StrategySmallCardVariants.default,
}: Props) => {
  return (
    <Container variant={variant}>
      <div className="row">
        <UserInfoContainer>
          <Avatar>
            <Img width={40} height={40} src={avatar} alt="Avatar" layout="fixed" />
          </Avatar>
          <div>
            <h5>{title}</h5>
            <p className="paragraph-md">{currency}</p>
          </div>
        </UserInfoContainer>

        <GraphWrapper>
          <ChartSvgM />
        </GraphWrapper>
      </div>

      {variant === StrategySmallCardVariants.default && (
        <div className="row statistic">
          <div>
            <p className="paragraph-sm">TVL</p>
            <div className="value tvl">{tvl}</div>
          </div>
          <div>
            <p className="paragraph-sm">24H Change</p>
            <div className="value pctChange">
              {pctChange} <UpIcon />
            </div>
          </div>
        </div>
      )}

      <div className="row statistic2">
        <div>
          <div className="flex_row flex_center">
            <div className="investorsWrapper">
              {investors.data.map((inv, i) => {
                return (
                  <ImageContainer key={`${i}_${id}`}>
                    <Img src={inv.avatar} alt="Avatar" layout="fill" />
                  </ImageContainer>
                )
              })}
            </div>
            <h5 className="investorsCount">
              +{investors.count} <span className="paragraph-sm">Investors</span>
            </h5>
          </div>
        </div>
        {variant === StrategySmallCardVariants.short && (
          <div className="value pctChange">
            {pctChange} <UpIcon />
          </div>
        )}
      </div>
    </Container>
  )
}

const ImageContainer = styled.div`
  position: relative;
`

const UserInfoContainer = styled.div`
  min-width: 110px;
  display: flex;
  justify-content: space-between;
  width: fit-content !important;

  & h5 {
    color: ${Colors.Neutral[400]};
  }
`

const GraphWrapper = styled.div``

const Avatar = styled.div`
  margin: 0 10px 0 0;
  filter: drop-shadow(3.35704px 6.71408px 7.38548px rgba(66, 57, 104, 0.1));
`

const SmallCardShortVariantCss = css`
  & .statistic2 {
    border-top: 1px solid #efe7ff;
    padding: 16px 0 0 0;
    margin: 12px 0 0 0;

    & > div {
      width: fit-content;
    }
  }

  & .row {
    & > div {
      width: fit-content;
    }
  }
`

const SmallCardDefaultVariantCss = css`
  ${GraphWrapper} {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fdfcff 95%);
    }
  }
`

const Container = styled.div<{ variant?: StrategySmallCardVariants }>`
  width: 100%;
  min-width: ${(props) => {
    switch (props.variant) {
      case StrategySmallCardVariants.short:
        return '350px'
      default:
        return '320px'
    }
  }};
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: ${Colors.Neutral[0]};
  padding: 24px 16px;
  box-shadow: ${Shadows.medium};
  border-radius: 16px;

  & .row {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 48%;
    }
  }

  & .statistic {
    margin: 24px 0 24px 0;
  }

  ${(props) => {
    switch (props.variant) {
      case StrategySmallCardVariants.short:
        return [SmallCardShortVariantCss]
      default:
        return [SmallCardDefaultVariantCss]
    }
  }};

  & .paragraph-md,
  .paragraph-sm,
  .investorsCount {
    color: ${Colors.Neutral[300]};
  }

  & .investorsCount {
    display: flex;
    width: 100%;
    align-items: center;

    & span {
      margin: 0 0 0 5px;
    }
  }

  & .investorsWrapper {
    display: flex;
    margin: 0 8px 0 0;
    position: relative;
    min-width: 48px;
    height: 24px;

    & ${ImageContainer} {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
    }

    & ${ImageContainer}:nth-child(1) {
      position: absolute;
      left: 0;
    }
    & ${ImageContainer}:nth-child(2) {
      position: absolute;
      left: 12px;
    }
    & ${ImageContainer}:nth-child(3) {
      position: absolute;
      left: 24px;
    }
  }

  & .value {
    margin-top: 4px;
  }

  & .userInfoContainer {
  }

  & .pctChange {
    color: ${Colors.Positive[900]};
    font-weight: 700;
    display: flex;
    align-items: center;

    & svg {
      margin: 0 0 0 8px;
    }
  }

  & .tvl {
    color: ${Colors.Neutral[500]};
    font-weight: 600;
  }
`
