import { ChainId, Ether, KovanEther, WEth } from '../constants'
import { CurrencyValue } from '../model'
import { useChainId } from './useChainId'
import { useEtherBalance, useEthers, useTokenBalance } from '@usedapp/core'
import { useMemo } from 'react'

export function useEthBalance() {
  const { chainId } = useChainId()
  const { account } = useEthers()
  const value = useEtherBalance(account)

  return useMemo(() => {
    if (!value) return undefined

    const currency = chainId === ChainId.Mainnet ? Ether : KovanEther
    return new CurrencyValue(currency, value)
  }, [value, chainId])
}

export const useWethBalance = () => {
  const { chainId } = useChainId()
  const { account } = useEthers()
  const value = useTokenBalance(WEth.address, account)

  return useMemo(() => {
    if (!value) return undefined

    const currency = chainId === ChainId.Mainnet ? WEth : KovanEther
    return new CurrencyValue(currency, value)
  }, [value, chainId])
}

export const useCombinedBalance = () => {
  const { chainId } = useChainId()
  const { account } = useEthers()
  const wethBalance = useTokenBalance(WEth.address, account)
  const ethBalance = useEtherBalance(account)

  return useMemo(() => {
    if (!ethBalance || !wethBalance) return undefined
    const value = ethBalance.add(wethBalance)
    if (!value) return undefined
    const currency = chainId === ChainId.Mainnet ? Ether : KovanEther
    return new CurrencyValue(currency, value)
  }, [chainId, ethBalance, wethBalance])
}
