import React, { ReactNode } from 'react'
import { Device, LandingGlobalStyles } from '../../styles'

import { NavbarLandingPage } from './Navbar'
import { Footer } from './Footer'
import styled from 'styled-components'
import { VampireClaimModal } from '../../../components/page/VampireClaim'

interface Props {
  children: ReactNode
  white?: boolean
}

export const LandingLayout = ({ children, white }: Props) => {
  return (
    <>
      <LayoutContainer white={white}>
        <LayoutContent>
          <LandingGlobalStyles />
          <NavbarLandingPage />
          {children}
          <Footer />
        </LayoutContent>
        <Background />
      </LayoutContainer>
    </>
  )
}

const LayoutContainer = styled.div<{ white?: boolean }>`
  position: relative;
  overflow: hidden;
  background: ${(props) => (props.white ? 'white' : 'none')}; ;
`
const Background = styled.div`
  width: 100%;
  height: calc(100% + 200px);
  position: absolute;
  top: -200px;
  z-index: 0;
  background: url('/images/landing/backgrounds/_bg.png');
  background-size: cover;

  @media ${Device.mobile} {
    background: url('/images/landing/backgrounds/_mbg.png');
    top: 0;
    height: 100%;
    background-size: cover;
  }
`

const LayoutContent = styled.div`
  position: relative;
  z-index: 1;
`
