import { ChainId } from '../constants'

export class Authentication {
  constructor(
    readonly account: string,
    readonly chainId: ChainId,
    readonly isAuthenticated: boolean,
    readonly jwt: string
  ) {
    this.account = account
    this.chainId = chainId
    this.isAuthenticated = isAuthenticated
    this.jwt = jwt
  }
}
