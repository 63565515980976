import React from 'react'
import styled from 'styled-components'

export function LSearchIcon({ color, className }: { color?: string; className?: string }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color || 'currentColor'}
      className={className}
    >
      <path
        d="M17.9968 16.9997L14.7773 13.7812"
        stroke="#A4A8CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="9.34788"
        cy="8.34759"
        rx="7.34983"
        ry="7.34759"
        stroke="#A4A8CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const SvgIcon = styled.svg`
  width: 148px;
  height: 41px;
  position: relative;
`
