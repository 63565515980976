import { httpApiClient } from '../httpClients/httpApiClient'
import { operations } from './rest/types'
import { useQuery } from 'react-query'
import { useEthers } from '@usedapp/core'
import { useMemo } from 'react'

type GetUserNonceResponse = operations['getUserNonce']['responses']['200']['content']['application/json']
type PromiseWithNullableString = Promise<null | string>

export const fetchJwtWithNonce = async (
  nonce: string,
  account: string,
  library: any,
  errCb?: () => void
): PromiseWithNullableString => {
  try {
    const message = `Welcome to Enso!
      Click to sign in and accept the Enso Terms of Service. This request will not trigger a blockchain transaction or cost any gas fees.
      Wallet address: ${account}
      Nonce: ${nonce}`
    const signedMessage = await library?.getSigner()?.signMessage(message)
    const jwt = await httpApiClient.post('users/auth', { message, signedMessage, nonce }).then(({ data }) => data)
    localStorage.setItem('auth' + '/' + account, jwt)
    return jwt
  } catch (e) {
    errCb && errCb()
    return null
  }
}

export const fetchNonce = async (account: string): PromiseWithNullableString => {
  const { nonce } = await httpApiClient
    .get<GetUserNonceResponse>(`users/nonce/${account?.toLowerCase()}`)
    .then(({ data }) => data)
  return nonce ?? null
}

export const useNonce = () => {
  const { account } = useEthers()
  const queryKey = ['nonce' + account]
  const response = useQuery(
    queryKey,
    () => {
      httpApiClient.get(`users/nonce/${account?.toLowerCase()}`)
    },
    { enabled: !!account }
  )
  return useMemo(() => response?.data ?? null, [response.data])
}
