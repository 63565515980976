import React, { ReactNode, useState } from 'react'
import { RequestAuthContext } from './context'

export function RequestAuthProvider({ children }: { children: ReactNode }) {
  const [shouldRequestSignature, setShouldRequestSignature] = useState(true)
  return (
    <RequestAuthContext.Provider value={[shouldRequestSignature, setShouldRequestSignature]}>
      {children}
    </RequestAuthContext.Provider>
  )
}
