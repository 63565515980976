import EnsoOracle from '../constants/v1.1-core/EnsoOracle.json'
import Strategy from '../constants/v1.1-core/Strategy.json'
import StrategyController from '../constants/v1.1-core/StrategyController.json'
import Factory from '../constants/v1.1-core/StrategyProxyFactory.json'
import TokenRegistry from '../constants/v1.1-core/TokenRegistry.json'
import CurveDepositZapRegistry from '../constants/v1.1-core/CurveDepositZapRegistry.json'
import UniswapV3Registry from '../constants/v1.1-core/UniswapV3Registry.json'

import OldEnsoOracle from '../constants/v1-core/EnsoOracle.json'
import OldStrategy from '../constants/v1-core/Strategy.json'
import OldStrategyController from '../constants/v1-core/StrategyController.json'
import OldFactory from '../constants/v1-core/StrategyProxyFactory.json'
import OldTokenRegistry from '../constants/v1-core/TokenRegistry.json'
import OldCurveDepositZapRegistry from '../constants/v1-core/CurveDepositZapRegistry.json'
import OldUniswapV3Registry from '../constants/v1-core/UniswapV3Registry.json'

import { useEthers } from '@usedapp/core'
import { Contract } from 'ethers'
import { useMemo } from 'react'
import { useConfig } from './useConfig'
import { ZERO_ADDRESS } from 'src/constants'
import { PlatformVersion, usePlatformVersion } from './usePlatformVersion'

const makeContractSafe = (targetAddress: string, abi: any, signer: any) => {
  return new Contract(targetAddress.length ? targetAddress : ZERO_ADDRESS, abi, signer)
}

export function useContracts() {
  const config = useConfig()
  const { library } = useEthers()
  const platformVersion = usePlatformVersion()

  const isOld = platformVersion === PlatformVersion.OLD

  const strategyController = useMemo(
    () =>
      makeContractSafe(
        config.strategyControllerAddress,
        isOld ? OldStrategyController.abi : StrategyController.abi,
        library?.getSigner()
      ),
    [config.strategyControllerAddress, isOld, library]
  )
  const strategyProxyFactory = useMemo(
    () =>
      makeContractSafe(config.strategyProxyFactoryAddress, isOld ? OldFactory.abi : Factory.abi, library?.getSigner()),
    [config, library, isOld]
  )
  const oracle = useMemo(
    () => makeContractSafe(config.oracleAddress, isOld ? OldEnsoOracle.abi : EnsoOracle.abi, library?.getSigner()),
    [config, library, isOld]
  )
  const tokenRegistry = useMemo(
    () => makeContractSafe(config.tokenRegistry, isOld ? OldTokenRegistry.abi : TokenRegistry.abi, library),
    [config, library, isOld]
  )
  const curveDepositZapRegistry = useMemo(
    () =>
      makeContractSafe(
        config.curveDepositZapRegistry,
        isOld ? OldCurveDepositZapRegistry.abi : CurveDepositZapRegistry.abi,
        library
      ),
    [config, library, isOld]
  )
  const uniswapV3Registry = useMemo(
    () => makeContractSafe(config.uniswapV3Registry, isOld ? OldUniswapV3Registry.abi : UniswapV3Registry.abi, library),
    [config, library, isOld]
  )

  return {
    strategyController,
    strategyProxyFactory,
    oracle,
    tokenRegistry,
    uniswapV3Registry,
    curveDepositZapRegistry,
  }
}

export const useStrategyContract = (strategyAddress: string) => {
  const { library } = useEthers()
  const platformVersion = usePlatformVersion()

  return useMemo(
    () =>
      new Contract(
        strategyAddress,
        platformVersion === PlatformVersion.OLD ? OldStrategy.abi : Strategy.abi,
        library?.getSigner()
      ),
    [library, platformVersion, strategyAddress]
  )
}
