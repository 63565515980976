import React, { useRef } from 'react'
import styled from 'styled-components'

import {
  ContentContainer,
  SimpleCardLayout,
  InvestInAnimation,
  EarnFromCreationsAnimation,
  SaveMoneyAnimation,
} from '../base'
import { useListElementOnScreen } from 'src/hooks/useListElementOnScreen'
import { CreateYourStrategyAnimation } from '../base/animations/CreateYourStrategyAnimation'

import { Colors, Device, Fonts } from '../../styles'
import CompleteWithOthersAnimation from '../base/animations/CompleteWithOthers'

export const AdvantagesSection = () => {
  const createYourStrategyAnimationRef = useRef(null)
  const investInAnimationRef = useRef(null)
  const earnFromCreationsAnimationRef = useRef(null)
  const saveMoneyAnimationRef = useRef(null)
  const completeWithOthersRef = useRef(null)

  const isIntersectingCreateYourStrategy = useListElementOnScreen(createYourStrategyAnimationRef)
  const isIntersectingInvestIn = useListElementOnScreen(investInAnimationRef)
  const isIntersectingEearnFromCreation = useListElementOnScreen(earnFromCreationsAnimationRef)
  const isIntersectingSaveMoney = useListElementOnScreen(saveMoneyAnimationRef)
  const isCompleteWithOthers = useListElementOnScreen(completeWithOthersRef)

  return (
    <ContentContainer>
      <Content>
        <RawWrapper>
          <SimpleCardLayout
            title="Create your strategy"
            subtitle="Choose between single assets, yield farming or even other nested strategies."
            ref={createYourStrategyAnimationRef}
          >
            {isIntersectingCreateYourStrategy && <CreateYourStrategyAnimation />}
          </SimpleCardLayout>
          <SimpleCardLayout
            title="Invest in alpha strategies"
            subtitle="Invest in anything from hidden gems to high yield blue chip strategies."
            ref={investInAnimationRef}
          >
            {isIntersectingInvestIn && <InvestInAnimation />}
          </SimpleCardLayout>
        </RawWrapper>
        <SimpleCardLayout
          title="Compete with others"
          subtitle="Compete for the best performing DeFi strategies, and win prizes while you do so."
          size={1}
          ref={completeWithOthersRef}
        >
          {isCompleteWithOthers && <CompleteWithOthersAnimation />}
        </SimpleCardLayout>
        <RawWrapper>
          <SimpleCardLayout
            title="Earn from your creations"
            subtitle="Generate performance fees on your strategy."
            ref={earnFromCreationsAnimationRef}
          >
            {isIntersectingEearnFromCreation && <EarnFromCreationsAnimation />}
          </SimpleCardLayout>
          <SimpleCardLayout
            title="Save money on gas"
            subtitle="upto 40% reduction in gas by bundling multiple transactions into one."
            ref={saveMoneyAnimationRef}
          >
            {isIntersectingSaveMoney && <SaveMoneyAnimation />}
          </SimpleCardLayout>
        </RawWrapper>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 88px 0;
  position: relative;
  z-index: 2;

  @media ${Device.mobile} {
    padding: 80px 0;
    gap: 32px;
  }
`

const RawWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media ${Device.mobile} {
    flex-direction: column;
    gap: 32px;
  }

  & > div {
    width: 50%;

    @media ${Device.mobile} {
      width: 100%;
    }
  }
`

const ThirdCardContent = styled.div`
  background: url('/images/landing/backgrounds/complete.png') no-repeat;
  width: 100%;
  height: 100%;
  top: 150px;
  left: 0;
  position: absolute;

  @media ${Device.mobile} {
    background: url('/images/landing/backgrounds/complete_mobile.png') no-repeat;
    top: 180px;
    background-position: top center;
  }
`

const FifthCardContent = styled.div`
  & .prices {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin: 70px 0 36px 0;
  }

  & .price {
    color: ${Colors.Positive[900]};
    font-family: ${Fonts.secondary};
    font-weight: 700;
    font-size: 40px;
    line-height: 32px;
  }

  & .price__old {
    color: ${Colors.Neutral[200]};
    font-family: ${Fonts.secondary};
    font-weight: 700;
    font-size: 24px;
    margin: 8px 0 0 0;
    opacity: 0.3;
    position: relative;

    &::before {
      content: '';
      width: 56px;
      height: 1px;
      background: ${Colors.Neutral[200]};
      display: block;
      position: absolute;
      left: 50%;
      top: 8px;
      transform: translate(-50%, 0);
    }
  }
`

const ImageContainer = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  bottom: 20px;
  left: 50%;
  width: 100%;
  height: auto;
  min-height: 130px;
`
