import Big, { BigSource } from 'big.js'
import { BigNumber } from 'ethers'
import numbro from 'numbro'
import { truncateDecimals } from '../utils'

export interface FormatNumberOptions {
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  thousandSeparated?: boolean
  thousandSeparatedThreshold?: number
  forceSign?: boolean
  averageThreshold?: number
  symbol?: {
    text: string
    position?: 'prefix' | 'postfix'
    spaceSeparated?: boolean
  }
}

export function formatNumber(
  num: BigSource,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = minimumFractionDigits + 2,
    forceSign = false,
    averageThreshold = 6,
    symbol,
    thousandSeparated = false,
    thousandSeparatedThreshold = 6,
  }: FormatNumberOptions = {}
) {
  const defaultOptions: numbro.Format = {
    thousandSeparated,
    trimMantissa: true,
    currencySymbol: symbol?.text ?? '',
    output: symbol ? ('currency' as const) : ('number' as const),
    currencyPosition: symbol?.position ?? 'prefix',
    forceSign,
    spaceSeparated: symbol?.spaceSeparated ?? false,
  }

  const minFractionDigits = Math.min(minimumFractionDigits, maximumFractionDigits)
  const maxFractionDigits = Math.max(minimumFractionDigits, maximumFractionDigits)
  const toFormat = (num ?? 0).toString()
  const absNumValue = Big(toFormat).abs()
  const lowerBoundFraction = Big(1).div(maxFractionDigits ? 10 ** maxFractionDigits : 1)
  const upperBoundFraction = Big(1).div(minFractionDigits ? 10 ** minFractionDigits : 1)

  if (absNumValue.eq('0')) {
    return numbro('0').format({
      ...defaultOptions,
      mantissa: minFractionDigits,
      trimMantissa: false,
    })
  }

  if (absNumValue.lt(lowerBoundFraction)) {
    const minimalValueString = `0.${'0'.repeat(maxFractionDigits ? maxFractionDigits - 1 : 0)}1`
    if (symbol) {
      return symbol.position === 'prefix'
        ? '< ' + symbol.text + minimalValueString
        : '< ' + minimalValueString + symbol.text
    }
    return '< ' + minimalValueString
  }

  if (absNumValue.lt(upperBoundFraction)) {
    return numbro(toFormat).format({ ...defaultOptions, mantissa: maxFractionDigits })
  }

  if (absNumValue.gte('1e+15')) {
    if (symbol) {
      return symbol.position === 'prefix' ? '> ' + symbol.text + '999t' : '> ' + '999t' + symbol.text
    }
    return '> 999t'
  }

  if (absNumValue.gt(`1e+${averageThreshold}`)) {
    return numbro(toFormat).format({ ...defaultOptions, average: true, totalLength: 3, thousandSeparated: false })
  }

  const fractionPart = Big(toFormat).round(maxFractionDigits).mod(1)
  const fractionPartLength = fractionPart.eq(0) ? 0 : fractionPart.valueOf().split('.')[1].length
  const mantissa = fractionPartLength > minFractionDigits ? maxFractionDigits : minFractionDigits

  return numbro(toFormat).format({
    ...defaultOptions,
    mantissa,
    trimMantissa: fractionPartLength > minFractionDigits,
    thousandSeparated: thousandSeparated || absNumValue.gt(`1e+${thousandSeparatedThreshold}`),
  })
}

export function formatDecimalOffset(value: string, decimalsToTruncate: number = 8) {
  return BigNumber.from(truncateDecimals(value, 0)).div(Math.pow(10, decimalsToTruncate)).toString()
}
