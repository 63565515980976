import React from 'react'
import styled from 'styled-components'

interface NotificationIconProps {
  color?: string
  className?: string
}

export function NotificationIcon({ color, className }: NotificationIconProps) {
  return (
    <JoystickIconSvg viewBox="0 0 18 23" fill={color || 'currentColor'} className={className}>
      <g clipPath="url(#clip0_1145_137143)">
        <path
          d="M17.7834 16.2625L16.5306 14.3054C16.4067 14.111 16.3338 13.8888 16.3187 13.6592L15.8857 7.09126C15.7965 5.39762 15.0904 3.79433 13.8993 2.58127C12.7082 1.36821 11.1138 0.62847 9.41439 0.500422C7.59489 0.397443 5.80819 1.01401 4.44342 2.21585C3.07864 3.41768 2.24635 5.10743 2.12779 6.91709L1.6672 13.6729C1.65204 13.9026 1.57919 14.1248 1.45533 14.3192L0.216331 16.2625C0.0834924 16.4703 0.00919626 16.7099 0.00120817 16.956C-0.00677992 17.2022 0.0518333 17.446 0.170921 17.662C0.290009 17.8779 0.465199 18.058 0.678179 18.1835C0.891159 18.309 1.13411 18.3753 1.38163 18.3754H16.6135C16.8614 18.3761 17.1049 18.3105 17.3186 18.1853C17.5322 18.0601 17.708 17.88 17.8277 17.664C17.9473 17.4479 18.0063 17.2038 17.9985 16.9572C17.9907 16.7106 17.9164 16.4707 17.7834 16.2625V16.2625Z"
          fill="#6A79FF"
        />
        <path
          d="M10.5935 19.75C10.7543 19.7518 10.9119 19.7954 11.0505 19.8766C11.1892 19.9577 11.304 20.0736 11.3837 20.2127C11.4633 20.3517 11.505 20.5091 11.5045 20.6692C11.5041 20.8292 11.4615 20.9864 11.3811 21.125C11.137 21.5375 10.7889 21.8794 10.3712 22.1169C9.95361 22.3545 9.48089 22.4794 8.99983 22.4794C8.51878 22.4794 8.04606 22.3545 7.62842 22.1169C7.21078 21.8794 6.86269 21.5375 6.61857 21.125C6.53813 20.9864 6.49557 20.8292 6.49512 20.6692C6.49467 20.5091 6.53634 20.3517 6.616 20.2127C6.69565 20.0736 6.81051 19.9577 6.94914 19.8766C7.08777 19.7954 7.24534 19.7518 7.40618 19.75H10.5935Z"
          fill="#6A79FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1145_137143">
          <rect width="18" height="22" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </JoystickIconSvg>
  )
}

export const JoystickIconSvg = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
