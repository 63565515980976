import React from 'react'
import styled from 'styled-components'

export function LinkedIcon({ className }: { color?: string; className?: string }) {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64654 4.04382C1.38948 4.04382 0.549805 3.17777 0.549805 2.02191C0.549805 0.866054 1.38785 0 2.78513 0C4.0422 0 4.88184 0.866054 4.88184 2.02191C4.88184 3.17777 4.04383 4.04382 2.64654 4.04382ZM4.77668 18H0.663086V6.20557H4.77668V18ZM13.4687 18H17.4503V11.5535C17.4503 7.8517 15.8574 6.20557 13.8666 6.20557C11.8758 6.20557 10.8144 6.8913 10.2845 7.8517V6.20557H6.1709V18H10.2845V11.5535C10.2845 9.63432 11.6117 9.35974 12.0095 9.35974C12.4073 9.35974 13.4687 9.7708 13.4687 11.5535V18Z"
        fill="url(#paint0_radial_1339_83274)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1339_83274"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.00671 -3.82499) rotate(80.2017) scale(52.2876 23.2617)"
        >
          <stop stopColor="#9985FF" />
          <stop offset="0.531506" stopColor="#FF67F9" stopOpacity="0.88" />
          <stop offset="0.911307" stopColor="#FFA857" stopOpacity="0.88" />
        </radialGradient>
      </defs>
    </SvgIcon>
  )
}

const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  position: relative;
`
