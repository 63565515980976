import React from 'react'
import styled from 'styled-components'

export function CheckIcon({
  color,
  className,
  inherit,
  size,
}: {
  color?: string
  className?: string
  inherit?: boolean
  size?: number
}) {
  return (
    <Check
      viewBox="0 0 14 12"
      preserveAspectRatio="xMidYMid meet"
      color={color ?? 'white'}
      className={className}
      inherit={inherit}
      size={size}
    >
      <path
        d="M5.44444 8.36873L1.55556 3.75334L0 5.5995L5.44444 12.061L14 1.90719L12.4444 0.0610352L5.44444 8.36873Z"
        fill="currentColor"
        stroke="none"
      />
    </Check>
  )
}

export const Check = styled.svg<{ inherit?: boolean; size?: number }>`
  width: ${({ size }) => (size ? `${size}px` : `100%;`)};
  height: ${({ size }) => (size ? `${size}px` : `100%;`)};
  ${({ inherit }) => inherit && 'width: inherit;height: inherit;'}
  min-width: 14px;
  min-height: 14px;
`
