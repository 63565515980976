import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../constants'

interface SocialIconProps {
  className?: string
  size?: number
  color?: string
}

export function DiscordSecondaryIcon({ className, size, color }: SocialIconProps) {
  return (
    <SocialIcon viewBox={`0 0 16 16`} fill={color ?? 'white'} className={className} size={size}>
      <path
        d="M12.3636 5C12.3636 5 11.1132 4.103 9.63636 4L9.50327 4.244C10.8385 4.5435 11.4511 4.97275 12.0909 5.5C10.9877 4.98375 9.89845 4.5 8 4.5C6.10155 4.5 5.01227 4.98375 3.90909 5.5C4.54891 4.97275 5.27764 4.49625 6.49673 4.244L6.36364 4C4.81427 4.13425 3.63636 5 3.63636 5C3.63636 5 2.23973 6.85625 2 10.5C3.40782 11.9883 5.54545 12 5.54545 12L5.99245 11.4537C5.23373 11.212 4.37682 10.7803 3.63636 10C4.51945 10.6125 5.85227 11.25 8 11.25C10.1477 11.25 11.4805 10.6125 12.3636 10C11.6232 10.7803 10.7663 11.212 10.0075 11.4537L10.4545 12C10.4545 12 12.5922 11.9883 14 10.5C13.7603 6.85625 12.3636 5 12.3636 5ZM6.22727 9.5C5.70009 9.5 5.27273 9.05225 5.27273 8.5C5.27273 7.94775 5.70009 7.5 6.22727 7.5C6.75445 7.5 7.18182 7.94775 7.18182 8.5C7.18182 9.05225 6.75445 9.5 6.22727 9.5ZM9.77273 9.5C9.24555 9.5 8.81818 9.05225 8.81818 8.5C8.81818 7.94775 9.24555 7.5 9.77273 7.5C10.2999 7.5 10.7273 7.94775 10.7273 8.5C10.7273 9.05225 10.2999 9.5 9.77273 9.5Z"
        fill={color ?? 'white'}
      />
    </SocialIcon>
  )
}

const SocialIcon = styled.svg<SocialIconProps>`
  width: ${({ size }) => size ?? 16}px;
  height: ${({ size }) => size ?? 16}px;
  background: ${Colors.DiscordPurple};
  border-radius: 50%;
`
