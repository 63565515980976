import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Animations, Colors, Transitions } from '../../constants'
import { CHAIN_NAMES, isTestNetwork } from '../../constants/chainId'
import { useEthBalance } from '../../hooks'
import { useEthers } from '@usedapp/core'
import { shortenAddress } from '../../misc/utils'
import { Button, ButtonSize, ButtonVariant } from '../base/Button'
import { JazzIcon } from '../icons'
import { Arrow } from '../icons/Arrow'
import { AccountMenu } from './AccountMenu'

interface AccountProps extends AccountCollapsingProps {
  address: string
}

interface AccountCollapsingProps {
  isCollapsed?: boolean
  open?: boolean
  className?: string
}

export function Account({ address, isCollapsed, className }: AccountProps) {
  const { chainId } = useEthers()
  const ethBalance = useEthBalance()
  const [open, setOpen] = useState(false)

  return (
    <Container isCollapsed={isCollapsed} className={className}>
      <BalanceAccount
        variant={ButtonVariant.secondary}
        size={ButtonSize.l}
        isCollapsed={isCollapsed}
        onClick={() => setOpen(!open)}
        open={open}
      >
        <AccountAvatar address={address} size={32} isCollapsed={isCollapsed} />
        <AccountInfo>
          <Address isCollapsed={isCollapsed}>{shortenAddress(address)}</Address>
          <BalanceArea isCollapsed={isCollapsed}>
            {ethBalance && (
              <>
                <BalanceValue>{`${Number(ethBalance).toFixed(2)}`}</BalanceValue>
                <BalanceAreaCurrency>{ethBalance.currency.symbol}</BalanceAreaCurrency>
              </>
            )}
          </BalanceArea>
        </AccountInfo>
        <AccountArrow open={open} isCollapsed={isCollapsed} />
        {open && <AccountMenu isCollapsed={isCollapsed} address={address} />}
      </BalanceAccount>
      {chainId && isTestNetwork(chainId) && <NetworkName isCollapsed={isCollapsed}>{CHAIN_NAMES[chainId]}</NetworkName>}
    </Container>
  )
}

const HideAccountItems = css`
  opacity: 0;
  transform: scale(0);
  transition: ${Transitions.all};
`

const Container = styled.div<AccountCollapsingProps>`
  display: grid;
  grid-area: navaccount;
  grid-template-rows: 48px 16px;
  grid-template-areas:
    'balanceaccount'
    'network';
  grid-row-gap: 8px;
  width: 100%;
`

const NetworkName = styled.span<AccountCollapsingProps>`
  display: inline-flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  grid-area: network;
  width: 100%;
  max-width: 175px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${Colors.Warning[900]};
  ${({ isCollapsed }) => (isCollapsed ? HideAccountItems : null)};
  transition: ${Transitions.all};
  transition-delay: ${({ isCollapsed }) => (isCollapsed ? '0s' : Transitions.duration)};
  overflow: hidden;
  ${Animations.showSidebarStuff};
`

const AccountAvatar = styled(JazzIcon)<AccountCollapsingProps>`
  transform: ${({ isCollapsed }) => (isCollapsed ? 'translateX(-8px) scale(0.625)' : 'translateX(0px) scale(1)')};
  transition: ${Transitions.all};
`

const AccountArrow = styled(Arrow)<{ open?: boolean; isCollapsed?: boolean }>`
  position: absolute;
  right: ${({ isCollapsed }) => (isCollapsed ? '0px' : '8px')};
  width: 24px;
  height: 24px;
  color: inherit;
  transform: scaleY(${({ open }) => (open ? '-1' : '1')});
  transition: ${Transitions.all}, color 0s ease;
`

const BalanceAccount = styled(Button)<AccountCollapsingProps>`
  display: grid;
  position: relative;
  grid-column-gap: unset;
  justify-content: start;
  justify-items: start;
  align-items: center;
  width: 100%;
  min-width: unset;
  grid-area: balanceaccount;
  padding: 4px 8px;
  text-align: left;
`

const AccountInfo = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 16px 16px;
  width: 100%;
`

const Address = styled.span<AccountCollapsingProps>`
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${Colors.Neutral[900]};
  ${({ isCollapsed }) => (isCollapsed ? HideAccountItems : null)};
  overflow: hidden;
  ${Animations.showSidebarStuff};
`

const BalanceArea = styled.div<AccountCollapsingProps>`
  display: flex;
  position: absolute;
  left: ${({ isCollapsed }) => (isCollapsed ? '-40px' : '8px')};
  bottom: ${({ isCollapsed }) => (isCollapsed ? '-32px' : '0px')};
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  transition: ${Transitions.all};
`

const BalanceValue = styled.span<AccountCollapsingProps>`
  color: ${Colors.Neutral[700]};
  font-feature-settings: 'tnum';
  overflow: hidden;
`

const BalanceAreaCurrency = styled.span<AccountCollapsingProps>`
  color: ${Colors.Neutral[900]};
  margin-left: 2px;
  overflow: hidden;
`
