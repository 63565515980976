import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useEthers } from '@usedapp/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useRef, useState } from 'react'
import { useEthBalance } from '../../hooks'
import { formatNumber } from '../../misc/helpers/number'
import styled, { css } from 'styled-components'
import { UrlObject } from 'url'
import { BorderRad, Colors, Shadows, Transitions } from '../../constants'
import { Button, ButtonInner, ButtonsGroup, ButtonSize, ButtonVariant } from '../base/Button'
import { WalletAvatar } from '../base/WalletAvatar'
import { LinkButton, LinkButtonComponent } from '../base/LinkButton'
// import { GlobalSearch } from '../GlobalSearch/GlobalSearch'
import { DisconnectIcon, EditIcon } from '../icons'
import { HorizontalDots } from '../icons/HorizontalDots'
import { DiscordIcon } from '../icons/navbar/DiscordIcon'
import { MediumIcon } from '../icons/navbar/MediumIcon'
import { TelegramIcon } from '../icons/navbar/TelegramIcon'
import { TwitterIcon } from '../icons/navbar/TwitterIcon'
import { SelectWalletModal } from '../top/SelectWalletModal'
import { ENVIRONMENT_CONFIG } from '../../../config/environment'
import { useAuthentication } from '../../providers/authentication/context'
import { LightningBoltIcon } from '../icons/LightningBoltIcon'
import { JoystickIcon } from '../icons/JoystickIcon'
import { NotificationIcon } from '../icons/Notification'
import { useUserDetails } from '../pages/profile/useUserDetails'
import { VampireClaim } from './VampireClaim'
import * as newNavbar from '../../newDesign/components/sections/Navbar'
import { DiscordSecondaryIcon } from '../icons/DiscordSecondaryIcon'
const { ARCADE_URL } = ENVIRONMENT_CONFIG

export const urls: Array<{ displayName: string; url: UrlObject; blank?: boolean }> = [
  { displayName: 'Team', url: { pathname: '/team' } },
  { displayName: 'Github', url: { pathname: 'https://github.com/EnsoFinance/' }, blank: true },
  { displayName: 'Medium', url: { pathname: 'https://medium.com/ensofinance/' }, blank: true },
  { displayName: 'Twitter', url: { pathname: 'https://twitter.com/EnsoFinance/' }, blank: true },
  { displayName: 'Discord', url: { pathname: 'https://discord.gg/enso-finance' }, blank: true },
  { displayName: 'Manifesto', url: { pathname: 'https://manifesto.enso.finance/' }, blank: true },
  { displayName: 'Docs', url: { pathname: 'https://docs.enso.finance/' }, blank: true },
  { displayName: 'Terms', url: { pathname: '/terms' } },
  { displayName: 'Privacy Policy', url: { pathname: '/privacy-policy' } },
]

export const socialLinks: Array<{ displayIcon: React.ReactElement; url: UrlObject; blank?: boolean }> = [
  { displayIcon: <TwitterIcon />, url: { pathname: 'https://twitter.com/EnsoFinance' } },
  { displayIcon: <DiscordIcon />, url: { pathname: 'https://discord.gg/enso-finance' } },
  { displayIcon: <MediumIcon />, url: { pathname: 'https://medium.com/ensofinance' } },
]

export const infoMenuContent: Array<{ displayText: string; href: string; blank?: boolean }> = [
  { displayText: 'Team', href: '/team' },
  { displayText: 'Github', href: 'https://github.com/EnsoFinance/', blank: true },
  { displayText: 'Medium', href: 'https://medium.com/ensofinance/', blank: true },
  { displayText: 'Twitter', href: 'https://twitter.com/EnsoFinance/', blank: true },
  { displayText: 'Discord', href: 'https://discord.gg/enso-finance', blank: true },
  { displayText: 'Manifesto', href: 'https://manifesto.enso.finance/', blank: true },
  { displayText: 'Docs', href: 'https://docs.enso.finance/', blank: true },
  { displayText: 'Terms', href: '/terms' },
  { displayText: 'Privacy Policy', href: '/privacy-policy' },
]

export const Navbar = () => {
  const { account } = useEthers()
  const [auth] = useAuthentication()
  const href = account && auth ? '/profile/' + account : '/'

  return <newNavbar.NavbarMainApp />
}

export const LandingButtons = () => {
  return (
    <ButtonsContainer>
      {/* <ArcadeButton /> */}
      <Socials />
    </ButtonsContainer>
  )
}

export const ExploreButton = () => {
  const { pathname } = useRouter()
  const href =
    pathname === '/explore/strategies/[...params]'
      ? { pathname: '/explore/creators/[...params]', query: { params: ['0'] } }
      : { pathname: '/explore/strategies/[...params]', query: { params: ['0'] } }

  return (
    <Link href={href} passHref>
      <NavbarButtonLink>
        <LightningBoltIcon color={Colors.Primary[500]} /> Explore
      </NavbarButtonLink>
    </Link>
  )
}

export const TournamentButton = () => {
  return (
    <NavbarButtonText>
      <JoystickIcon color={Colors.Primary[500]} /> Compete
      <OverlayText>Soon</OverlayText>
    </NavbarButtonText>
  )
}

export const UserButtons = () => {
  return (
    <ButtonsContainer>
      <VampireClaim />
      <Connection />
    </ButtonsContainer>
  )
}

export const NotificationButtons = () => {
  return (
    <ButtonsContainer>
      <LinkButton
        href={'/'}
        variant={ButtonVariant.whiteOutlined}
        icon={<NotificationIcon />}
        iconOnly
        size={ButtonSize.m}
      />
    </ButtonsContainer>
  )
}

export const ArcadeButton = () => {
  return (
    <StyledArcardeButton
      variant={ButtonVariant.primary}
      onClick={() => {
        window.open(ARCADE_URL, '_blank')
      }}
    >
      Go to Arcade
    </StyledArcardeButton>
  )
}

export const Connection = () => {
  const { account, deactivate } = useEthers()
  const { user } = useUserDetails(account ?? '')
  const balance = useEthBalance()
  const [isSelectWalletActive, setSelectWalletActive] = useState(false)
  const [, clear] = useAuthentication()

  const disconnect = useCallback(() => {
    deactivate()
    clear()
  }, [clear, deactivate])

  const accountConnectButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      {account ? (
        <PopoverContainer modal>
          <DropdownMenu.Trigger asChild>
            <AccountConnectButton variant={ButtonVariant.white} ref={accountConnectButtonRef}>
              {balance
                ? formatNumber(balance.toString(), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                  }) +
                  ' ' +
                  balance.currency.symbol
                : '0 ETH'}
              <AccountImageSquare>
                <WalletAvatar id={account.toLowerCase()} size={24} image={user?.profileImage} />
              </AccountImageSquare>
            </AccountConnectButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content asChild sideOffset={4} side="bottom" align="end">
            <AccountPanel minWidth={accountConnectButtonRef.current?.clientWidth}>
              <Link href={`/profile/${account.toLowerCase()}`} passHref>
                <PopoverItem>
                  <PopoverIcon>
                    <EditIcon />
                  </PopoverIcon>
                  My Profile
                </PopoverItem>
              </Link>
              <Link href="/early-access#early-access-discord" passHref>
                <PopoverItem as={Button} variant={ButtonVariant.link}>
                  <PopoverIcon>
                    <DiscordSecondaryIcon />
                  </PopoverIcon>
                  Early Access
                </PopoverItem>
              </Link>
              <PopoverItem as={Button} variant={ButtonVariant.link} onClick={disconnect}>
                <PopoverIcon>
                  <DisconnectIcon />
                </PopoverIcon>
                Disconnect
              </PopoverItem>
            </AccountPanel>
          </DropdownMenu.Content>
        </PopoverContainer>
      ) : (
        <NoAccountConnectButton onClick={() => setSelectWalletActive(true)} variant={ButtonVariant.whiteOutlined}>
          Connect
        </NoAccountConnectButton>
      )}
      <SelectWalletModal open={isSelectWalletActive} onClose={() => setSelectWalletActive(false)} />
    </>
  )
}

export const Socials = () => {
  return (
    <PopoverContainer modal={false}>
      <DropdownMenu.Trigger asChild>
        <Button variant={ButtonVariant.white} icon={<HorizontalDots />} iconOnly size={ButtonSize.m} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content asChild sideOffset={4} side="bottom" align="end">
        <SocialsPanel>
          {infoMenuContent.map(({ displayText, href, blank }) => (
            <PopoverLink
              key={displayText}
              href={href}
              rel={blank ? 'noreferrer' : undefined}
              target={blank ? '_blank' : undefined}
            >
              {displayText}
            </PopoverLink>
          ))}

          <SocialsPopoverContainer>
            {socialLinks.map(({ displayIcon, url }, index) => (
              <PopoverLink key={index} href={url.pathname ?? ''} rel="noreferrer" target="_blank">
                {displayIcon}
              </PopoverLink>
            ))}
          </SocialsPopoverContainer>
        </SocialsPanel>
      </DropdownMenu.Content>
    </PopoverContainer>
  )
}

export const NavbarContainer = styled.nav`
  position: sticky;
  top: 0;
  width: 100%;
  display: grid;
  background: #fcfcfc;
  grid-template-columns: 400px 1fr 360px;
  justify-content: space-between;
  grid-column-gap: 32px;
  align-items: center;
  height: fit-content;
  padding: 0 32px;
  border-bottom: 1px solid ${Colors.ButtonBorder};
  z-index: 999;
  margin-bottom: 16px;
`

export const NavButtonsContainer = styled.nav`
  display: flex;
  height: 100%;
  padding: 18px 0;
  align-items: center;
`

interface LinkComponentElementProps {
  disabled?: boolean
}

export const OverlayText = styled.div`
  position: absolute;
  top: -12px;
  right: -16px;
  font-size: 10px;
  color: ${Colors.Warning[800]};
  font-weight: 700;
`

export const NavbarButtonText = styled.div<LinkComponentElementProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 24px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  transition: ${Transitions.all};
  z-index: 20;

  color: #a09fac;

  > :first-child {
    margin-right: 6px;
    opacity: 0.5;
  }

  cursor: no-drop;
`

export const NavbarButtonLink = styled(NavbarButtonText)`
  opacity: 1;
  cursor: pointer;

  > :first-child {
    margin-right: 6px;
    opacity: 1;
  }

  &,
  &:visited {
    color: ${Colors.Neutral[900]};
    -webkit-text-stroke-color: transparent;
  }

  &:hover,
  &:focus {
    color: ${Colors.Primary[500]};
  }
`

export const ButtonsContainer = styled(ButtonsGroup)`
  grid-column-gap: 8px;
  height: fit-content;
  justify-self: end;
  justify-content: end;
  z-index: 1;

  & > ${LinkButtonComponent} {
    min-width: auto;
    padding: 4px 10px;
  }
`

export const ButtonLink = styled(Link)`
  height: fit-content;
`

export const PopoverContainer = styled(DropdownMenu.Root)`
  position: relative;
`

export const PopoverPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px 0;
  font-size: 14px;
  color: ${Colors.Neutral[700]};
  background-color: ${Colors.White};
  border-radius: ${BorderRad.m};
  box-shadow: ${Shadows.smallEnso};
`

export const AccountPanel = styled(PopoverPanel)<{ minWidth?: number }>`
  width: fit-content;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `};
  row-gap: 4px;
`

export const SocialsPanel = styled(PopoverPanel)`
  width: max-content;
  padding: 10px 0;
`

const SocialsPopoverContainer = styled.div`
  padding: 10px 10px 0;
  display: flex;
  gap: 10px;

  a {
    padding: 0;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
`

export const PopoverItem = styled.a`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${Colors.Neutral[700]};
  text-decoration: none;
  border-radius: 0;
  transition: ${Transitions.all};

  &:hover {
    color: ${Colors.Primary[500]};
  }

  &:last-child {
    position: relative;
    margin-top: 4px;
    color: ${Colors.Negative[900]};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -4px;
      width: 100%;
      height: 1px;
      background-color: ${Colors.Primary[100]};
    }

    &:hover,
    &:focus {
      color: ${Colors.Primary[500]};
    }
    &:active {
      color: ${Colors.Primary[600]};
    }
  }
`

export const PopoverLink = styled.a`
  display: block;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.Neutral[700]};
  transition: ${Transitions.all};
  text-align: center;

  &:hover {
    color: ${Colors.Primary[500]};
  }
`

export const PopoverIcon = styled.div`
  &,
  & > svg {
    width: 16px;
    height: 16px;
  }
`

export const NoAccountConnectButton = styled(Button)`
  width: 130px;
  min-width: 130px;
`

const AccountConnectButton = styled(Button)`
  width: 130px;
  min-width: 130px;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding-left: 8px;

  ${ButtonInner} {
    width: 100%;
    justify-content: space-between;
    grid-column-gap: 2px;
  }
`

export const AccountImageSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  // border-radius: ${BorderRad.m};
  // box-shadow: ${Shadows.defaultEnso};
  // background-color: ${Colors.White};
`

export const StyledArcardeButton = styled(Button)`
  background-color: white;
  background-image: url('/images/ArcadeButtonBg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: bold;
  color: white;
  span {
    filter: drop-shadow(5px 5px 5px #000);
  }
`
