import React, { useEffect, useState } from 'react'
import { Device, size } from '../styles'

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(768)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    setWidth(window.innerWidth)

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return width <= 768
}

export default useCheckMobileScreen
