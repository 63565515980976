import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ContentContainer, Button } from '../base'
import { Colors, Device } from '../../styles'
import Img from 'next/image'

export const BackedBestSection = () => {
  return (
    <ContentContainer>
      <Content>
        <h4>Backed by the best</h4>
        <PartnersContainer>
          <FirstRow>
            <ImageContainer>
              <Img src="/images/landing/logos/ploychain.svg" alt="Polychain logo" layout="fill" objectFit="contain" />
            </ImageContainer>
            <ImageContainer>
              <Img src="/images/landing/logos/multicoin.png" alt="Multicoin logo" layout="fill" objectFit="contain" />
            </ImageContainer>
          </FirstRow>
          <SecondRow>
            <ImageContainer>
              <Img src="/images/landing/logos/spartan.png" alt="Spartain logo" layout="fill" objectFit="contain" />
            </ImageContainer>
            <ImageContainer>
              <Img src="/images/landing/logos/dialectic.svg" alt="Dialectic logo" layout="fill" objectFit="contain" />
            </ImageContainer>
            <ImageContainer>
              <Img src="/images/landing/logos/p2p.png" alt="Inersect logo" layout="fill" objectFit="contain" />
            </ImageContainer>
          </SecondRow>
        </PartnersContainer>
      </Content>
    </ContentContainer>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px 0 28px 0;

  & h4 {
    color: ${Colors.Neutral[300]};
    margin: 0 0 48px 0;
  }

  @media ${Device.mobile} {
    border-top: 2px solid #ebeef7;
    padding: 80px 0 80px 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  position: relative;
`

const FirstRow = styled.div`
  width: 100%;
  display: flex;
  gap: 0 40px;
  justify-content: center;

  & ${ImageContainer} {
    width: 33%;
  }
`

const SecondRow = styled.div`
  width: 95%;
  display: flex;
  gap: 0 40px;
  justify-content: space-between;
  align-items: center;

  & ${ImageContainer} {
    width: 33%;
  }

  & ${ImageContainer}:last-child {
    height: 40px;
    min-height: 70px;
    width: 15%;
  }
`

const PartnersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 585px;
  width: 100%;
  justify-content: center;
  gap: 0 40px;

  @media ${Device.mobile} {
    gap: 0;
  }
`
