import { useCallback, useEffect, useState } from 'react'

export enum Status {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
export interface StatusPayload {
  status: Status
  setLoading: Function
  setError: Function
  setSuccess: Function
  setIdle: Function
  errorMessage: string
}
export const DEFAULT_STATUS: StatusPayload = {
  status: Status.IDLE,
  setLoading: () => {},
  setError: (e: any) => {},
  setSuccess: () => {},
  setIdle: () => {},
  errorMessage: '',
}

export const useStatus = (returnToIdleTimeout?: number): StatusPayload => {
  const [status, setStatus] = useState<Status.LOADING | Status.ERROR | Status.SUCCESS | Status.IDLE>(Status.IDLE)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const setLoading = useCallback(() => setStatus(Status.LOADING), [])
  const setError = useCallback((e?: any) => {
    setStatus(Status.ERROR)
    setErrorMessage(e?.toString())
  }, [])
  const setSuccess = useCallback(() => setStatus(Status.SUCCESS), [])
  const setIdle = useCallback(() => setStatus(Status.IDLE), [])

  useEffect(() => {
    if (returnToIdleTimeout) {
      setTimeout(() => {
        ;[Status.SUCCESS, Status.ERROR].includes(status) && setStatus(Status.IDLE)
      }, returnToIdleTimeout)
    }
  }, [status, returnToIdleTimeout])

  return { status, setLoading, setError, setSuccess, setIdle, errorMessage }
}
