import React from 'react'
import styled from 'styled-components'

interface SocialIconProps {
  className?: string
}

export function MediumIcon({ className }: SocialIconProps) {
  return (
    <SocialIcon viewBox="0 0 32 32" fill="none" color="currentColor" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H32V32H0V0ZM9.70001 11.7C9.79999 11.8 9.89999 12 9.89999 12.2V19.2C10 19.5 9.89999 19.7 9.70001 19.9L8 21.9V22.2H12.7V21.9L11 19.9C10.9 19.7 10.8 19.5 10.8 19.2V13.1L15 22.2H15.4L19 13.1V20.4C19 20.6 19 20.7 18.9 20.8L17.6 22.1V22.4H23.9V22.1L22.7 20.9C22.6 20.8 22.6 20.6 22.6 20.5V11.5C22.5 11.3 22.6 11.2 22.7 11.1L24 9.9V9.6H19.6L16.4 17.5L12.8 9.6H8.20001V9.9L9.70001 11.7Z"
        fill="currentColor"
      />
    </SocialIcon>
  )
}

const SocialIcon = styled.svg`
  width: 32px;
  height: 32px;
`
