export const LIQUIDTY_MIGRATION_MAINNET_ADDRESS = '0x0c6D898ac945E493D25751Ea43BE2c8Beb881D8C'
export const LIQUIDITY_MIGRATION_ABI = [
  {
    inputs: [
      { internalType: 'address[]', name: 'adapters_', type: 'address[]' },
      { internalType: 'uint256', name: 'unlock_', type: 'uint256' },
      { internalType: 'uint256', name: 'modify_', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'adapter', type: 'address' },
      { indexed: false, internalType: 'address', name: 'lp', type: 'address' },
      { indexed: false, internalType: 'address', name: 'strategy', type: 'address' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'Created',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'lp', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'receiver', type: 'address' },
    ],
    name: 'EmergencyMigration',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'adapter', type: 'address' },
      { indexed: false, internalType: 'address', name: 'lp', type: 'address' },
      { indexed: false, internalType: 'address', name: 'strategy', type: 'address' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'Migrated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'lp', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'Refunded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'adapter', type: 'address' },
      { indexed: false, internalType: 'address', name: 'strategy', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'Staked',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'adapters',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'adapter', type: 'address' }],
    name: 'addAdapter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'lps', type: 'address[]' },
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
      { internalType: 'address', name: 'adapter', type: 'address' },
    ],
    name: 'batchStake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'lp', type: 'address' },
      { internalType: 'address', name: 'adapter', type: 'address' },
      { internalType: 'address', name: 'exchange', type: 'address' },
      { internalType: 'uint256', name: 'minAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'buyAndStake',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'lp', type: 'address' }],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'controller',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract IERC20', name: 'lp', type: 'address' }],
    name: 'emergencyMigrate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'emergencyReceiver',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'genericRouter',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'address', name: 'lp', type: 'address' },
    ],
    name: 'hasStaked',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'lp', type: 'address' },
      { internalType: 'address', name: 'adapter', type: 'address' },
    ],
    name: 'migrateAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'migrationCoordinator',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'modify',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address', name: 'lp', type: 'address' },
    ],
    name: 'refund',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'adapter', type: 'address' }],
    name: 'removeAdapter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address', name: 'lp', type: 'address' },
      { internalType: 'address', name: 'adapter', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'setStake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'lp', type: 'address' },
      { internalType: 'address', name: 'strategy', type: 'address' },
    ],
    name: 'setStrategy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'lp', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'adapter', type: 'address' },
    ],
    name: 'stake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'staked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'strategies',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'totalStaked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unlocked',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: 'newController', type: 'address' }],
    name: 'updateController',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newCoordinator', type: 'address' }],
    name: 'updateCoordinator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newReceiver', type: 'address' }],
    name: 'updateEmergencyReceiver',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newGenericRouter', type: 'address' }],
    name: 'updateGenericRouter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'unlock_', type: 'uint256' }],
    name: 'updateUnlock',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'lp', type: 'address' }],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export const TOKENS = {
  TOKENSETS: [
    '0x23687d9d40f9ecc86e7666dddb820e700f954526', // USDAPY
    '0xf059afa5239ed6463a00fc06a447c14fe26406e1', // WBTCAPY
    '0xfdc4a3fc36df16a78edcaf1b837d3acaaedb2cb4', // SCIFI
    '0x7b18913D945242A9c313573E6c99064cd940c6aF', // sushiHOUSE
    '0xe5feeaC09D36B18b3FA757E5Cf3F8dA6B8e27F4C', // NFTI
    '0xFE05B972EAb7761B60b4A14558EAC3Fef78F306A', // MUG
    '0xB1F66BC5867A329cFd0465A506c4ad96e6Be1aDC', // HUUB
    '0xa188DA64fc4e212Cda65bD3406e0ce03a5323560', // TGF
    '0xBbA8120b355bC70E771F28e151a141A126843CdF', // CMI
    '0xe8e8486228753E01Dbc222dA262Aa706Bd67e601', // WEB3
  ],

  INDEXCOOP: [
    '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b', // DPI
    '0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1', // DATA
    '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd', // ETH2xFLI
    '0x0b498ff89709d3838a063f1dfa463091f9801c2b', // BTC2xFLI
    '0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6', // BED
    '0x72e364f2abdc788b7e918bc238b21f109cd634d7', // MVI
  ],

  DHEDGE: [
    '0x0f4c00139602ab502bc7c1c0e71d6cb72a9fb0e7', // DTOP
    '0x63ae7457b8be660daaf308a07db6bccb733b92df', // Convex
    '0xd076b9865feb49A43Aa38c06b0432dF6b6cBCA9E', // Jesse
    '0x3A4851597F36F459b58e65C55c8f3a8710313Fc7', // SNXDebt
    '0x391603b1C3b03A0133AD82E91692790e58f73570', // Gutta
    '0x907FeB27f8cc5b003Db7e62dfc2f9B01ce3FADd6', // Potato
    '0x3781eA00ECBE98d1550806C1213FC7FEb4F88a42', // Lion
    '0x0ac1DBa8252240589266194F9C27a42229E84B19', // ADAM
  ],

  INDEXED: [
    '0x126c121f99e1e211df2e5f8de2d96fa36647c855', // DEGEN
    '0xd6cb2adf47655b1babddc214d79257348cbc39a7', // ORCL5
    '0x68bb81b3f67f7aab5fd1390ecb0b8e1a806f2465', // NFTP
  ],

  POWERPOOL: [
    '0x26607aC599266b21d13c7aCF7942c7701a8b699c', // PIPT
    '0xb4bebD34f6DaaFd808f73De0d10235a92Fbb6c3D', // YETI
    '0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B', // YLA
  ],

  PIEDAO: [
    '0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e', // USDPLUS
    '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd', // BTCPLUS
    '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c', // DEFIS
  ],
}

export const VAMPIRE_PRICES = [
  {
    address: '0x23687d9d40f9ecc86e7666dddb820e700f954526',
    price: 419.787005840314,
  },
  {
    address: '0xf059afa5239ed6463a00fc06a447c14fe26406e1',
    price: 512.4771413916902,
  },
  {
    address: '0xfdc4a3fc36df16a78edcaf1b837d3acaaedb2cb4',
    price: 1.2816548608017089,
  },
  {
    address: '0x7b18913d945242a9c313573e6c99064cd940c6af',
    price: 10.807728643165024,
  },
  {
    address: '0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c',
    price: 1327.3640943505698,
  },
  {
    address: '0xfe05b972eab7761b60b4a14558eac3fef78f306a',
    price: 374.8010713826706,
  },
  {
    address: '0xb1f66bc5867a329cfd0465a506c4ad96e6be1adc',
    price: 3.2826593437446734,
  },
  {
    address: '0xa188da64fc4e212cda65bd3406e0ce03a5323560',
    price: 98.20603762922174,
  },
  {
    address: '0xbba8120b355bc70e771f28e151a141a126843cdf',
    price: 4.315430713576031,
  },
  {
    address: '0xe8e8486228753e01dbc222da262aa706bd67e601',
    price: 3.2687572765527277,
  },
  {
    address: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    price: 136.68582020980452,
  },
  {
    address: '0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1',
    price: 40.9500657744546,
  },
  {
    address: '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd',
    price: 62.10733996638992,
  },
  {
    address: '0x0b498ff89709d3838a063f1dfa463091f9801c2b',
    price: 20.983257956827977,
  },
  {
    address: '0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6',
    price: 94.92939956492657,
  },
  {
    address: '0x72e364f2abdc788b7e918bc238b21f109cd634d7',
    price: 89.66371034512646,
  },
  {
    address: '0x126c121f99e1e211df2e5f8de2d96fa36647c855',
    price: 2.5248826118835543,
  },
  {
    address: '0xd6cb2adf47655b1babddc214d79257348cbc39a7',
    price: 4.858623130031212,
  },
  {
    address: '0x68bb81b3f67f7aab5fd1390ecb0b8e1a806f2465',
    price: 25.731476537634308,
  },
  {
    address: '0x26607ac599266b21d13c7acf7942c7701a8b699c',
    price: 1.4423247440019231,
  },
  {
    address: '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d',
    price: 0.6345764849915925,
  },
  {
    address: '0x9ba60ba98413a60db4c651d4afe5c937bbd8044b',
    price: 0.9389342064012519,
  },
  {
    address: '0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e',
    price: 0.9865080105999723,
  },
  {
    address: '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
    price: 36518.13345603736,
  },
  {
    address: '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
    price: 2.6205649361918413,
  },
  {
    address: '0x0f4c00139602ab502bc7c1c0e71d6cb72a9fb0e7',
    price: 0.7179204129589498,
  },
  {
    address: '0x63ae7457b8be660daaf308a07db6bccb733b92df',
    price: 1.703619136584541,
  },
  {
    address: '0xd076b9865feb49A43Aa38c06b0432dF6b6cBCA9E',
    price: 2.6239833961036982,
  },
  {
    address: '0x3A4851597F36F459b58e65C55c8f3a8710313Fc7',
    price: 1.5148076475031103,
  },
  {
    address: '0x391603b1C3b03A0133AD82E91692790e58f73570',
    price: 2.093372831920918,
  },
  {
    address: '0x907FeB27f8cc5b003Db7e62dfc2f9B01ce3FADd6',
    price: 4.449746615743095,
  },
  {
    address: '0x3781eA00ECBE98d1550806C1213FC7FEb4F88a42',
    price: 2.251246928045047,
  },
  {
    address: '0x0ac1DBa8252240589266194F9C27a42229E84B19',
    price: 2.978212416860191,
  },
]
// MVI, DEGEN, BTC++,  DEFI+S, and NFTI
export const MUST_WITHDRAW = [
  '0x72e364f2abdc788b7e918bc238b21f109cd634d7',
  '0x126c121f99e1e211df2e5f8de2d96fa36647c855',
  '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
  '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
  '0xe5feeaC09D36B18b3FA757E5Cf3F8dA6B8e27F4C',
].map((a) => a.toLowerCase())
