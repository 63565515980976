import { Provider } from '@ethersproject/providers'
import { Contract } from 'ethers'
import { ChainId, ERC20_ABI } from '../constants'
import { InvalidToken, Token } from '../model'
import { encodeCall } from './multicall/encodeCall'
import { multicall } from './multicall/multicall'

export const fetchToken = async (provider: Provider, chainId: ChainId, address: string) => {
  const contract = new Contract(address, ERC20_ABI, provider)
  try {
    const [[symbol], [decimals], [name]] = await multicall(provider, chainId, [
      encodeCall(contract, 'symbol', []),
      encodeCall(contract, 'decimals', []),
      encodeCall(contract, 'name', []),
    ])
    return new Token(chainId, address, name, symbol, decimals)
  } catch (e: any) {
    if (e.code === 'CALL_EXCEPTION' || e.code === 'UNPREDICTABLE_GAS_LIMIT') {
      throw new InvalidToken(address)
    }
    throw e
  }
}

export const fetchTokenSilently = async (provider: Provider, chainId: ChainId, address: string) => {
  const contract = new Contract(address, ERC20_ABI, provider)
  try {
    const [[symbol], [decimals], [name]] = await multicall(provider, chainId, [
      encodeCall(contract, 'symbol', []),
      encodeCall(contract, 'decimals', []),
      encodeCall(contract, 'name', []),
    ])
    return new Token(chainId, address, name, symbol, decimals)
  } catch (e) {
    console.log('Failed to fetch token details: ', address)
  }
}
