import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Device } from '../../styles'

interface Props {
  children: ReactNode
  className?: string
}

export const ContentContainer = ({ children, className }: Props) => {
  return <ContentContainerWrapper className={className}>{children}</ContentContainerWrapper>
}

const ContentContainerWrapper = styled.section`
  width: 100%;
  max-width: 936px;
  margin: 0 auto;
  position: relative;

  @media ${Device.mobile} {
    padding: 0 16px;
  }
`
