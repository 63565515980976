import { useQuery, useQuery as useQueryV3 } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  TOKEN_DERIVED_ETH,
  TOKEN_DERIVED_ETH_V3,
  TOKENS_DERIVED_ETH,
  TOKENS_DERIVED_ETH_V3,
} from '../infrastructure/subgraph/uniswap/queries'
import { GetTokenDerivedEthQuery, GetTokensDerivedEthQuery } from '../infrastructure/subgraph/uniswap/types'
import { useRefetchOnNewBlock } from '../infrastructure/subgraph/useRefetchOnNewBlock'
import { normalizeString } from '../misc/utils'
import { useEtherPrice } from '../providers/etherPrice/context'
import Big from 'big.js'
import { SubgraphClientName } from '../infrastructure/subgraph/SubgraphClientName'

export enum TokenUsdPriceState {
  Unknown = 'Unknown',
  Price = 'Price',
  NoPrice = 'NoPrice',
}

export function useTokenUsdPrice(tokenAddress: string) {
  const { price: etherPrice } = useEtherPrice()
  const [tokenUsdPrice, setTokenUsdPrice] = useState<string>()
  const [priceState, setPriceState] = useState<TokenUsdPriceState>(TokenUsdPriceState.Unknown)

  const uniV2Response = useQuery<GetTokenDerivedEthQuery>(TOKEN_DERIVED_ETH, {
    variables: { id: normalizeString(tokenAddress) },
    context: { clientName: SubgraphClientName.UniV2 },
  })

  const uniV3Response = useQueryV3<GetTokenDerivedEthQuery>(TOKEN_DERIVED_ETH_V3, {
    variables: { id: normalizeString(tokenAddress) },
    context: { clientName: SubgraphClientName.UniV3 },
  })

  useEffect(() => {
    if (!etherPrice) {
      return
    }

    const uniV3DerivedEth = uniV3Response?.data?.token?.derivedETH
    const uniV2DerivedEth = uniV2Response?.data?.token?.derivedETH

    if (uniV3DerivedEth && etherPrice && uniV2DerivedEth) {
      const uniV2Big = new Big(uniV2DerivedEth)
      const uniV3Big = new Big(uniV3DerivedEth)
      const worstPrice = (uniV2Big.gt(uniV3Big) ? uniV2Big : uniV3Big).mul(etherPrice).toFixed()
      setTokenUsdPrice(worstPrice)
      setPriceState(TokenUsdPriceState.Price)
    } else if (uniV3Response?.data && uniV2Response?.data) {
      setPriceState(TokenUsdPriceState.NoPrice)
    }
  }, [uniV2Response?.data, uniV3Response?.data, etherPrice])

  useRefetchOnNewBlock<GetTokenDerivedEthQuery>(uniV2Response.refetch)
  useRefetchOnNewBlock<GetTokenDerivedEthQuery>(uniV3Response.refetch)

  return { tokenUsdPrice, priceState }
}

export interface TokenPrice {
  id: string
  price: string | undefined
}

export function useTokensHelperUsdPrice(tokenAddresses: string[]) {
  const { price: etherPrice } = useEtherPrice()
  const [tokensUsdPrice, setTokensUsdPrice] = useState<TokenPrice[]>()
  const ids = tokenAddresses.map(normalizeString)
  const [priceMap, setPriceMap] = useState(new Map())

  const uniV2Responses = useQuery<GetTokensDerivedEthQuery>(TOKENS_DERIVED_ETH, {
    variables: { ids, amount: ids.length },
    context: { clientName: SubgraphClientName.UniV2 },
  })
  const uniV3Responses = useQueryV3<GetTokensDerivedEthQuery>(TOKENS_DERIVED_ETH_V3, {
    variables: { ids, amount: ids.length },
    context: { clientName: SubgraphClientName.UniV3 },
  })

  useEffect(() => {
    if (!etherPrice) {
      return
    }

    const uniV2Tokens = uniV2Responses?.data?.tokens
    const uniV3Tokens = uniV3Responses?.data?.tokens

    if (uniV2Tokens && uniV3Tokens && uniV2Tokens.length && uniV3Tokens.length) {
      const newPrices = []

      ;[...uniV2Tokens, ...uniV3Tokens].forEach((token) => {
        const previousValue = priceMap.get(token.id)
        const currentValue = new Big(token.derivedETH)
        if (previousValue) {
          if (currentValue.gt(previousValue)) {
            priceMap.set(token.id, currentValue)
          }
        } else {
          priceMap.set(token.id, currentValue)
        }
      })

      for (const [id, amount] of priceMap.entries()) {
        const price = amount.mul(etherPrice).toFixed()
        newPrices.push({
          id,
          price,
        })
      }

      if (newPrices) {
        setTokensUsdPrice(newPrices)
      }
      setPriceMap(priceMap)
    }
  }, [uniV2Responses?.data, uniV3Responses?.data, etherPrice, priceMap])

  useRefetchOnNewBlock<GetTokenDerivedEthQuery>(uniV2Responses?.refetch)

  return tokensUsdPrice
}
