import React from 'react'
import styled from 'styled-components'
import { Device } from '../../../newDesign/styles'

import { HiringList } from '../base'
import { ContentContainer } from '../base'

const HIRING_DATA = [
  {
    title: 'Engineering',
    items: [{ title: 'Blockend Engineer', href: 'https://jobs.lever.co/Enso/9a82f8da-ef81-453e-ad8b-9852e792f1bd' }],
  },
]

export const HiringSection = () => {
  return (
    <ContentContainer>
      <Container>
        <h1>We are hiring!</h1>
        <HiringList data={HIRING_DATA} />
      </Container>
    </ContentContainer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 170px;

  & h1 {
    text-align: center;
    margin-bottom: 24px;
  }

  @media ${Device.mobile} {
    margin-top: 32px;
    margin-bottom: 92px;
  }
`
