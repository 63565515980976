import { ContractCall, useContractCalls, useContractFunction, useEthers } from '@usedapp/core'
import { LIQUIDTY_MIGRATION_MAINNET_ADDRESS, LIQUIDITY_MIGRATION_ABI } from './data'
import { Interface } from '@ethersproject/abi'
import { useMemo } from 'react'
import { Contract } from 'ethers'
import { getDefaultChainId, useChainId } from 'src/hooks/useChainId'
const abi = new Interface(LIQUIDITY_MIGRATION_ABI)

export const useVampireWithdraw = () => {
  const { library } = useEthers()

  const vampireContract = useMemo(
    () => new Contract(LIQUIDTY_MIGRATION_MAINNET_ADDRESS, LIQUIDITY_MIGRATION_ABI, library?.getSigner()),
    [library]
  )

  return useContractFunction(vampireContract, 'withdraw', { transactionName: 'Withdraw strategy' })
}

export const useVampireClaim = () => {
  const { library } = useEthers()

  const vampireContract = useMemo(
    () => new Contract(LIQUIDTY_MIGRATION_MAINNET_ADDRESS, LIQUIDITY_MIGRATION_ABI, library?.getSigner()),
    [library]
  )

  return useContractFunction(vampireContract, 'claim', { transactionName: 'Claim strategy' })
}

export const useVampireDetails = (tokenAddresses: string[]) => {
  const { account } = useEthers()
  const { chainId } = useChainId()
  const calls: (ContractCall | undefined)[] = tokenAddresses.map((tokenAddress) =>
    account && chainId === getDefaultChainId().chainId
      ? {
          address: LIQUIDTY_MIGRATION_MAINNET_ADDRESS,
          args: [account, tokenAddress],
          method: 'staked',
          abi,
        }
      : undefined
  )
  return useContractCalls(calls) ?? []
}
