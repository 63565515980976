import { allSettled } from '../misc/helpers/allSettled'
import { fetchToken } from '../infrastructure/fetchToken'
import { useEthers } from '@usedapp/core'
import { useChainId } from './useChainId'
import { useEffect, useState } from 'react'
import { Token } from '../model'

export const useTokenDetails = (addresses: string[]) => {
  const { account, library } = useEthers()
  const { chainId } = useChainId()
  const [tokenDetails, setTokenDetails] = useState<Token[]>([])

  useEffect(() => {
    const fetchTokenDetails = async () => {
      if (library && chainId && account) {
        try {
          const tokens = await Promise.all(addresses.map((address) => fetchToken(library, chainId, address)))
          setTokenDetails(tokens)
        } catch (err) {
          console.error(`Could not fetch token details for ${addresses.length} tokens`, addresses)
        }
      }
    }
    if (addresses.length) fetchTokenDetails()
  }, [account, addresses, chainId, library])

  return tokenDetails
}
