import React from 'react'
import Blockie from 'react-blockies'
import styled, { css } from 'styled-components'
import { JazzIcon } from '..'
import { BorderRad, Colors, Transitions } from '../../constants'
import { CommonImage } from '../page/CommonImage'

export interface WalletAvatarProps {
  image?: string | null | undefined
  size?: number
  id: string
  isStrategy?: boolean
  className?: string
  alt?: string
  borderSize?: number
}

export function WalletAvatar({ image, size = 24, borderSize = 4, id, isStrategy, className, alt }: WalletAvatarProps) {
  return (
    <WalletAvatarWrapper className={className} size={size}>
      {image ? (
        <RoundedImage src={image} width={size - borderSize} height={size} alt={alt} />
      ) : (
        <>
          {isStrategy ? (
            <JazzIcon address={id} size={size - borderSize} />
          ) : (
            <BlockieWrapper id={id} size={size - borderSize} />
          )}
        </>
      )}
    </WalletAvatarWrapper>
  )
}

interface WrapperProps {
  id: string
  size: number
}

export const BlockieWrapper = ({ id, size }: WrapperProps) => (
  <BlockieWrapperInner size={size}>
    <Blockie seed={id} size={32} scale={4} />
  </BlockieWrapperInner>
)

export const BlockieWrapperInner = styled.div<{ size?: number }>`
  overflow: hidden;
  width: ${({ size }) => size + 'px'};
  min-width: ${({ size }) => size + 'px'};
  max-width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  min-height: ${({ size }) => size + 'px'};
  max-height: ${({ size }) => size + 'px'};
  border-radius: ${BorderRad.full};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WalletBackgroundShadowEffect = css`
  background: linear-gradient(180deg, #f0f4ff, #f0f4ff);
  filter: drop-shadow(0px 3px 2px #e0e0e0);
`

export const WalletAvatarWrapper = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size + 'px'};
  min-width: ${({ size }) => size + 'px'};
  max-width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  min-height: ${({ size }) => size + 'px'};
  max-height: ${({ size }) => size + 'px'};
  border-radius: ${BorderRad.round};
  overflow: hidden;
  transition: ${Transitions.all};

  ${WalletBackgroundShadowEffect}
`

const RoundedImage = styled(CommonImage)`
  height: 100%;
  width: auto;
`
