import * as Tooltip from '@radix-ui/react-tooltip'
import React from 'react'
import styled, { css } from 'styled-components'
import { BorderRad, Colors, Overflow, Shadows, Transitions } from '../../constants'
import { InfoIconSvg } from '../icons/InfoIcon'
import { TooltipProps } from '@radix-ui/react-tooltip'

export interface PopupProps extends TooltipProps {
  tooltipTrigger: React.ReactNode
  tooltipToShow: React.ReactNode
  side?: 'bottom' | 'left' | 'right' | 'top'
  sideOffset?: number
  className?: string
  delayDuration?: number
  dark?: boolean
}

export function GlobalTooltip({
  tooltipTrigger,
  tooltipToShow,
  side,
  sideOffset,
  className,
  delayDuration,
  dark = false,
}: PopupProps) {
  return (
    <TooltipContainer delayDuration={delayDuration ?? 50}>
      <TooltipTriggerContainer className={className} asChild>
        <div>{tooltipTrigger}</div>
      </TooltipTriggerContainer>
      <TooltipToShowContainer dark={dark.toString()} side={side} sideOffset={sideOffset} portalled>
        <TooltipArrow dark={dark.toString()} />
        <TooltipToShowWrapper>{tooltipToShow}</TooltipToShowWrapper>
      </TooltipToShowContainer>
    </TooltipContainer>
  )
}

const TooltipContainer = styled(Tooltip.Root)`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  align-items: center;
  width: fit-content;
  height: fit-content;
`

const TooltipArrow = styled(Tooltip.Arrow)<{ dark: string }>`
  fill: ${Colors.Neutral[50]};
  ${({ dark }) =>
    dark === 'true' &&
    css`
      fill: ${Colors.Neutral[900]};
    `}
`

const TooltipToShowContainer = styled(Tooltip.Content)<{ dark?: string }>`
  width: 100%;
  max-width: 320px;
  min-height: 32px;
  height: fit-content;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: ${BorderRad.m};
  filter: drop-shadow(${Shadows.defaultEnso});
  background-color: ${Colors.Neutral[50]};
  color: ${Colors.Neutral[600]};

  ${({ dark }) =>
    dark === 'true' &&
    css`
      color: ${Colors.White};
      background-color: ${Colors.Neutral[900]};
    `}
`

const TooltipToShowWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: fit-content;
  max-height: 320px;
  overflow: hidden;
  overflow-y: scroll;
`

const TooltipTriggerContainer = styled(Tooltip.Trigger)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  transition: ${Transitions.all};

  ${InfoIconSvg} {
    width: 12px;
    height: 12px;
  }

  &:hover,
  &:focus {
    color: ${Colors.Primary[500]};
    outline: none;
  }
`

export const TooltipHolder = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  align-items: center;
  width: fit-content;
`

export const TooltipTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`

export const TooltipRows = styled.div`
  display: grid;
  grid-row-gap: 4px;
  width: 100%;
`

export const TooltipRow = styled.div`
  display: grid;
  grid-template-columns: 72px auto;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`

export const TooltipRowContent = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Colors.White};
  text-align: right;
`

export const TooltipRowLabel = styled(TooltipRowContent)`
  color: ${Colors.Neutral[700]};
  text-align: left;
  ${Overflow.FullDots};
`
