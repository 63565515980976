import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Colors, Device } from '../../styles'

interface Props {
  title: string
  answer: string
}

interface HideButtonProps {
  isHide: boolean
  animateBlock: boolean
}

export const Question = ({ title, answer }: Props) => {
  const [isHide, setIsHide] = useState(true)
  const [animateBlock, setAnimateBlock] = useState(false)

  useEffect(() => {
    setAnimateBlock(true)
    setTimeout(() => setAnimateBlock(false), 300)
  }, [isHide])

  const handleHide = () => setIsHide(!isHide)

  return (
    <Content onClick={handleHide} isHide={isHide}>
      <Title>{title}</Title>
      <Answer>{answer}</Answer>
      <HideButton isHide={isHide} animateBlock={animateBlock}>
        <span className="block" />
      </HideButton>
    </Content>
  )
}

const ContentOpenStyles = css`
  max-height: 500px;
  transition: max-height 0.5s ease-in;

  @media ${Device.mobile} {
    padding: 20px;
    max-height: 500px;
  }
`

const Content = styled.div<{ isHide: boolean }>`
  display: flex;
  flex-direction: column;
  background: #fdfcff;
  box-shadow: 4px 8px 12px rgba(66, 57, 104, 0.04);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;
  max-height: 70px;

  @media ${Device.mobile} {
    padding: 20px;
    max-height: 56px;
  }

  transition: max-height 0.5s ease-out;
  overflow: hidden;

  ${(props) => {
    if (props.isHide) return []
    return [ContentOpenStyles]
  }};
`

const HideButton = styled.div<HideButtonProps>`
  position: absolute;
  width: 24px;
  height: 24px;
  background: #efe7ff;
  box-shadow: 4px 8px 12px rgba(66, 57, 104, 0.04);
  border-radius: 16px;
  top: 24px;
  right: 24px;

  @media ${Device.mobile} {
    top: 18px;
  }

  &::before,
  &::after {
    content: '';
    border: 2px solid #986eec;
    border-radius: 2px;
    position: absolute;
    opacity: ${(props) => (props.animateBlock ? 0 : 1)};
  }

  &::before {
    width: 10px;
    height: 0;
    top: 10px;
    left: 5px;
  }

  &::after {
    width: 0;
    height: 10px;
    top: 5px;
    left: 10px;
    display: ${(props) => (props.isHide ? 'initial' : 'none')};
  }

  & .block {
    position: absolute;
    border-radius: 6px;
    width: 14px;
    left: 5px;
    top: 0;
    background: #986eec;
    display: ${(props) => (props.animateBlock ? 'initial' : 'none')};

    animation-name: 'animate-hide-button';
    animation-duration: 0.3s;

    @keyframes ${(props) => props.animateBlock && 'animate-hide-button'} {
      0%,
      100% {
        height: 0;
        opacity: 0.1;
        top: 45%;
      }
      50% {
        height: 100%;
        opacity: 0.6;
        top: 0;
      }
    }
  }
`

const Title = styled.div`
  height: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.03em;
  color: ${Colors.Neutral[400]};

  @media ${Device.mobile} {
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    height: 16px;
  }
`

const Answer = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: ${Colors.Neutral[300]};
  width: 92%;
  margin-top: 24px;

  @media ${Device.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`
