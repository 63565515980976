import { SupportedChainId } from '../src/constants'
import { ChainId } from '../src/constants/chainId'
export enum Environment {
  STAGE = 'STAGE',
  PROD = 'PROD',
  LOCAL = 'LOCAL',
}
export enum EnvironmentField {
  ENVIRONMENT = 'ENVIRONMENT',
  USER_API = 'USER_API',
  BACKEND_GQL_API = 'BACKEND_GQL_API',
  SUBGRAPH = 'SUBGRAPH',
  MAINNET_RPC_URL = 'MAINNET_RPC_URL',
  ARCADE_URL = 'ARCADE_URL',
  INFURA_PROJECT_ID = 'INFURA_PROJECT_ID',
  ZAPPER_API_KEY = 'ZAPPER_API_KEY',
  COVALENT_KEY = 'COVALENT_KEY',
}
type Subgraph = Record<SupportedChainId, string>
export type EnvConfigInstance = {
  [EnvironmentField.ENVIRONMENT]: string
  [EnvironmentField.USER_API]: string
  [EnvironmentField.BACKEND_GQL_API]: string
  [EnvironmentField.ARCADE_URL]: string
  [EnvironmentField.MAINNET_RPC_URL]: string
  [EnvironmentField.INFURA_PROJECT_ID]: string
  [EnvironmentField.ZAPPER_API_KEY]: string
  [EnvironmentField.COVALENT_KEY]: string
  [EnvironmentField.SUBGRAPH]: Subgraph
}

const INFURA_PROJECT_ID = process.env.NEXT_PUBLIC_INFURA_PROJECT_ID || ''
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || ''
const USER_API = process.env.NEXT_PUBLIC_USER_API || ''
const BACKEND_GQL_API = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || ''
const SUBGRAPH_MAINNET = process.env.NEXT_PUBLIC_SUBGRAPH_MAINNET || ''
const SUBGRAPH_KOVAN = process.env.NEXT_PUBLIC_SUBGRAPH_KOVAN || ''
const MAINNET_RPC_URL = process.env.NEXT_PUBLIC_MAINNET_RPC_URL || ''
const ARCADE_URL = process.env.NEXT_PUBLIC_ARCADE_URL || ''
const ZAPPER_API_KEY = process.env.NEXT_PUBLIC_ZAPPER_API_KEY || ''
const COVALENT_KEY = process.env.NEXT_PUBLIC_COVALENT_KEY || ''
export const ENVIRONMENT_CONFIG: EnvConfigInstance = {
  ENVIRONMENT,
  USER_API,
  BACKEND_GQL_API,
  SUBGRAPH: {
    [ChainId.Mainnet]: SUBGRAPH_MAINNET,
    [ChainId.Kovan]: SUBGRAPH_KOVAN,
  },
  MAINNET_RPC_URL,
  ARCADE_URL,
  INFURA_PROJECT_ID,
  ZAPPER_API_KEY,
  COVALENT_KEY,
}
