import { createContext, useContext } from 'react'
import { BigNumber } from 'ethers'
import { Token } from '../../model'

export interface ClaimableAmount {
  amount: BigNumber
  token: Token
}
export interface Claimable {
  open: boolean
  setOpen: (open: boolean) => void
  claims: ClaimableAmount[]
}
export const VampireContext = createContext<Claimable>({ open: false, setOpen: () => {}, claims: [] })

export function useVampire() {
  return useContext(VampireContext)
}
