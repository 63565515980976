import { gql } from '@apollo/client'

export const GlobalSearchQuery = gql`
  query GetGlobalSearch($chainId: Int!, $search: String!, $type: String) {
    globalSearch(chainId: $chainId, search: $search, type: $type) {
      managers {
        id
        name
        image
        type
      }
      investors {
        id
        name
        image
        type
      }
      strategies {
        id
        name
        symbol
        image
        type
      }
    }
  }
`

export const ProfileDetailsQuery = gql`
  query GetProfileDetails($chainId: Int!, $id: String!) {
    profileDetails(chainId: $chainId, id: $id) {
      id
      name
      type
      followerCount
      followingCount
      creationDate
      twitterHandle
      discordHandle
      telegramHandle
      mediumHandle
      profileImage
      isFollowing
      managerMeta {
        holdersCount
        tvl
        strategiesCount
      }
      investorMeta {
        investmentsUSD
        holdingTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        performanceTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
      }
      settings {
        slippageThreshold
      }
    }
  }
`

export const UserStrategiesQuery = gql`
  query GetUserStrategies(
    $chainId: Int!
    $id: String!
    $pageSize: Int!
    $currentPage: Int!
    $type: UserStrategiesType!
    $filter: QueryUserStrategiesFilter!
    $sort: Sorting!
  ) {
    userStrategies(
      chainId: $chainId
      id: $id
      type: $type
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      strategies {
        id
        name
        symbol
        price
        tvl
        image
        holdersCount
        version
        updatedImplementation
        updatedRewards

        manager {
          id
          name
          profileImage
          type
          managerMeta {
            tvl
          }
        }
        items {
          id
          percentage
          balance
          timestamp
          adapters
          path
          token {
            id
            symbol
            name
            decimals
            category
          }
        }
        forCurrentUser {
          balance
          investment
        }
        tvlTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        priceTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        creationDate
        totalSupply
        image
      }
      pageCount
      meta {
        totalCountFavourited
        totalCountInvested
        totalCountCreated
        selectedStrategyTypeTvlSum
        selectedStrategyTypeTrendsAverage {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        tvlRangeFrom
        tvlRangeTo
        performanceRangeFrom
        performanceRangeTo
        investmentsRangeFrom
        investmentsRangeTo
        createdStrategiesAssetExposure {
          symbol
          percentage
          address
          dollarValue
        }
        favouritedStrategiesAssetExposure {
          symbol
          percentage
          address
          dollarValue
        }
        investedStrategiesAssetExposure {
          symbol
          percentage
          address
          dollarValue
        }
      }
    }
  }
`

export const StrategyDetailsQuery = gql`
  query GetStrategyDetails($chainId: Int!, $id: String!) {
    strategyDetails(chainId: $chainId, id: $id) {
      id
      name
      symbol
      price
      tvl
      lastStateChangeCategory
      lastStateChangeTimestamp
      locked
      version
      updatedImplementation
      updatedRewards
      stateChanges {
        id
        before
        after
        stateChangeCategory
        status
        timestamp
        txHash
        blockNumber
      }
      restructures {
        before {
          percentage
          balance
          timestamp
          adapters
          path
          token {
            symbol
            id
          }
        }
        after {
          percentage
          balance
          timestamp
          adapters
          path
          token {
            symbol
            id
          }
        }
        status
        timestamp
      }
      stateChanges {
        id
        after
        before
        timestamp
        txHash
        blockNumber
      }
      creationDate
      lastRestructure
      totalSupply
      holdersCount
      socialTrading
      fee
      locked
      restructureDisabled
      threshold
      rebalanceSlippage
      restructureSlippage
      timelock
      threshold
      chainId
      image
      followerCount
      priceTrends {
        trend1d
        trend7d
        trend30d
        trendAll
      }
      manager {
        id
        name
        type
        followerCount
        followingCount
        managerMeta {
          holdersCount
          strategiesCount
          tvl
        }
        creationDate
        twitterHandle
        discordHandle
        telegramHandle
        mediumHandle
        profileImage
      }
      items {
        id
        percentage
        balance
        timestamp
        adapters
        path
        token {
          id
          symbol
          name
          decimals
          category
        }
      }
      forCurrentUser {
        id
        balance
        investment
        isFollowing
      }
    }
  }
`

export const LeaderboardsStrategiesQuery = gql`
  query GetLeaderboardsStrategies(
    $chainId: Int!
    $pageSize: Int!
    $currentPage: Int!
    $filter: QueryLeaderboardStrategiesFilter!
    $sort: Sorting!
  ) {
    leaderboardStrategies(
      chainId: $chainId
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      pageCount
      strategies {
        id
        name
        symbol
        price
        tvl
        creationDate
        totalSupply
        holdersCount
        socialTrading
        fee
        timelock
        chainId
        image
        version
        updatedImplementation
        updatedRewards
        tvlTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        priceTrends {
          trend1d
          trend7d
          trend30d
          trendAll
        }
        items {
          id
          percentage
          balance
          timestamp
          adapters
          path
          token {
            id
            symbol
            name
            decimals
            category
          }
        }
        followerCount
        forCurrentUser {
          id
          isFollowing
        }
      }
    }
  }
`

export const LeaderboardsInvestorsQuery = gql`
  query GetLeaderboardsInvestors(
    $chainId: Int!
    $pageSize: Int!
    $currentPage: Int!
    $filter: QueryLeaderboardInvestorsFilter!
    $sort: Sorting!
  ) {
    leaderboardInvestors(
      chainId: $chainId
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      pageCount
      investors {
        id
        name
        type
        followerCount
        followingCount
        creationDate
        twitterHandle
        discordHandle
        telegramHandle
        mediumHandle
        profileImage
        isFollowing
        investorMeta {
          investmentsUSD
          performanceTrends {
            trend1d
            trend7d
            trend30d
            trendAll
          }
        }
      }
    }
  }
`

export const LeaderboardsManagersQuery = gql`
  query GetLeaderboardsManagers(
    $chainId: Int!
    $pageSize: Int!
    $currentPage: Int!
    $filter: QueryLeaderboardManagersFilter!
    $sort: Sorting!
  ) {
    leaderboardManagers(
      chainId: $chainId
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      pageCount
      managers {
        id
        name
        type
        followerCount
        followingCount
        creationDate
        twitterHandle
        discordHandle
        telegramHandle
        mediumHandle
        profileImage
        isFollowing
        managerMeta {
          claimedPerformanceFeeSum
          holdersCount
          strategiesCount
          tvl
          performanceTrends {
            trend1d
            trend7d
            trend30d
            trendAll
          }
        }
      }
    }
  }
`

export const getStrategyDetailsGraph = gql`
  query GetStrategyDetailsGraph($chainId: Int!, $ids: [String!]!) {
    strategiesDetailsGraph(chainId: $chainId, ids: $ids) {
      earliestDateTimestamp
      strategy {
        id
        name
        symbol
      }
      items {
        timestamp
        percent
      }
    }
  }
`

export const searchStrategyTokens = gql`
  query SearchStrategyToken($chainId: Int!, $userId: String, $type: TokenSearchQueryType!, $search: String!) {
    tokenSearch(chainId: $chainId, userId: $userId, type: $type, search: $search) {
      id
      name
      symbol
      decimals
      category
    }
  }
`

export const searchStrategyInvestors = gql`
  query StrategyInvestors($chainId: Int!, $strategyId: String!) {
    strategyInvestors(chainId: $chainId, strategyId: $strategyId) {
      investors {
        id
        profileImage
        name
      }
    }
  }
`

export const getIdList = gql`
  query IdList($chainId: Int!, $type: IdListType!) {
    idList(chainId: $chainId, type: $type) {
      ids
      count
    }
  }
`

export const getPlatform = gql`
  query Platform($chainId: Int!) {
    platform(chainId: $chainId) {
      id
      version
    }
  }
`
