import { UseComboboxGetItemPropsOptions } from 'downshift'
import React from 'react'
import { shortenAddress } from '../../misc/utils'
import styled, { css } from 'styled-components'
import { Colors, Overflow, Shadows, Transitions, ZERO_ADDRESS } from '../../constants'
import { CommonImage } from '../page/CommonImage'
import { OptionProps } from './GlobalSearch'
import { itemToString } from './GlobalSearchHelpers'
import { GlobalSearchAllResultType } from './useGlobalSearch'
import Link from 'next/link'
import { WalletAvatar } from '../base/WalletAvatar'

interface DropdownOptionsProps {
  options: OptionProps<GlobalSearchAllResultType>[]
  getItemProps: (options: UseComboboxGetItemPropsOptions<GlobalSearchAllResultType>) => any
  highlightedIndex: number
}

export const DropdownOptions = ({ options, getItemProps, highlightedIndex }: DropdownOptionsProps) => {
  return (
    <>
      {options.map((option: OptionProps<GlobalSearchAllResultType>, optionIndex: number) => (
        <OptionItem highlighted={highlightedIndex === option.index} key={optionIndex} {...getItemProps(option)}>
          <WalletAvatar isStrategy={option.item.type == 'Strategy'} image={option.item.image} id={option.item.id} />
          {option.item.type === 'Strategy' ? (
            <Link href={`/strategy/${option.item.id}`} passHref>
              <OptionLink href={`/strategy/${option.item.id ?? '0x0'}`}>
                {itemToString(option.item)}{' '}
                <OptionItemSubtext>{shortenAddress(option.item?.id ?? ZERO_ADDRESS)}</OptionItemSubtext>
              </OptionLink>
            </Link>
          ) : (
            <Link href={`/profile/${option.item.id ?? '0x0'}`} passHref>
              <OptionLink>
                {itemToString(option.item)}{' '}
                <OptionItemSubtext>{shortenAddress(option.item?.id ?? ZERO_ADDRESS)}</OptionItemSubtext>
              </OptionLink>
            </Link>
          )}
        </OptionItem>
      ))}
    </>
  )
}

export const OptionLink = styled.a`
  all: unset;
  color: inherit;
`

export const OptionItemSubtext = styled.span`
  color: ${Colors.Neutral[400]};
`

export const OptionItem = styled.span<{ highlighted?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  color: ${Colors.Neutral[900]};
  cursor: pointer;
  transition: ${Transitions.all};
  ${Overflow.FullDots};

  ${({ highlighted }) =>
    highlighted &&
    css`
      color: ${Colors.Primary[500]};
    `}

  &:hover,
  &:focus-within {
    &:before {
      box-shadow: ${Shadows.defaultEnso};
    }
    color: ${Colors.Primary[500]};
  }
  & + & {
    margin-top: 16px;
  }
`
export const Avatar = styled(CommonImage)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`
