import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../constants'

interface ExclamationProps {
  color?: string
  className?: string
  size?: number
}

export function Exclamation({ color, className, size = 14 }: ExclamationProps) {
  return (
    <SvgIcon
      color={color ?? Colors.Neutral[500]}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      size={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <path
        d="M7.00944 0.33252C3.32757 0.33252 0.342773 3.31719 0.342773 6.99919C0.342773 10.6812 3.32757 13.6659 7.00944 13.6659C10.6914 13.6659 13.6761 10.6812 13.6761 6.99919C13.6761 3.31719 10.6914 0.33252 7.00944 0.33252ZM7.00944 1.66585C9.95477 1.66585 12.3428 4.05385 12.3428 6.99919C12.3428 9.94452 9.95477 12.3325 7.00944 12.3325C4.06391 12.3325 1.67611 9.94452 1.67611 6.99919C1.67611 4.05385 4.06391 1.66585 7.00944 1.66585ZM7.00944 3.66585C6.64144 3.66585 6.34277 3.96452 6.34277 4.33252V7.66585C6.34277 8.03385 6.64144 8.33252 7.00944 8.33252C7.37744 8.33252 7.6761 8.03385 7.6761 7.66585V4.33252C7.6761 3.96452 7.37744 3.66585 7.00944 3.66585ZM7.00944 8.99919C6.64144 8.99919 6.34277 9.29785 6.34277 9.66585C6.34277 10.0339 6.64144 10.3325 7.00944 10.3325C7.37744 10.3325 7.6761 10.0339 7.6761 9.66585C7.6761 9.29785 7.37744 8.99919 7.00944 8.99919Z"
        fill={color || 'currentColor'}
      />
    </SvgIcon>
  )
}

const SvgIcon = styled.svg<ExclamationProps>`
  width: ${({ size }) => size ?? 14}px;
  height: ${({ size }) => size ?? 14}px;
  position: relative;
`
