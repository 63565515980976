import { UnsupportedChainIdError } from '@web3-react/core'
import { ChainId, CHAIN_NAMES } from '@usedapp/core'
export { ChainId, CHAIN_NAMES }

export type SupportedChainId = ChainId.Mainnet | ChainId.Kovan
export const SUPPORTED_CHAINS = [ChainId.Mainnet]
export const TEST_NETWORKS = [
  ChainId.Ropsten,
  ChainId.Kovan,
  ChainId.Rinkeby,
  ChainId.Goerli,
  ChainId.Kovan,
  ChainId.Hardhat,
]

export const isTestNetwork = (chainId?: ChainId): boolean => {
  return !!chainId && TEST_NETWORKS.includes(chainId)
}

export function ensureChainSupport(chainId: ChainId, supportedChains: ChainId[]) {
  if (!supportedChains.includes(chainId)) {
    throw new UnsupportedChainIdError(chainId, supportedChains)
  }
}

export function chainIdFromError(message: string) {
  const regexp = /Unsupported chain id: (?<id>[0-9]+)/
  const match = message.match(regexp)
  const chaiIdAsString = match?.groups?.id
  return chaiIdAsString ? parseInt(chaiIdAsString) : undefined
}

export function getUnsupportedNetworkMessage(supportedChains: ChainId[], chainId?: ChainId) {
  const chainName = chainId ? CHAIN_NAMES[chainId] : 'Unknown'
  const supportedString = supportedChains.map((chainId) => CHAIN_NAMES[chainId]).join(', ')
  return `Unsupported chain ${chainName}. Supported chains are: ${supportedString}.`
}
