import React, { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { DeploymentSpinner, Spinner } from '../icons/DeploymentSpinner'
import { Colors } from '../../constants'

interface WrappedSpinnerProps {
  loading: boolean
  size?: number
  children: ReactNode
}
export const WrappedSpinner = memo(({ loading, children }: WrappedSpinnerProps) => {
  if (loading) {
    return <InProcessSpinner />
  }
  return <>{children}</>
})

const InProcessSpinner = styled(DeploymentSpinner)<Partial<WrappedSpinnerProps>>`
  height: ${(size) => size && `16px;`};
  width: ${(size) => size && `16px;`};

  ${Spinner} > path {
    fill: ${Colors.Neutral[500]};
  }
`
