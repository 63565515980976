import React from 'react'
import styled from 'styled-components'

interface SocialIconProps {
  className?: string
}

export function TwitterIcon({ className }: SocialIconProps) {
  return (
    <SocialIcon viewBox="0 0 32 32" fill="none" color="currentColor" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H32V32H0V0ZM22.1 11.5C22.8 11.4 23.4 11.3 24 11C23.6 11.7 23 12.3 22.3 12.7C22.5 17.4 19.1 22.5 13 22.5C11.2 22.5 9.5 21.9 8 21C9.7 21.2 11.5 20.7 12.7 19.8C11.2 19.8 10 18.8 9.6 17.5C10.1 17.6 10.6 17.5 11.1 17.4C9.6 17 8.5 15.6 8.5 14.1C9 14.3 9.5 14.5 10 14.5C8.6 13.5 8.1 11.6 9 10.1C10.7 12.1 13.1 13.4 15.8 13.5C15.3 11.5 16.9 9.5 19 9.5C19.9 9.5 20.8 9.9 21.4 10.5C22.2 10.3 22.9 10.1 23.5 9.7C23.3 10.5 22.8 11.1 22.1 11.5Z"
        fill="currentColor"
      />
    </SocialIcon>
  )
}

const SocialIcon = styled.svg`
  width: 32px;
  height: 32px;
`
