import React from 'react'
import styled from 'styled-components'

import { Colors, Device, Transitions } from '../../../newDesign/styles'

interface Block {
  title: String
  items: { title: string; href: string }[]
}

interface HiringListProps {
  data: Block[]
}

export const HiringList = ({ data }: HiringListProps) => {
  return (
    <Container>
      {data.map((block: Block, i) => {
        return (
          <React.Fragment key={i}>
            <h3>{block.title}</h3>
            {block.items.map((item, i) => (
              <ListItem key={i} href={item.href} target="_blank">
                {item.title}
              </ListItem>
            ))}
          </React.Fragment>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  & h3 {
    margin-bottom: 8px;
    margin-top: 24px;
  }

  @media ${Device.mobile} {
    & h3 {
      margin-bottom: 0;
      margin-top: 16px;
    }
  }
`

const ListItem = styled.a`
  width: 100%;
  margin-top: 16px;
  background: ${Colors.Neutral[0]};
  box-shadow: 4px 8px 12px rgba(66, 57, 104, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${Colors.Neutral[400]};
  transition: ${Transitions.default};

  &:hover {
    box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05), 0px -4px 28px #ecdcff, 0px 7px 40px #ffe2e7;
  }

  @media ${Device.mobile} {
    font-size: 16px;
    line-height: 16px;
  }
`
