import React from 'react'
import styled from 'styled-components'

interface SuccessIconProps {
  className?: any
}

export function SuccessIcon({ className }: SuccessIconProps) {
  return (
    <SuccessIconStyle
      x="0px"
      y="0px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      color="currentColor"
    >
      <path
        d="M8,1C4.1,1,1,4.1,1,8s3.1,7,7,7s7-3.1,7-7S11.9,1,8,1z M6.6,11.5L3.1,8l1-1l2.5,2.5l5.3-5.3l1,1L6.6,11.5z"
        fill="currentColor"
      />
    </SuccessIconStyle>
  )
}

const SuccessIconStyle = styled.svg`
  width: 24px;
  height: 24px;
  position: relative;
`
