import { BigNumber } from 'ethers'
import _isInteger from 'lodash/isInteger'
import _uniq from 'lodash/uniq'

export function shortenAddress(address: string, tailCharactersAmount?: number) {
  if (!tailCharactersAmount || tailCharactersAmount < 1) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4)
  }
  if (tailCharactersAmount >= 20) {
    return address
  }
  return (
    address.substr(0, 2 + Math.floor(tailCharactersAmount)) +
    '...' +
    address.substr(address.length - Math.floor(tailCharactersAmount))
  )
}

export const parseErrorMessage = (errorMessage: string): string | null => {
  const keywords = ['message', 'reason', 'fault']
  try {
    const errorMessages = keywords
      .map((keyword) => findStringsBetweenTwoStringsRegEx(errorMessage, `"${keyword}":"`, '"'))
      .filter(Boolean)

    return _uniq(errorMessages).join('\t') ?? null
  } catch (e) {
    return null
  }
}

export const findStringsBetweenTwoStringsRegEx = (
  string: string,
  startString: string,
  endString: string
): string | null => {
  const regex = new RegExp(`${startString}(.*?)${endString}`, 'g')
  const matches = string.match(regex)
  if (!matches) {
    return null
  }
  return _uniq(matches.map((match) => match.replace(startString, '').replace(endString, '')))?.join(`\t`) ?? null
}

export const abbrLargeNumber = (num: number) => {
  const n = Math.round(num)
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export function normalizeString(aString: string) {
  return aString?.trim().toLowerCase() ?? ''
}

export function cleanString(aString: string) {
  return (aString ?? '').trim().replace(/\s+/g, ' ')
}

export function isStringPercentage(text: string, scale = 1): boolean {
  const stringForRegex = `^[1-9]\\d?$|^[1-9]\\d?\\.\\d{0,${scale}}$|^100$|^100\\.0{0,${scale}}$|^0$|^0\\.\\d{0,${scale}}$|^$`
  const percentRegex = new RegExp(stringForRegex)
  return percentRegex.test(text)
}

export function laxParseFloat(value: string): number {
  return parseFloat(value === '' ? '0' : value)
}

export function isValidPercentage(numStr: string): boolean {
  return _isInteger(+numStr) && +numStr >= 0 && +numStr <= 100
}
export function isValidPercentageNum(num: number): boolean {
  return _isInteger(num) && num > 0 && num <= 100
}

export function pcStr2Num(percent: string): number {
  return !!percent && isValidPercentage(percent) ? laxParseFloat(percent) / 100 : 0
}

export function pcNum2Str(percent: number): string {
  const res = percent * 100
  return isValidPercentageNum(res) ? res.toFixed(0) : '0'
}

export const isBlankAddress = (value: string): boolean => {
  return ['0x0000000000000000000000000000000000000000', '0x', '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'].includes(
    value.toLowerCase()
  )
}

export const randomBoolean = () => Math.random() < 0.5

export const truncateDecimals = (value: string, decimals: number) => {
  if (value.includes('.')) {
    const [integer, decimal] = value.split('.')
    const trimmed = decimal.substring(0, decimals)
    return decimals !== 0 ? `${integer}.${trimmed}` : integer
  } else {
    return value
  }
}

export const truncateNumber = (value: number, decimals: number) => {
  const factor = Math.pow(10, decimals)
  return parseInt((value * factor).toFixed(), 10) / factor
}
