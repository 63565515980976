import { Category, DropdownSectionsAndItemIndex, OptionProps } from './GlobalSearch'
import { GlobalSearchAllResultType } from './useGlobalSearch'

export const itemToString = (item: GlobalSearchAllResultType | null): string => {
  return item?.name ?? ''
}

export function sectionOptions(
  options: Category<GlobalSearchAllResultType>[]
): DropdownSectionsAndItemIndex<GlobalSearchAllResultType> {
  const INITIAL_GROUP: DropdownSectionsAndItemIndex<GlobalSearchAllResultType> = {
    sections: [],
    itemIndex: 0,
  }
  return options.reduce((mem, section): DropdownSectionsAndItemIndex<GlobalSearchAllResultType> => {
    const _options = section.options.map((item: GlobalSearchAllResultType): OptionProps<GlobalSearchAllResultType> => {
      const index = mem.itemIndex++
      return {
        item,
        index,
      }
    })
    mem.sections.push({ ...section, options: _options })
    return mem
  }, INITIAL_GROUP)
}
