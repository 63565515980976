import { useEthers } from '@usedapp/core'
import { getApplicationConfig } from '../../config/getApplicationConfig'

export function useChainId() {
  const { defaultChainId } = getApplicationConfig().network
  const { chainId = defaultChainId } = useEthers()
  return { chainId }
}

export function getDefaultChainId() {
  const { defaultChainId } = getApplicationConfig().network
  return { chainId: defaultChainId }
}
