import { useLazyQuery } from '@apollo/client'
import { useDebounce } from '@usedapp/core'
import { useEffect } from 'react'
import { useChainId } from '../../hooks/useChainId'
import { GlobalSearchQuery } from '../../infrastructure/subgraph/backend/queries'
import {
  GetGlobalSearchQuery,
  GetGlobalSearchQueryVariables,
  SearchResultStrategy,
  SearchResultUser,
} from '../../infrastructure/subgraph/backend/types'
import { SubgraphClientName } from '../../infrastructure/subgraph/SubgraphClientName'

export type GlobalSearchFilterType = 'INVESTORS' | 'MANAGERS' | 'STRATEGIES'

export type GlobalSearchInvestor = Required<NonNullable<Omit<SearchResultUser, '__typename'>>>
export type GlobalSearchManager = Required<NonNullable<Omit<SearchResultUser, '__typename'>>>
export type GlobalSearchStrategy = Required<NonNullable<Omit<SearchResultStrategy, '__typename'>>>

export type GlobalSearchAllResultType = GlobalSearchManager | GlobalSearchInvestor | GlobalSearchStrategy

const DEBOUNCE_THRESHOLD = 275

export function useGlobalSearch(searchTerm: string, type?: GlobalSearchFilterType) {
  const { chainId } = useChainId()

  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_THRESHOLD)
  const [runSearch, { data, previousData, loading, error }] = useLazyQuery<
    GetGlobalSearchQuery,
    GetGlobalSearchQueryVariables
  >(GlobalSearchQuery, {
    variables: {
      chainId,
      type,
      search: debouncedSearchTerm,
    },
    context: { clientName: SubgraphClientName.Enso },
  })

  useEffect(() => {
    if (debouncedSearchTerm.length > 0) runSearch()
  }, [debouncedSearchTerm, runSearch])

  const managers = data?.globalSearch?.managers ?? previousData?.globalSearch?.managers ?? []
  const investors = data?.globalSearch?.investors ?? previousData?.globalSearch?.investors ?? []
  const strategies = data?.globalSearch?.strategies ?? previousData?.globalSearch?.strategies ?? []

  const allResults = [...managers, ...investors, ...strategies]
  return {
    managers: managers as GlobalSearchManager[],
    investors: investors as GlobalSearchInvestor[],
    strategies: strategies as GlobalSearchStrategy[],
    allResults: allResults as GlobalSearchAllResultType[],
    hasResults: !error && !loading && allResults.length > 0,
    loading,
  }
}
