import React from 'react'
import styled from 'styled-components'

interface LightningBoltIconProps {
  color?: string
  className?: string
}

export function LightningBoltIcon({ color, className }: LightningBoltIconProps) {
  return (
    <LightningBoltSvg viewBox="0 0 16 24" fill={color || 'currentColor'} className={className}>
      <path d="M1.00004 13.5H7.50004L6.78504 22.92C6.7751 23.1317 6.83268 23.3411 6.94947 23.5179C7.06626 23.6948 7.23622 23.83 7.43483 23.904C7.63343 23.978 7.85041 23.9869 8.05445 23.9296C8.25849 23.8723 8.43904 23.7516 8.57004 23.585L16.81 12.085C16.9177 11.9357 16.9821 11.7596 16.9961 11.5761C17.0101 11.3926 16.9732 11.2088 16.8895 11.0449C16.8057 10.881 16.6784 10.7434 16.5214 10.6473C16.3645 10.5511 16.1841 10.5002 16 10.5H9.50004L10.215 1.07998C10.225 0.868275 10.1674 0.658883 10.0506 0.482025C9.93381 0.305167 9.76385 0.16999 9.56525 0.0959991C9.36664 0.0220086 9.14966 0.0130322 8.94562 0.0703652C8.74158 0.127698 8.56104 0.248376 8.43004 0.414982L0.190036 11.91C0.0809586 12.0593 0.0153959 12.2358 0.000631116 12.4201C-0.0141337 12.6044 0.0224774 12.7892 0.106397 12.9539C0.190318 13.1186 0.318261 13.2569 0.476016 13.3533C0.633771 13.4497 0.815161 13.5005 1.00004 13.5Z" />
    </LightningBoltSvg>
  )
}

export const LightningBoltSvg = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`
